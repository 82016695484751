import { useEffect, useState } from "react";
import apiClient from "../utils/axios";
import ReactPaginate from "react-paginate";

const PatientsPastVisists = ({ patientId }) => {
    
    const [visits, setVisits] = useState([]);

    const [totalCount, setTotalCount] = useState(0);
	const [pageNo, setPageNo] = useState(1);
    
    const limit = 10; 

    const handlePageClick = (data) => {
       setPageNo(data.selected + 1)
    }


    useEffect(() => {
        const loadData = async () => {

            let data = {
                value: true,
                patientId:patientId,
                pageNo: pageNo,
                limit: limit
            }


            

            try {
                const response = await apiClient.post("patient-visits", data);
                setVisits(response.data.data.data)
                setTotalCount(response.data.count)
            } catch (error) {
                console.log(error)
            }
        }
        loadData()
    },[pageNo])
    return <>
         <div className="nk-block nk-block-between">
            <div className="nk-block-head">
                <h6 className="title">Pasts Visits</h6>
            </div>
            <div className="nk-block">
                <a className="btn btn-icon btn-primary" data-bs-toggle="modal" href="#addPayment"><em className="icon ni ni-plus"></em></a>
            </div>
        </div>
        <div className="card">
            <div className="nk-tb-list nk-tb-ulist is-compact">
                <div className="nk-tb-item nk-tb-head">
                    <div className="nk-tb-col"><span className="sub-text">Date of Visit</span></div>
                    <div className="nk-tb-col tb-col-sm"><span className="sub-text">Department</span></div>
                    <div className="nk-tb-col"><span className="sub-text">Status</span></div>
                    {/* <div className="nk-tb-col"><span className="sub-text">Status</span></div> */}
                </div>
                {visits && visits.length > 0 && visits.map((visit) => (
                    <div className="nk-tb-item" key={visit.id}>
                        <div className="nk-tb-col"><span>{ visit.checkin_date}</span></div>
                        <div className="nk-tb-col tb-col-sm">{ visit.department_name}<span>Cash</span></div>
                        <div className="nk-tb-col"><span>{ visit.status}</span></div>
                        {/* <div className="nk-tb-col"><span className="tb-status text-success">Paid</span></div> */}
                    </div>
                ))}
                <div className="mt-3 mb-3">
                {totalCount >  limit && <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={(totalCount / limit)}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    />}
                </div>
                
               
            </div>
        </div>
    </>
}

export default PatientsPastVisists;