import { useState } from "react";
import apiClient from "../../../utils/axios";
import { useAuth } from "../../../store/AuthContext";
import Switch from "../../../reusables/Switch";

const Security = () => {

    const {authUser} = useAuth()

    const [isChangePassword, setIsChangePassword] = useState(false)
    const [successMessage , setSuccessMessage] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [lastPassUpdated, setLastPassUpdated] = useState(authUser.password_lastChanged)

    
    
    

    const changePassHandler = () => {
        setIsChangePassword(true)
    }
    
    const submitHandler = async  (e) => {
        e.preventDefault();
        if (newPassword.trim() === "") {
            setPasswordError("Please enter new password.")
            return ;
        }
        if (newPassword.trim().length < 5) {
            setPasswordError("Password is short.Use atleast 5 characters")
            return ;
        }
        // const ok = confirm('Your previous password will be set to this password.Continue')
        const ok = true;
        if (ok) {
            let data = {
                password: newPassword
            }
            const response = await apiClient.post(`update-password`, data)
            
            if (response.status === 200 && response.data.status) {
                setPasswordError("")
                setSuccessMessage(response.data.message)
                setLastPassUpdated(response.data.last_changed)
                setTimeout(() => {
                    setSuccessMessage("")
                    setIsChangePassword(false)
                },5000)
            }
        }
        
    }


    return <div className="security">
            <div className="nk-block-head nk-block-head-lg">
                <div className="nk-block-head-content">
                    <h5 className="nk-block-title">Security Settings</h5>
                    <div className="nk-block-des">
                        <p>These settings are helps you keep your account secure.</p>
                    </div>
                </div>
            </div>
            <div className="nk-block">
                <div className="card card-bordered">
                    <div className="card-inner-group">
                        <div className="card-inner">
                            <div className="between-center flex-wrap flex-md-nowrap g-3">
                                <div className="nk-block-text">
                                    <h6>Save my Activity Logs</h6>
                                    <p>You can save your all activity logs including unusual activity detected.</p>
                                </div>
                                <div className="nk-block-actions">
                                    <ul className="align-center gx-3">
                                        <li className="order-md-last">
                                            <div className="custom-control custom-switch me-n2 checked">
                                                <input type="checkbox" className="custom-control-input" checked="" id="activity-log" />
                                            {/* <label className="custom-control-label" for="activity-log"></label> */}
                                            <Switch></Switch>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card-inner">
                            <div className="between-center flex-wrap g-3">
                                <div className="nk-block-text">
                                    <h6>Change Password</h6>
                                    <p>Set a unique password to protect your account.</p>
                                </div>
                                <div className="nk-block-actions flex-shrink-sm-0">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                                    {!isChangePassword ? <><li className="order-md-last">
                                            <button onClick={changePassHandler} className="btn btn-primary">Change Password</button>
                                        </li>
                                        <li>
                                            <em className="text-soft text-date fs-12px">Last changed: <span>{ lastPassUpdated }</span></em>
                                        </li> </>: 
                                        <><li className="order-md-last">
                                                <button onClick={submitHandler} className="btn btn-primary">Submit</button>
                                            </li>
                                            <li>
                                                <em className="text-soft text-date fs-12px">
                                                    <input type="password" className="form-control" placeholder="Enter New password"
                                                        onChange={(e)=>setNewPassword(e.target.value)}
                                                    />
                                                    <span className="text-danger fw-bold mt-2">{passwordError && passwordError}</span>
                                                    <span className="text-success fw-bold mt-2">{successMessage && successMessage }</span>
                                                </em>
                                        </li></>
                                        }
                                        
                                    
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card-inner">
                            <div className="between-center flex-wrap flex-md-nowrap g-3">
                                <div className="nk-block-text">
                                    <h6>2 Factor Auth &nbsp; <span className="badge bg-success ms-0">Enabled</span></h6>
                                    <p>Secure your account with 2FA security. When it is activated you will need to enter not only your password, but also a special code using app. You can receive this code by in mobile app. </p>
                                </div>
                                <div className="nk-block-actions">
                                    <a href="#" className="btn btn-primary">Disable</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
}

export default Security;