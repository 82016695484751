import { useEffect, useState } from "react";
import { reportUrls } from "../../../constants/reportUrls";
import {useNavigate} from 'react-router-dom'

const Index = () =>{


    const [urls,setUrls] = useState([])
    const navigate = useNavigate()

    const goToReport = (data) =>{
        let url = urls.find(goTo => goTo.slug == data)
        if(!url){
           return ;
        }
        navigate(url.slug)
        
    }

    useEffect(()=>{
        setUrls(reportUrls)
    },[])


    return <>
        <div className="patient-reports rows gap-2">
                    
                    <div className="card col-sm-3"  style={{ cursor: 'pointer' }} onClick={()=>goToReport(`normal-report`)}>
                        <div className="nk-ecwg nk-ecwg6">
                                <div className="card-inner">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h6 className="title">Normal Patients Report</h6>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div className="card col-sm-3"  style={{ cursor: 'pointer' }} onClick={()=>goToReport(`weekly-medical-summary-report`)}>
                        <div className="nk-ecwg nk-ecwg6">
                                <div className="card-inner">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h6 className="title">Weekly  Summary Report</h6>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div className="card col-sm-3"  style={{ cursor: 'pointer' }} onClick={()=>goToReport(`yearly-medical-summary-report`)}>
                        <div className="nk-ecwg nk-ecwg6">
                                <div className="card-inner">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h6 className="title">Yearly Summary Report</h6>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
        </div>
    </>
}

export default Index;