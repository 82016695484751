import {  HashRouter  as Router, Route, Routes, useNavigate } from "react-router-dom";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import HomePage from './../pages/HomePage';
import Register from "../pages/auth/Register";
import Login from "../pages/auth/Login";
import { useAuth } from "../store/AuthContext";
import ListPatients from "../pages/Patients/ListPatients";
import CreatePatient from "../pages/Patients/CreatePatient";
import ViewPatient from "../pages/Patients/ViewPatient";
import EditPatient from "../pages/Patients/EditPatient";
import ListPatientVisits from "../pages/PatientVisits/ListPatientVisits";
import CreatePatientVisit from "../pages/PatientVisits/CreatePatientVisit";
import ManagePatient from "../pages/ManagePatient/ManagePatient";
import Suppliers from "../pages/Inventory/Suppliers/Suppliers";
import ViewSupplier from "../pages/Inventory/Suppliers/viewSupplier";
import CreateSupplier from "../pages/Inventory/Suppliers/CreateSupplier";
import EditSupplier from "../pages/Inventory/Suppliers/EditSupplier";
import CreateBatch from "../pages/Inventory/Batches/CreateBatch";
import ListBatches from "../pages/Inventory/Batches/ListBatches";
import ViewBatch from "../pages/Inventory/Batches/ViewBatch";
import EditBatch from "../pages/Inventory/Batches/EditBatch";
import ListBrands from "../pages/Inventory/Brands/ListBrands";
import ViewBrand from "../pages/Inventory/Brands/ViewBrand";
import EditBrand from "../pages/Inventory/Brands/EditBrand";
import CreateBrand from "../pages/Inventory/Brands/CreateBrand";
import CreateDrug from "../pages/Inventory/Drugs/CreateDrug";
import ListDrugs from "../pages/Inventory/Drugs/ListDrugs";
import EditDrug from "../pages/Inventory/Drugs/EditDrug";
import ViewDrug from "../pages/Inventory/Drugs/ViewDrug";
import AllReports from "../pages/Reports/AllReports";
import AccountProfile from "../pages/settings/AccountProfile";
// import PatientsDataReport from "../components/reports/PatientsDataReport";
// import MedicalSummaryReport from "../components/reports/MedicalSummaryReport";
import Index from "../components/reports/Patients";
import PatientsDataReport from "../components/reports/Patients/PatientsDataReport";
import MedicalSummaryReport from "../components/reports/Patients/MedicalSummaryReport";
import Settings from "../pages/settings/Settings";
// import ManageRoles from "../pages/settings/ManageRoles";
import  useCheckRole  from "../reusables/CheckRoleOrPermission";
import { ROLES } from "../constants/rolesPermissions";

//



const NavPage = () => {
    const { isLoggedIn } = useAuth()

    const { hasRole } = useCheckRole();

    const navigate = useNavigate()
   
    const isAdmin = () => {
        return hasRole(ROLES.ADMIN);
      };

    



    return (
        <Routes>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/register" element={<Register />} />
            {isLoggedIn && <Route path="/" element={<HomePage />} />}
            {isLoggedIn && <Route path="/login" element={<Login />} />}
            {isLoggedIn && <Route path="/patients" element={<ListPatients />} /> }
            {isLoggedIn && <Route path="/patients/add-patient" element={<CreatePatient />} /> }
            {isLoggedIn && <Route path="/patients/:id" element={<ViewPatient />} /> }
            {isLoggedIn && <Route path="/patients/edit/:id" element={<EditPatient />} /> }
            {isLoggedIn && <Route path="/patient-visits" element={<ListPatientVisits/>} />}
            {isLoggedIn && <Route path="/add-patient-visit" element={<CreatePatientVisit/>} />}
            {isLoggedIn && <Route path="/manage-patient/:patientId/:consultationId" element={<ManagePatient />} />}
            {isLoggedIn && <Route path="/inventory/suppliers" element={<Suppliers />} />}
            {isLoggedIn && <Route path="/invetory/suppliers/:supplierId" element={<ViewSupplier/>} />}
            {isLoggedIn && <Route path="/inventory/suppliers/add-supplier" element={<CreateSupplier />} />}
            {isLoggedIn && <Route path="/inventory/suppliers/Edit/:supplierId" element={<EditSupplier />} />}
            {isLoggedIn && <Route path="/inventory/batch/add-batch" element={<CreateBatch />} />}
            {isLoggedIn && <Route path="/inventory/batches" element={<ListBatches />} />}
            {isLoggedIn && <Route path="/inventory/batches/:batchId" element={<ViewBatch />} />}
            {isLoggedIn && <Route path="/inventory/batches/Edit/:batchId" element={<EditBatch />} />}
            {isLoggedIn && <Route path="/inventory/brands" element={<ListBrands />} />}
            {isLoggedIn && <Route path="/inventory/brands/add-brand" element={<CreateBrand />} />}
            {isLoggedIn && <Route path="/inventory/brands/:brandId" element={<ViewBrand />} />}
            {isLoggedIn && <Route path="/inventory/brands/Edit/:brandId" element={<EditBrand />} />}
            {isLoggedIn && <Route path="/inventory/drugs" element={<ListDrugs />} />}
            {isLoggedIn && <Route path="/inventory/drugs/:drugId" element={<ViewDrug />} />}
            {isLoggedIn && <Route path="/inventory/drugs/add-drug" element={<CreateDrug />} />}
            {isLoggedIn && <Route path="/inventory/drugs/Edit/:drugId" element={<EditDrug />} />}
            {isLoggedIn && <Route path="/reports" element={<AllReports/>} />}
            {isLoggedIn && <Route path="/reports/patients-report" element={<Index/>} />}
            {isLoggedIn && <Route path="/reports/patients-report/normal-report" element={<PatientsDataReport/>} />}
            {isLoggedIn && <Route path="/reports/patients-report/weekly-medical-summary-report" element={<MedicalSummaryReport/>} />}
            {isLoggedIn && isAdmin() && <Route path="/system-setup" element={<Settings />} />}
            {isLoggedIn && <Route path="/account-profile" element={<AccountProfile/>} />}
            {/* {isLoggedIn && <Route path="/roles/manage-roles" element={<ManageRoles/>} />} */}
            {/* {isLoggedIn && <Route path="/roles/manage-roles" element={<ManageRoles/>} />} */}
            



            


          
            
            
        </Routes>
    );
}

export default NavPage;


