export const reportUrls = [
    {
        
        "name":"Normal Report",
        "slug":"normal-report"
    },
    {
        "name":"Medical Summary Report",
        "slug":"weekly-medical-summary-report"
    },
   
]

