import { useAuth } from "../store/AuthContext";



//admin permissions
const  useCheckRole = () => {
    const { roles } = useAuth();

    const hasRole = (roleToCheck) => {
        return roles.some(role => role === roleToCheck);
    };

    return {
        hasRole,
    };
};
export default useCheckRole;


// export const hasPermission = () => {
//     const { permissions } = useAuth()

//     const hasPermission = () =>{
//         return permissions.some(permission => permission == permissionToCheck)
//     } 
    
//      return {
//         hasPermission
//     };
// };

