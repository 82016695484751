import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";
import Investigations from './../../Investigations';

const InvestigationsSetUp = () => {
    const [investigationsList,setInvestigationsList] = useState([])
    const [name, setName] = useState("");
    const [error, setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddInvestigation, setIsAddInvestigation] = useState(false)
    const [isEditInvestigation, setIsEditInvestigation] = useState(false)
    const [investigationType,setInvestigationType] = useState("")
    const [id, setId] = useState("")

    const submitHandler = async (e) => {
        e.preventDefault();
        if (name.trim() === "" || investigationType.trim() === "") {
            setError("Investigations name cannot be empty")
            return;
        }

        if (isEditInvestigation) {
            let data = {
                name: name,
                type:investigationType
            }

            const response = await apiClient.put(`investigations/${id}`, data)
            if (response.status === 200 && response.data.status) {
                setError("")
                setIsAddInvestigation(false)
                setIsEditInvestigation(false)
                setName("")
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage("")
                }, 3000)

                getInvestigationsData()
                
            }
        } else {
            let data = {
                name: name,
                type:investigationType
            }
            const response = await apiClient.post(`investigations`,data)
            if (response.status === 200 && response.data.status) {
                setError("")
                setSuccessMessage(response.data.message)
                setIsAddInvestigation(false)
                getInvestigationsData()
                setTimeout(() => {
                    setSuccessMessage("")
                },3000)
            }

        }
        
    }

    const handleEditinvestigation = (investigation) => {
        setId(investigation.id)
        setIsAddInvestigation(true)
        setIsEditInvestigation(true)
        setName(investigation.name)
        setInvestigationType(investigation.type)
    }
    const handleDeleteinvestigation = async (investigationId) => {
        const response = await apiClient.delete(`investigations/${investigationId}`)
        if(response.status === 200){
            setSuccessMessage(response.data.message)
            setTimeout(() => {
                setSuccessMessage("")
            }, 3000);
            getInvestigationsData()
        }
    }
    


    const getInvestigationsData = async () => {
        setIsLoading(true)
        const response = await apiClient.get(`investigations`)
        if (response.status === 200 ) {
            setIsLoading(false)
            console.log("response",response.data)
            setInvestigationsList(response.data)
		} else {
			console.log("something happened")
		}
    }
    

    const backToList = () => {
        setIsAddInvestigation(false)
        setIsEditInvestigation(false)
        setName("")
        setInvestigationType("")
        setId("")
    }

    useEffect(() => {
        getInvestigationsData()
    },[])

    


     const investigationsCreate = (<div class="row gy-4">
        <div class="example-alert">
                    <div class="alert alert-success alert-icon">
                        Use this Page to set up <strong>investigations </strong>
                    </div>
         </div>
         <div className="d-flex justify-content-between " style={{ cursor: 'pointer' }} >
            {/* <div className="mt-3">{isEditInvestigation ? 'Edit Investigation' : 'Add Investigation'}</div> */}
            <div></div>
            <div onClick={backToList} className="mt-3"><em class="icon ni ni-plus" ></em><span>Back To Investigations lists</span></div>
        </div>
            <div class="col-xxl-3 col-md-4">
                <div class="form-group">
                    <label class="form-label" for="full-name">{isEditInvestigation ? 'Edit Investigation' : 'Add Investigation'}</label>
                    <div class="form-control-wrap">
                        <input type="text" className="form-control" placeholder="Enter investigations Name" value={name} onChange={(e) => setName(e.target.value)} />
                     <div>
                         <label class="form-label" for="full-name">Investigation Type</label>
                    <div class="form-control-wrap">
                        <input type="text" className="form-control" placeholder="Enter investigations type" value={investigationType} onChange={(e) => setInvestigationType(e.target.value)} />
                        <button className="btn btn-primary mt-2" onClick={submitHandler}>{!isEditInvestigation ? 'Submit' : 'Update'}</button>
                    </div>

                         
                         <span className="text-danger fw-bold mt-1">{error && error}</span>
                        <span className="text-success fw-bold mt-1">{ successMessage && successMessage}</span>
                    </div>
                    </div>
                </div>
            </div>
        </div>)


      const investigationList = <>
        <div className="float-end m-3" style={{ cursor:'pointer' }} onClick={()=>setIsAddInvestigation(true)}>
            <em class="icon ni ni-plus" ></em><span>Add investigation</span>
        </div>
        <span className="text-success fw-bold mt-3">{ successMessage && successMessage}</span>
        {isLoading && <p className="mt-1 text-center">Loading ...</p>}
        {!isLoading && investigationsList && investigationsList.length === 0 && <p className="mt-1 text-center">No Investigations </p>}
        {!isLoading && investigationsList && investigationsList.length > 0 && <table className="table table-hover table-striped">
            
                <thead>
                    <tr>
                        
                        <td>Name</td>
                        <td>Type</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>

                
                    {investigationsList.map(investigation => (
                        
                        <tr>
                            
                            <td>{investigation.name}</td>
                            <td>{investigation.type}</td>
                            
                        <td className = "d-flex gap-1 " >
                                <button className="btn btn-primary p-2" onClick={() => handleEditinvestigation(investigation)} style={{ marginRight: '10px' }}>Edit</button>
                                <button className="btn btn-danger p-2" onClick={() => handleDeleteinvestigation(investigation.id)} >Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
        </table>}
        

    </>
    
   return isAddInvestigation ? investigationsCreate :  investigationList
}
export default InvestigationsSetUp;
