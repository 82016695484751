import { useState } from "react";
import * as XLSX from 'xlsx';
// import * as FileSaver from 'file-saver';

import apiClient from "../utils/axios";
import { PatientSheet } from "../constants/patientsheet";
import { useNavigate } from "react-router-dom";

const BulkUploadPatientsModal = ({onClose}) =>{

    const [file,setFile] = useState(null);
    const [data,setData] = useState([])
    // const [isLoaded,setIsLoaded] = useState(false)
    const [error,setError] = useState("")
    const [message, setMessage] = useState("")
    const [viewData, setViewData] = useState(false)
    

    const navigate = useNavigate();

    //end gender test csv upload

    const isExcelFile = (uploadedFile) => {
        return uploadedFile.name.endsWith(".xlsx") || uploadedFile.name.endsWith(".xls");
    };

    const downloadSheetHandler = () =>{

        const data = PatientSheet;

        // Convert the data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, "patients-sheet.xlsx");

    }

    const handleFileUpload = (e) => {
        
        if(e.target.value === ""){
            return ;
        }
       
       
       const uploadedFile  =  e.target.files[0]

        if (!isExcelFile(uploadedFile)) {
            setError('Invalid file type. Please upload an Excel file.');
            setFile(null)
            setData([])
            setViewData(false)
            return;
        } else{
            setViewData(true)
            setError("")
            setFile(uploadedFile)
        }
        
    }


    const viewFileData = (e)=> {
        e.preventDefault();
        if (!file) return;
        if (error) return;
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            setData(jsonData);
        };
        reader.readAsBinaryString(file);

    }

    const handleSendData = async () => {

        if(data.length === 0 ) return ;
        //
        try {
            const response = await apiClient.post('patients/bulk-upload-patients', { data });
            if(response.data.status){
                setMessage("Success")
                setTimeout(() => {
                    setMessage()
                    onClose()
                    navigate("/patients")
                }, 2000);
                
                setFile(null)
                
            }else{
                setMessage("")
                setError(response.data.message)
                setTimeout(() => {
                    setError("")
                    
                }, 5000);
                

                
            }
               
        } catch (error) {
            console.error('Error sending data to the backend', error);
        }
    };

  


    return <>
        <div className="modal-backdrop fade show"></div>
            <div
                className="modal fade show"
                tabIndex="-1"
                style={{ display: 'block' }}
                aria-modal="true"
                role="dialog"
            >
            <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content"  style={{width: "auto"}}>
                <button type="button" className="close" onClick={onClose}>
                <em className="icon ni ni-cross-sm"></em>
                </button>
                <div className="modal-body modal-body-md">
                <h5 className="modal-title">Upload Patients in Bulk</h5>
                
                <form action="#" className="mt-4">
                    <div className="row g-gs">

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="childName">Upload File</label>
                                <div className="form-control-wrap">
                                <input
                                    type="file"
                                    className="form-control"
                                    placeholder="upload file"
                                    onChange={handleFileUpload}
                                />
                                            </div>
                                            
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="form-label" htmlFor="childName">Download Sheet</label>
                            <div className="form-control-wrap">
                                <span className="btn btn-primary" onClick={downloadSheetHandler}>Download Excel Sheet</span>
                            </div>
                            </div>
                        </div>

                        <div className="col-8 float-end">
                            
                            <ul className=" d-flex flex-wrap flex-sm-nowrap gx-4 gy-2">
                                { viewData  && <li>
                                    <span data-bs-dismiss="modal" className="btn btn-primary" onClick={viewFileData}>View Data</span>
                                </li>}
                                
                                <li>
                                    <a href="#" className="btn btn-danger" onClick={onClose} >Cancel</a>
                                </li>
                                
                            {data.length > 0 && <li>
                                    <span data-bs-dismiss="modal" className="btn btn-primary" onClick={handleSendData}>Upload</span>
                                </li>} 

                            </ul>
                        </div>

                        {data.length > 0 && (
                            <div className="table-responsive" style={{height:'300px'}}>
                                <div className="d-flex justify-content-between">
                                    <div><b>Total patients: {data.length} </b></div>
                                    <div>
                                         {message && <div className="text-success m-3">{message}</div>}
                                         {error && <div  className="text-danger"><b>{ error}</b></div>}       
                                    </div>
                                </div>
                            <table className="table">
                            <thead>
                                <tr>
                                {Object.keys(data[0]).map((key) => (
                                    <th key={key}>{key}</th>
                                ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row, index) => (
                                <tr key={index}>
                                    {Object.values(row).map((value, idx) => (
                                    <td key={idx}>{value}</td>
                                    ))}
                                </tr>
                                ))}
                            </tbody>
                            </table>
                            </div>
                        )}


                    </div>
                </form>
                </div>
            </div>
            </div>
        </div>
    </>;
}

export default BulkUploadPatientsModal;