import { useAuth } from "../store/AuthContext";
import { ROLES } from "../constants/rolesPermissions";
import Button from '@mui/material/Button';
import { React, useState } from 'react';

import Alerts from './../reusables/Alerts';


const HomePage = () => {

    const {role,authUser} = useAuth()
    // return <>Role:  { role.name} {} </>
    // return  <Button variant="contained" color="success"  >Hello React</Button>
   


    return (
        <div>
            <Alerts>Welcome { authUser.name}</Alerts>
        </div>
   
    );
    


    
}

export default HomePage;