const Notifications = () => {
    return <div class="nk-block container mt-4">
                <div class="nk-block-head nk-block-head-lg">
                    <div class="nk-block-head-content">
                        <h5 class="nk-block-title">Notification Settings</h5>
                        <div class="nk-block-des">
                            <p>You will get only notification what have enabled.</p>
                        </div>
                    </div>
                </div>
                <div class="nk-block-head nk-block-head-sm">
                    <div class="nk-block-head-content">
                        <h6>Security Alerts</h6>
                        <p>You will get only those email notification what you want.</p>
                    </div>
                </div>
                <div class="nk-block-content">
                    <div class="gy-3">
                        <div class="g-item">
                            <div class="custom-control custom-switch checked">
                                <input type="checkbox" class="custom-control-input" checked="" id="unusual-activity" />
                                <label class="custom-control-label" for="unusual-activity">Email me whenever encounter unusual activity</label>
                            </div>
                        </div>
                        <div class="g-item">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="new-browser"/>
                                <label class="custom-control-label" for="new-browser">Email me if new browser is used to sign in</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nk-block-head nk-block-head-sm">
                    <div class="nk-block-head-content">
                        <h6>News</h6>
                        <p>You will get only those email notification what you want.</p>
                    </div>
                </div>
                <div class="nk-block-content">
                    <div class="gy-3">
                        <div class="g-item">
                            <div class="custom-control custom-switch checked">
                                <input type="checkbox" class="custom-control-input" checked="" id="latest-sale-news"/>
                                <label class="custom-control-label" for="latest-sale-news">Notify me by email about sales and latest news</label>
                            </div>
                        </div>
                        <div class="g-item">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="feature-update"/>
                                <label class="custom-control-label" for="feature-update">Email me about new features and updates</label>
                            </div>
                        </div>
                        <div class="g-item">
                            <div class="custom-control custom-switch checked">
                                <input type="checkbox" class="custom-control-input" checked="" id="account-tips"/>
                                <label class="custom-control-label" for="account-tips">Email me about tips on using account</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}
export default Notifications