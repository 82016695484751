import {  useState } from "react"
import Button from "../../reusables/Button";
import { useAuth } from "../../store/AuthContext";
import apiClient from "../../utils/axios";
import Cookies from "js-cookie";

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [hasError, setHasError] = useState(false)
    const [error, setError] = useState("");
    
    const {  isUserLoggedInHandler } = useAuth();

   
    const submitHandler = async (e) => {
        e.preventDefault();
        
        if (email === "" || password === "") {
            setError("please fill all the fileds * ");
        }
        let data = {
            email: email,
            password:password
        }
        
        try {
            let url = "login-user"
            const response = await apiClient.post(url, data);  
            console.log("response",response)
            if (!response.data.status) {
                setHasError(true)
                setError("wrong login credentials")
            } else {
                
                setHasError(false)
                setError("")
                isUserLoggedInHandler(response.data)
                
            }
        
        } catch (error) {
            console.log("an error occured",error)
            setHasError(true)
            setError("Wrong credentials")
            setTimeout(() => {
                setHasError(false)
                setError("")
            }, 3000);
        }

    }



    return <div class="nk-split nk-split-page nk-split-lg">
    <div class="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
        <div class="absolute-top-right d-lg-none p-3 p-sm-5">
            <a href="#" class="toggle btn-white btn btn-icon btn-light" data-target="athPromo"><em class="icon ni ni-info"></em></a>
        </div>
        <div class="nk-block nk-block-middle nk-auth-body">
            <div class="brand-logo pb-5">
                {/* <a href="/demo7/index.html" class="logo-link">
                    <img class="logo-light logo-img logo-img-lg" src="https://dashlite.net/demo7/images/logo-dark.png" srcset="/demo7/images/logo2x.png 2x" alt="logo" />
                    <img class="logo-dark logo-img logo-img-lg" src="https://dashlite.net/demo7/images/logo-dark.png" srcset="/demo7/images/logo-dark2x.png 2x" alt="logo-dark" />
                </a> */}
            </div>
            <div class="nk-block-head">
                <div class="nk-block-head-content">
                    <h5 class="nk-block-title">Sign-In</h5>
                    <div class="nk-block-des"><p>Access the Hospital Management System using email and password.</p></div>
                    <div className="text-center mt-3 mb- text-danger fs-6">{hasError && <>{error}</>}</div>
                </div>
            </div>
            <form action="#" class="form-validate is-alter" autocomplete="off" novalidate="novalidate">
                <div class="form-group">
                    <div class="form-label-group"><label class="form-label" for="email-address">Email or Username</label>
                        {/* <a class="link link-primary link-sm" tabindex="-1" href="#">Need Help?</a> */}
                    </div>
                    <div class="form-control-wrap">
                        <input autocomplete="off" type="text" class="form-control form-control-lg" required="" id="email-address" placeholder="Enter your email address " 
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                        </div>
                </div>
                <div class="form-group">
                    <div class="form-label-group"><label class="form-label" for="password">Password</label>
                    {/* <a class="link link-primary link-sm" tabindex="-1" href="/demo7/pages/auths/auth-reset.html">Forgot Code?</a> */}
                    </div>
                    <div class="form-control-wrap">
                        <a tabindex="-1" href="#" class="form-icon form-icon-right passcode-switch lg" data-target="password">
                            <em class="passcode-icon icon-show icon ni ni-eye"></em><em class="passcode-icon icon-hide icon ni ni-eye-off"></em>
                        </a>
                        <input autocomplete="new-password" type="password" class="form-control form-control-lg" required="" id="password" placeholder="Enter your password" 
                        onChange={(e)=>setPassword(e.target.value)}/>
                    </div>
                </div>
                <div class="form-group">
                   <Button onClick={submitHandler}>Sign in</Button>
                </div>
            </form>
           
        </div>
        <div class="nk-block nk-auth-footer">
            
            <div class="mt-3"><p>© 2024 DashLite. All Rights Reserved.</p></div>
        </div>
    </div>
    <div class="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right toggle-screen-lg" data-toggle-body="true" data-content="athPromo" data-toggle-screen="lg" data-toggle-overlay="true">
        <div class="slider-wrap w-100 p-3 p-sm-5 m-auto">
            <div class="slider-inits slick-initialized slick-slider slick-dotted" data-slick='{"dots":true, "arrows":false}'>
                <div class="slick-list draggable">
                    <div class="slick-track" style={{opacity: "1", width: "3199px", transform: "translate3d(-457px, 0px, 0px);"}}>
                        <div class="slider-item slick-slide slick-cloned" data-slick-index="-1" id="" aria-hidden="true" style={{width: "457px;"}} tabindex="-1">
                            <div class="nk-feature nk-feature-center">
                                <div class="nk-feature-img">
                                    <img class="round" src="https://i.pinimg.com/564x/5a/fa/4e/5afa4e53555eb85ad7c5cfcf4404fb4a.jpg" 
                                    srcset="/demo7/images/slides/promo-c2x.png 2x" alt="" />

                                    {/* <img class="round" src={`${process.env.PUBLIC_URL}/assets/images/background-login.png`} 
                                    srcset="/demo7/images/slides/promo-c2x.png 2x" alt="" /> */}

                                    </div>
                                
                            </div>
                        </div>
                      
                    </div>
                </div>
               
            </div>
           
        </div>
    </div>
</div>



     
}

export default Login