
import { React } from 'react';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


const Alerts = ({ children}) => {

   
    return (
        <div>
             <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="info">{children} </Alert>
            </Stack>
        </div>
   
    );
    
}

export default Alerts;