import Header from "./header";
import Sidebar from "./sidebar";
import NavPage from "./NavPage";
import Login from "../pages/auth/Login";
import { useAuth } from './../store/AuthContext';
import Cookies from "js-cookie";

const MainPage = () => {

    const {isLoggedIn} = useAuth()


    const dashboard = <div class="nk-body ui-rounder npc-default has-sidebar ">
            <div class="nk-app-root">
                <div class="nk-sidebar" data-content="sidebarMenu">
                    <Sidebar/>
                </div>
                <div class="nk-main ">
                    <div class="nk-wrap ">
                        <Header />
                        <div class="nk-content ">
                            <div class="container-fluid">
                                <div class="nk-content-inner">
                                    <div class="nk-content-body">
                                       <NavPage />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
             </div>
        </div>
        
    
    return <>
    
        {isLoggedIn ? 
            dashboard : 
            <Login/>
        }
        
    </>
        
}

export default MainPage;