import React, { forwardRef } from 'react';
import { Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import './PieChartComponent.css';

// Register the plugin
Chart.register(ChartDataLabels);

const PieChart = forwardRef(({ data, label },ref) => {

  const generateRandomColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      colors.push(`rgba(${r}, ${g}, ${b}, 0.2)`);
    }
    return colors;
  };

  const backgroundColors = generateRandomColors(data.length);
  const borderColors = backgroundColors.map(color => color.replace('0.2', '1'));

  const chartData = {
    labels: label,
    datasets: [
      {
        label: 'Number of Patients',
        data: data,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      datalabels: {
        color: '#000',
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          return `${label}: ${value}`;
        },
        font: {
          weight: 'bold'
        }
      }
    }
  };


  return (
    <div className="pie-chart-container">
      <Pie ref={ref} data={chartData} options={chartOptions} />
    </div>
  );
});

export default PieChart;
