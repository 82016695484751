
const UserNotification = () => {
    
    return <div className="user-notifications" >
                <div className="nk-block">
                    <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-head-content">
                            <h5 className="nk-block-title">Notification Settings</h5>
                            <div className="nk-block-des">
                                <p>You will get only notification what have enabled.</p>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-head-content">
                            <h6>Security Alerts</h6>
                            <p>You will get only those email notification what you want.</p>
                        </div>
                    </div>
                    <div className="nk-block-content">
                        <div className="gy-3">
                            <div className="g-item">
                                <div className="custom-control custom-switch checked">
                                    <input type="checkbox" className="custom-control-input" checked="" id="unusual-activity"/>
                                    <label className="custom-control-label" for="unusual-activity">Email me whenever encounter unusual activity</label>
                                </div>
                            </div>
                            <div className="g-item">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="new-browser"/>
                                    <label className="custom-control-label" for="new-browser">Email me if new browser is used to sign in</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-head-content">
                            <h6>News</h6>
                            <p>You will get only those email notification what you want.</p>
                        </div>
                    </div>
                    <div className="nk-block-content">
                        <div className="gy-3">
                            <div className="g-item">
                                <div className="custom-control custom-switch checked">
                                    <input type="checkbox" className="custom-control-input" checked="" id="latest-sale-news"/>
                                    <label className="custom-control-label" for="latest-sale-news">Notify me by email about sales and latest news</label>
                                </div>
                            </div>
                            <div className="g-item">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="feature-update"/>
                                    <label className="custom-control-label" for="feature-update">Email me about new features and updates</label>
                                </div>
                            </div>
                            <div className="g-item">
                                <div className="custom-control custom-switch checked">
                                    <input type="checkbox" className="custom-control-input" checked="" id="account-tips" />
                                    <label className="custom-control-label" for="account-tips">Email me about tips on using account</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}
export default UserNotification;