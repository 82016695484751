import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../../utils/axios";
import TriageForm from "../../components/TriageForm";
import DiagnosisForm from "../../components/DiagnosisForm";
import PatientInfo from "../../components/PatienInfo";
import Investigations from "../../components/Investigations";
import PatientsPastVisists from "../../components/PatientPastVisits";
import PharmacyComponent from "../../components/PharmacyComponent";
import Procedures from "../../components/Procedures";

const ManagePatient = () =>{
    
    const { patientId, consultationId } = useParams();
    
    //note that patient refers to the person data
    const [patient,setPatient] = useState({})
    const [isLoading,setIsLoading] = useState(true)
    const [consultationData,setConsultationData] = useState({})

    // test
    const [activeTab, setActiveTab] = useState('tabItem1');
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
      };
    


    const fetchConsultationData = async () => {
        try {
            let url = 'consultation/get-consultation-data';
            const response = await apiClient.post(url, { consultation_id: consultationId })
            
            if (response.data.status) {
                let data = response.data.data;
                setConsultationData(data)
            }
        } catch (error) {
            
        }
    }

    const fetchPatientData = async () =>{
        let url = "patients/show"
        try {
            const response = await apiClient.post(url,{
                patient_id:patientId
            })

            if(response.status === 200){
                setPatient(response.data.data)
            }else {
                console.error('Failed to fetch patient data');
            }

        } catch (error) {
            console.log(error)
            setIsLoading(true)
        }finally {
            setIsLoading(false); // Always set isLoading to false in finally block
        }
    }
    
    // const getPatient

    useEffect(()=>{
        const fetchData = async () => {
            await fetchPatientData(); // Call fetchPatientData function
            await fetchConsultationData();
        };
        fetchData()
    },[patientId])

    useEffect(() => {
      }, [activeTab]);
    



   
    return <div className="container-fluid">
        <div className="nk-content-inner">
        <div className="nk-content-body">
            <div className="nk-block-head">
            <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                Patient/ <strong className="text-primary small"> {patient.first_name} </strong>
                </h3>
            </div>
            </div>
            <div className="nk-block nk-block-lg">
            <div className="card">
                <div className="card-aside-wrap">
                <div className="card-content">
                    <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a
                        className={`nav-link ${activeTab === 'tabItem1' ? 'active' : ''}`}
                        onClick={() => handleTabChange('tabItem1')}
                        role="tab"
                        >

                        <em className="icon ni ni-user-circle-fill"></em>
                        <span>Personal information</span>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a
                        className={`nav-link ${activeTab === 'tabItem2' ? 'active' : ''}`}
                        onClick={() => handleTabChange('tabItem2')}
                        role="tab"
                        >
                        <em className="icon ni ni-property"></em>
                        <span>Triage</span>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a
                        className={`nav-link ${activeTab === 'tabItem3' ? 'active' : ''}`}
                        onClick={() => handleTabChange('tabItem3')}
                        role="tab"
                        >
                        <em className="icon ni ni-capsule-fill"></em>
                        <span>Visit</span>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a
                        className={`nav-link ${activeTab === 'tabItem4' ? 'active' : ''}`}
                        onClick={() => handleTabChange('tabItem4')}
                        role="tab"
                        >
                        <em className="icon ni ni-money"></em>
                        <span>Investigations</span>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a
                        className={`nav-link ${activeTab === 'tabItem5' ? 'active' : ''}`}
                        onClick={() => handleTabChange('tabItem5')}
                        role="tab"
                        >
                        <em className="icon ni ni-wallet-in"></em>
                        <span>Prescription</span>
                        </a>
                    </li>
                                    
                    <li className="nav-item" role="presentation">
                        <a
                        className={`nav-link ${activeTab === 'tabItem7' ? 'active' : ''}`}
                        onClick={() => handleTabChange('tabItem7')}
                        role="tab"
                        >

                        <em className="icon ni ni-user-circle-fill"></em>
                        <span>Procedures</span>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a
                        className={`nav-link ${activeTab === 'tabItem6' ? 'active' : ''}`}
                        onClick={() => handleTabChange('tabItem6')}
                        role="tab"
                        >
                        <em className="icon ni ni-capsule-fill"></em>
                        <span>Past Visits</span>
                        </a>
                    </li>
                    </ul>
                    <div className="card-inner">
                    <div className="tab-content">
                        {activeTab === 'tabItem1' && (
                            <div className={`tab-pane ${activeTab === 'tabItem1' ? 'active' : ''}`} id="tabItem1" role="tabpanel">
                                <div className="nk-block">
                                <PatientInfo person={patient} id={patientId} />
                                </div>
                            </div>
                        )}
                        {activeTab === 'tabItem2' && (
                        <div className={`tab-pane ${activeTab === 'tabItem2' ? 'active' : ''}`} id="tabItem2" role="tabpanel">
                            <div className="nk-block">
                                <TriageForm triage={consultationData} />
                            </div>
                        </div>
                        )}
                        {activeTab === 'tabItem3' && (
                            <div className={`tab-pane ${activeTab === 'tabItem3' ? 'active' : ''}`} id="tabItem3" role="tabpanel">
                                <div className="nk-block">
                                    <DiagnosisForm diagnosisId={consultationId} />
                                </div>
                            </div>
                        )}
                        {activeTab === 'tabItem4' && (
                        <div className={`tab-pane ${activeTab === 'tabItem4' ? 'active' : ''}`} id="tabItem4" role="tabpanel">
                            <div className="nk-block nks-block-between">
                                <div className="nk-block">
                                    <Investigations consultationId={consultationId} />
                                </div>
                            </div>
                        </div>
                        )}
                        {activeTab === 'tabItem5' && (
                        <div className={`tab-pane ${activeTab === 'tabItem5' ? 'active' : ''}`} id="tabItem5" role="tabpanel">
                            <div className="nk-block nk-block-betweens">
                            <div className="nk-block">
                                <PharmacyComponent consultationId={consultationId} id={patientId} />
                            </div>
                            </div>
                        </div>
                        )}
                        {activeTab === 'tabItem6' && (
                        <div className="tab-pane" id="tabItem6" role="tabpanel">
                            <div className="nk-block">
                            <PatientsPastVisists patientId={patientId} />
                            </div>
                        </div>
                        )}
                                        
                        {activeTab === 'tabItem7' && (
                            <div className={`tab-pane ${activeTab === 'tabItem7' ? 'active' : ''}`} id="tabItem7" role="tabpanel">
                                <div className="nk-block">
                                <Procedures consultationId={consultationId} id={patientId}  />
                                </div>
                            </div>
                        )}
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    //end test
}

export default ManagePatient;


