import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../../../utils/axios";
import ViewBatchComponent from "../../../components/batches/ViewBatchComponent";
// import { useAuth } from "../../../store/AuthContext";

const ViewBatch = () => {

    const { batchId } = useParams();

    const [batch, setBatch] = useState({})
    const [isLoading,setIsLoading] = useState(true)

    

    const fetchSupplier = async () =>{
        let url = `batches/view/${batchId}`
        try {
            const response = await apiClient.post(url)
            console.log("batch info", response.data)
            if(response.status === 200){
                setBatch(response.data)
            }else {
                console.error('Failed to fetch supplier data');
            }

        } catch (error) {
            console.log(error)
            setIsLoading(true)
        }finally {
            setIsLoading(false); // Always set isLoading to false in finally block
        }
    }

    useEffect(()=>{
        fetchSupplier()
    },[batchId])

   
    return <>
           {isLoading && <p>loading ...</p>}
           {!isLoading && <ViewBatchComponent batch={batch} />}
        </>

}


export default ViewBatch;