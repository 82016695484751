import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";
import Switch from "../../../reusables/Switch";
import { useAuth } from "../../../store/AuthContext";
import Cookies from "js-cookie";
import InvestigationsSetUp from "./InvestigationsSetUp";
import DepartmentsSetUp from "./DepartmentsSetUp";
import CompanySetUp from "./CompanySetUp";
import MeasurementsSetUp from "./MeasurementsSetUp";

const HospSettings = () => {

    const allSettings = JSON.parse(Cookies.get('system-settings'))

    const [settings,setSettings] = useState(allSettings)
    const { fetchSystemSettings } = useAuth()
    const itemName = 'companys'
    const [activeTab, setActiveTab] = useState(itemName);
   
    const handleToggle = async (id, value) => {
       
        const response = await apiClient.put(`settings/${id}`,{dataValue:value})
        if(response.status === 200 ){
            fetchSystemSettings()
            setSettings(prevSettings => 
                prevSettings.map(setting => 
                    setting.id === id ? { ...setting, value: value } : setting
                )
            );            
        }
    };
    

    const setActiveHandler = (itemName) => {
      setActiveTab(itemName === activeTab ? activeTab : itemName);
    };

  

    let component;

    const systemSettingsMain = (<div className="#">

        
            <ul className="list-unstyled">
                {settings.map((setting) => (
                <li key={setting.id} className="border-bottom py-3">
                    <div className="row align-items-center">
                    
                        <div className="col-10">
                            <p>{setting.name}</p>
                            <p className="mb-0">{setting.description}</p>
                        </div>
                        <div className="col-2 border-right">
                        <label className="d-flex align-items-center">
                            <Switch checked={setting.value == 1  ? true : false} onChange={()=>handleToggle(setting.id, !setting.value)}/>
                        </label>
                        </div>
                    </div>
                </li>
                ))}
            </ul>
    </div>)

    

    if(activeTab === 'companys'){
         component = <CompanySetUp/>
    }
    if (activeTab === 'settings') {
        component = systemSettingsMain
    }
    if (activeTab === 'departments') {
        component = <DepartmentsSetUp/>
    }
    if (activeTab === 'investigations') {
        component = <InvestigationsSetUp/>
    }
     if (activeTab === 'measurements') {
        component = <MeasurementsSetUp/>
    }
   
    
    return <div className="card-content">
                
                <ul className="nav nav-tabs nav-tabs-card" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'companys' ? 'active ' : ''}`} onClick={() => setActiveHandler('companys')}   aria-selected="true" role="tab"><span>Companys</span></a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'departments' ? 'active ' : ''}`} onClick={() => setActiveHandler('departments')}   aria-selected="true" role="tab"><span>Departments</span></a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'investigations' ? 'active ' : ''}`} onClick={() => setActiveHandler('investigations')}   aria-selected="true" role="tab"><span>Investigations</span></a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'measurements' ? 'active ' : ''}`} onClick={() => setActiveHandler('measurements')}   aria-selected="true" role="tab"><span>Measurements</span></a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'settings' ? 'active ' : ''}`} onClick={() => setActiveHandler('settings')}   aria-selected="false" tabindex="-1" role="tab"><span>Settings</span></a>
                    </li>
                </ul>
                <div className="card-inners">
                    <div className="tab-contents">
                        {component}
                    </div>
                </div>
            </div>

   
    
}
export default HospSettings



