import { useEffect, useState } from 'react';
import Button from '../../reusables/Button';
import apiClient from '../../utils/axios';
import { bloodgroups } from '../../constants/bloodgroups';
import { genders } from '../../constants/genders';
import { allCities } from '../../constants/cities';
import BulkUploadPatientsModal from '../../reusables/BulkUploadPatientsModal';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

const CreatePatient = () => {

	const [workPlaces, setWorkPlaces] = useState([])
	const [workPlaceId,setWorkPlaceId] = useState("")
	const [firstName,setFirstName] = useState("")
	const [lastName,setLastName] = useState("")
	const [dob,setDob] = useState("")
	const [gender,setGender] = useState("1") //DEFAULT AS MALE
	const [workPlace,setWorkPlace] = useState("")
	const [cities,setCities] = useState([])
	const [cityId,setCityId] = useState("")
	const [email,setEmail] = useState("")
	const [bloodGroup, setBloodGroup] = useState("A") //DEFAULT AS A DEFAULT BLOOD GROUP
	const [nationalId, setNationalId] = useState("") 
	const [phoneNumbers, setPhoneNumbers] = useState(['']);
	const [phoneError, setPhoneError] = useState("")
	const [insuranceCardNumber,setInsuranceCardNumber] = useState("")
	const [medInsuranceCardNumber,setMedInsuranceCardNumber] = useState("")

	const navigate = useNavigate()


	const [showBulkPatientsModal,setShowBulkPatientsModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isSuccess,setIsSuccess] = useState(false)
	const [message,setMessage] = useState(false)
	const [error, setError] = useState("")


	const options = workPlaces && workPlaces.length > 0 ?  workPlaces.map(workPlace => ({
        value: workPlace.id,
        label: workPlace.name
    })):[];
    
    const handleWorkPlaceChange = (seletedWorkOption) => {
        if (seletedWorkOption && seletedWorkOption.value) {
            const selectedWorkPlace = workPlaces.find(workPlace => workPlace.id === parseInt(seletedWorkOption.value));
            setWorkPlaceId(selectedWorkPlace.id) 
        } else {
            setWorkPlaceId(""); // Handle empty or undefined case
        }
	}
	

	const handleAddPhoneNumber = () => {
		if (phoneNumbers.length < 3) {
			setPhoneNumbers([...phoneNumbers, '']);
		} else {
			setPhoneError("Only 3 phone numbers allowed")
			setTimeout(() => {
				setPhoneError("")
			}, 3000);
			return;
		}


		
	  };
	const handlePhoneNumberChange = (index, event) => {
		const newPhoneNumbers = phoneNumbers.slice();
		newPhoneNumbers[index] = event.target.value;
		setPhoneNumbers(newPhoneNumbers);
	};
	


	const submitHandler = async () => {
		const myGender = gender === 1 ? "Male" : "Female"

		
		setIsLoading(true);
		let url = 'patients/add'


		let data = {
			date_of_birth:dob,
			gender:myGender,
			phones:phoneNumbers,
			last_name:lastName,
			first_name:firstName,
			email:email,
			city_id:cityId,
			blood_group:bloodGroup,
			identifier_number:nationalId,
			place_of_work: workPlace,
			place_of_work_id:workPlaceId,
			insurance_card_number:insuranceCardNumber,
			med_Insurance_card_number:medInsuranceCardNumber
		}

		console.log("data",data)

		
		try {
			const response = await apiClient.post(url,data);

			if(response.data.status){
				setWorkPlace("")
				setFirstName("")
				setLastName("")
				setDob("")
				setGender("")
				setPhoneNumbers([""])
				setNationalId("")
				setIsSuccess(true)
				setError("")
				setMessage("Patient saved successfully")
				setTimeout(() => {
					setMessage("")
					navigate("/patients")
				}, 3000);
			}else{
				setError(response.data.message)
			}
		

		} catch (error) {
			// setError()
			console.log("error response",error)
			setIsLoading(false)
			setIsSuccess(false)
			setError("Network error occured")
		}finally{
			setIsLoading(false)
		}
	}

	const fetchCitiesData = async  () =>{
		
		try {
			const response = await apiClient.post("cities");
			if(response.status === 200 || response.status === 201){
				
				setCities(response.data.cities);
			}
		} catch (error) {
			console.log(error)
		}
		
		
	}

	const getWorkPlaces = async () => {

        const response = await apiClient.get(`work-places`)
        if (response.status === 200 && response.data.data.status) {
            console.log("response",)
            setWorkPlaces(response.data.data.data)
		} else {
			console.log("somthing happened")
		}
        
    }
	
	useEffect(()=>{
		const fetchCitiesAndWorkPlaces = async () =>{
			await fetchCitiesData()
			await getWorkPlaces()
		}
		fetchCitiesAndWorkPlaces()
		
	},[])





return <>
    <div className="nk-block-head nk-block-head-sm">
	<div className="nk-block-between">
		<div className="nk-block-head-content">
			<h3 className="nk-block-title page-title">Add Patient</h3>
			<div className="nk-block-des text-soft"><p>Input new Patient information carefully.</p></div>
		</div>
		<div className="nk-block-des text-soft float-end"><p className='btn btn-primary' onClick={(e)=>setShowBulkPatientsModal(true)}>Bulk Upload</p></div>
	</div>
</div>
<div className="nk-block">
	<div className="card card-bordered">
		<div className="card-inner-group">
			<div className="card-inner">
				<div className="nk-block-head">
					<div className="nk-block-head-content">
						<h5 className="title nk-block-title">Personal Info</h5>
						<p>Add common infomation like Name, Email etc</p>
					</div>
				</div>
				<div className="nk-block">
					<div className="row gy-4">


					<div className="col-xxl-3 col-md-4">
						<div className="form-group">
							<label className="form-label" for="full-name">First Name</label>
								<div className="form-control-wrap">
									<input type="text" className="form-control" id="full-name" placeholder="First Name"
									value={firstName}
										onChange={(e)=>setFirstName(e.target.value)}/>
								</div>
						</div>
					</div>
						
					<div className="col-xxl-3 col-md-4">
						<div className="form-group">
							<label className="form-label" for="full-name">Last Name</label>
								<div className="form-control-wrap">
									<input type="text" className="form-control" id="full-name" placeholder="Second Name"
									value={lastName}
										onChange={(e)=>setLastName(e.target.value)}/>
								</div>
						</div>
					</div>
								
					<div className="col-xxl-3 col-md-4">
						<div className="form-group">
							<label className="form-label">Date of Birth</label>
							<div className="form-control-wrap">
								<div className="form-icon form-icon-right"></div>
									<input type="date" className="form-control " data-date-format="dd-mm-yyyy" placeholder="dd-mm-yyyy"
										value={dob} onChange={(e)=>setDob(e.target.value)} />
								</div>
						</div>
					</div>
					<div className="col-xxl-3 col-md-4">
						<div className="form-group">
							<label className="form-label">Gender</label>
							<div className="form-control-wrap">
								<select
									className="form-select "
									value={gender}
									onChange={(e) => setGender(e.target.value)}
									aria-hidden="true"
								>
									<option value="" disabled>Select Gender</option>
									{genders.map((gender)=>(
										<option value={gender.id} >{gender.gender}</option>
									))}
								</select>
							</div>
						</div>
					</div>
					<div className="col-xxl-3 col-md-4">
						<div className="form-group">
							<label className="form-label" for="email">National ID/Passport</label>
							<div className="form-control-wrap">
								<input type="text" className="form-control" id="id" placeholder="National ID/ Passport" value={nationalId}
								onChange={(e)=>setNationalId(e.target.value)}/></div>
						</div>
					</div>

						
					<div className="col-xxl-3 col-md-4">
						<div className="form-group">
							<label className="form-label" for="email">Email</label>
							<div className="form-control-wrap"><input type="email" className="form-control" id="email" placeholder="Email"
							value={email}
								onChange={(e)=>setEmail(e.target.value)}/></div>
						</div>
					</div>

					<div className="col-xxl-3 col-md-4">
							<div className="form-group">
								<label className="form-label">Blood Group</label>
								<div className="form-control-wrap">
									<select className="form-select"  onChange={(e) => setBloodGroup(e.target.value)} aria-hidden="true">
										<option value="" disabled>Select Blood Group</option>
										
										{bloodgroups.map((bloodgroup)=>(
											<option value={bloodgroup.value}>{bloodgroup.group}</option>
										))}
									</select>
									
								</div>
							</div>
						</div>

						<div className="col-xxl-3 col-md-4">
							<div className="form-group">
								<label className="form-label">Company</label>
								<div className="form-control-wrap">
									
										<Select 
											options={options} 
											onChange={handleWorkPlaceChange} 
											placeholder="Search Company..." 
										/>
									
									</div>
									
									
									
							</div>
						</div>

						<div className="col-xxl-3 col-md-4">
						<div className="form-group">
							<label className="form-label">City</label>
							<div className="form-control-wrap">
								<select className="form-select" value={cityId}  onChange={(e)=>setCityId(e.target.value)} aria-hidden="true">
									<option value="" disabled>Select City</option>
									{cities.map((city) => (
										<option key={city.id} value={city.id}>
											{city.name}
										</option>
									))}
									
								</select>
							</div>
						</div>
					</div>

					
						
					<div className="col-xxl-3 col-md-4">
						<div className="form-group">
							<label className="form-label">Insurance card number</label>
							<div className="form-control-wrap">
								<input type='text' className='form-control'
								 placeholder='Enter Insurance Card Number'
								onChange={(e)=> setInsuranceCardNumber(e.target.value)}
								/>
							</div>
						</div>
					</div>
					
					<div className="col-xxl-3 col-md-4">
						<div className="form-group">
							<label className="form-label">Medevac Insurance Card Number</label>
							<div className="form-control-wrap">
								<input type='text' className='form-control' 
									placeholder='Medevac Insurance Card Number'
									onChange={(e)=> setMedInsuranceCardNumber(e.target.value)}
								/>
							</div>
						</div>
					</div>



					<div className='row'>
					{phoneNumbers.map((phoneNumber, index) => (
						<div className="col-xxl-3 col-md-4" key={index+1}>
								<div className="form-group" >
								
										<label className="form-label" htmlFor={`phone-${index}`}>Phone {index + 1}</label>
										<div className="form-control-wrap">
										<input
											type="text"
											className="form-control"
											id={`phone-${index}`}
											placeholder="Phone Number"
											value={phoneNumber}
											onChange={(event) => handlePhoneNumberChange(index, event)}
											/>
										</div>
								</div>
							</div>
						))}
					   <span type="button" onClick={handleAddPhoneNumber} style={{color:'blue',textDecoration:'underline'}}>Add Phone Number </span>
					   {phoneError && <span className='text-danger fw-bold'> {phoneError}</span> }
					</div>
				</div>
			</div>
			</div>
			
			<div className="card-inner">
				
					
				<div className="nk-block">
					<div className="row gy-4">
						<div className="col-12">
							<div className="form-group col-md-2 col-lg-2 col-sm-12 mx-auto">
									<Button onClick={submitHandler} >Add Patient</Button>
									{isLoading && <p className='mb-3 text-center'>Submitting ...</p>}
									{!isLoading && isSuccess && <p className='mb-3 mt-3 text-center text-success'><b>{message}</b></p>}
									{error && !isLoading && <p className='mb-3 text-danger text-center'>{error}</p>}
									
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	{showBulkPatientsModal && <BulkUploadPatientsModal onClose={(e)=>setShowBulkPatientsModal(false)}/>}
</div>

    </>
}

export default CreatePatient;