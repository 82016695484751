import { useEffect, useState } from "react"
import apiClient from "../../utils/axios"
import { NavLink, Router ,useNavigate } from "react-router-dom";
import Select from 'react-select'
import ReactPaginate from "react-paginate";
// import Pagination from "../../reusables/Pagination";


const ListPatientVisits = () =>{

	const navigate = useNavigate();

    const [patientVisits,setPatientVisits] = useState([]);
	const [patients,setPatients] = useState([])
	const [selectedOption,setSelectedOption] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");
	const [success,setSuccess] = useState("")
	const [totalCount,setTotalCount] = useState(0);
	const [pageNo,setPageNo] = useState(1);
	const [isSuccess, setIsSuccess] = useState(false)
	const [departmentOptions,setDepartmentOptions] = useState([])
	const [selectedDepart, setSelectedDepart] = useState("")
	const limit = 10;
	
	//consulation
	const [consultations,setConsultations] = useState({})


    const handleSearch = () =>{

    }

	
	const handlePageClick = (data) => {
       setPageNo(data.selected + 1)
    }

	const options = patients.map(patient => ({
        value: patient.id,
        label: patient.name
    }));

	const departOptions = departmentOptions.map(depart=>({
		value: depart.id,
        label: depart.name
	}))

	const handleChange = selectedOption => {
		setSelectedOption(selectedOption);
    };

	const handleDepartChange = selectedDepart =>{
		setSelectedDepart(selectedDepart);
	}


	const checkinHandler = async () =>{

		if (!selectedOption) {
            setError("please select a patient first")
			setTimeout(() => {
				setError("")
			}, 3000);
            return;
        }

		if(!selectedDepart){
			setError("please select a department")
			setTimeout(() => {
				setError("")
			}, 3000);
			return ;
		}

		if(!selectedDepart && !selectedOption){
			setError("please start by selecting a patients option")
			setTimeout(() => {
				setError("")
			}, 3000);
			
		}

		let data = {
			patient_id:selectedOption.value,
			department_id:selectedDepart.value,
		}

		// console.log("data is loaded : ", data)

		try {
			const response = await apiClient.post('patient-visits/add',data)
			if(response.status === 200 || response.status === 201 ){
				setSuccess('Successfully made a checkin')
				setTimeout(() => {
					setSuccess("")
				}, 3000);
				setSelectedOption(null); 
				fetchPatientVists(pageNo)
			}else{
				setSuccess("")
				console.log("something happend",response)
			}
		} catch (error) {
			console.log(error)
		}
	}

	

	const fetchPatientVists = async (pageNo = 1) => {
		
        let data = {
			pageNo: pageNo,
			limit: limit
		}
        try {
            const response = await apiClient.post("patient-visits",data);
            if(response.status === 200){
                setTotalCount( response.data.count)
                setPatientVisits(response.data.data.data)
                setIsLoading(false)
            }
            
        } catch (error) {
            console.log(error)
        }
    }

	const fetchPatients = async () =>{
		try {
			const response = await apiClient.post("patients",{value:true})
			
			if(response.status == 200 ){
				setPatients(response.data)
				setIsLoading(false)
				
			}else{
				console.log("something happened ", response)
				setPatients([])
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			setError("An error occured")
		}
	}

	const fetchDepartments = async () =>{
		try {
			const response = await apiClient.post("departments")			
			if(response.status === 200 ){
				setDepartmentOptions(response.data)
				setIsLoading(false)
			}else{
				console.log("something happened ", response)
				setDepartmentOptions([])
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			setError("An error occured")
		}
	}

	

	const loadMangePatientHandler = async (patient) => {
		//create a unique feild for a visit
		try {
			let url = "consultation/create-or-retrieve"
			const response = await apiClient.post(url, { patient_visit_id: patient.id });
			if (response.status === 200) {
				let consultationId = response.data.consultation_id;
				if(!consultationId){
					return ;
				}

				console.log("reaponse data load",consultationId )
				navigate(`/manage-patient/${patient.patient_id}/${consultationId}`)
			} else {
				alert("something happened");
			}
			
		} catch (error) {
			console.log(error)
		}
	
	}

    useEffect(()=>{
        const patientVists = async  () =>{
			 await fetchPatients();
			 await fetchDepartments();
             await fetchPatientVists(pageNo);
        }
        patientVists();
    },[pageNo,limit])

    return <>
       {/* <div className="nk-block-head nk-block-head-sm"> */}
	   <div >
			<div className="nk-block-between">
				<div className="nk-block-head-content">
					<h3 className="nk-block-title page-title"> Patients Visits</h3>
					
					<div className="nk-block-des text-soft"><p>Total of {totalCount} patient visits in hospital.</p></div>
				</div>
				<div className="nk-block-head-content">
					<a href="/demo7/hospital/patient-add.html" className="btn btn-icon btn-primary d-md-none"><em className="icon ni ni-plus"></em></a>
					<NavLink to="/patients/add-patient" className="btn btn-primary d-none d-md-inline-flex"><em className="icon ni ni-plus"></em><span>Add Patient</span></NavLink>
				</div>
			</div>
		</div>
		<div className="nk-block"  >
		 	<div className="row">
			 <p className="text-success fw-bold text-center">{success && success}</p>
			 <p className="text-danger fw-bold  fw-bold text-center">{error && error}</p>
			 {patients.length > 0 && <div ><h4>Add a checkin </h4></div> }
			 {patients && patients.length > 0 && <div className="patient-list col-4 mb-3" style={{zIndex:2}}>
				<Select 
					options={options} 
					onChange={handleChange}
					placeholder="Select a patient..."
					isClearable
				/>
				
				</div>
			 }

			{/* departments */}

			{patients.length > 0  && departmentOptions && departmentOptions.length > 0 && <div className="patient-list col-4 mb-3" style={{zIndex:2}}>
				<Select 
					options={departOptions} 
					onChange={handleDepartChange}
					placeholder="Select a department..."
					isClearable
				/>
				
			</div>

			
			}
			{patients.length > 0 && <div className="col-1 mb-3  btn btn-primary btn-sm"  onClick={checkinHandler} style={{justifyContent:"center"}}>Check in</div>}
			</div>


			<div className="card card-bordered card-stretch">
				<div className="card-inner-group">
					
					
					<div className="card-inner p-0 table-responsive">
						<div className="text-center">
							<div className="mb-3">{isLoading && <p>Loading...</p>}</div>
							<div className="mb-3">{!isLoading && !error && patients.length === 0 && <p>No patient visits</p>}</div>
							<div>{error !== "" && <p>No Patients</p>}</div>
						</div>
						{patientVisits && patientVisits.length > 0 && <>
							<div className="nk-tb-list nk-tb-ulist">
								<div className="nk-tb-item nk-tb-head">
									
									<div className="nk-tb-col"><span className="sub-text fw-bold">Patient name</span></div>
									<div className="nk-tb-col tb-col-sm"><span className="sub-text fw-bold">Department</span></div>
									<div className="nk-tb-col tb-col-xxl"><span className="sub-text fw-bold">Status</span></div>
									<div className="nk-tb-col tb-col-lg"><span className="sub-text fw-bold">Date of visit</span></div>								
									
									<div className="nk-tb-col nk-tb-col-tools">
										<ul className="nk-tb-actions gx-1 my-n1">
										<div className="nk-tb-col tb-col-xxl"><span className="sub-text fw-bold">Actions</span></div>
											
										</ul>
									</div>
								</div>

								{patientVisits.map((patient) => (
										<div className="nk-tb-item" key={patient.id}>
											<div className="nk-tb-col">
												<NavLink to="/">
													<div className="user-card">
														<div className="user-avatar bg-primary">
															<span>{patient.initials}</span>
														</div>
														<div className="user-info">
															<span className="tb-lead">{patient.patient_name}</span>
															<span>{patient.email}</span>
														</div>
													</div>
												</NavLink>
											</div>
											<div className="nk-tb-col tb-col-sm">
												<span className="fw-medium">{patient.department_name}</span>
											</div>
											<div className="nk-tb-col tb-col-lg">
												<span>{patient.status}</span>
											</div>
											<div className="nk-tb-col tb-col-md">
												<span className="tb-lead">{patient.checkin_date}</span>
											</div>
											<div className="nk-tb-col nk-tb-col-tools">
												<span
													className="tb-lead btn btn-primary"
													onClick={() => loadMangePatientHandler(patient)}
													style={{ color: "#ffffff" }}
												>
													Manage
												</span>
											</div>
										</div>
									))}
							</div>
						</>}

						<div className="mt-3">
							{totalCount > limit &&
								<div className="mt-3 mb-2">
								{/* <CustomPagination  totalCount={totalCount} limit={limit} handlePageClick={handlePageClick} /> */}
									<ReactPaginate
										previousLabel={"previous"}
										nextLabel={"next"}
										breakLabel={"..."}
										pageCount={(totalCount / limit)}
										marginPagesDisplayed={3}
										pageRangeDisplayed={3}
										onPageChange={handlePageClick}
										containerClassName={"pagination justify-content-center"}
										pageClassName={"page-item"}
										pageLinkClassName={"page-link"}
										previousClassName={"page-item"}
										previousLinkClassName={"page-link"}
										nextClassName={"page-item"}
										nextLinkClassName={"page-link"}
										breakClassName={"page-item"}
										breakLinkClassName={"page-link"}
										activeClassName={"active"}
									/> 
							</div>
							}
						</div>
					</div>
					
				</div>
			</div>
		</div>
    </>
}

export default ListPatientVisits;