import { useState } from "react";
import {  NavLink } from "react-router-dom";
import { ROLES } from "../constants/rolesPermissions";
import { useAuth } from "../store/AuthContext";
import useCheckRole  from "../reusables/CheckRoleOrPermission";
// import { PERMISSIONS } from "../constants/rolesPermissions";

    const Sidebar = () => {

    const [activeItem, setActiveItem] = useState(null);
    const { settings} = useAuth()

    const { hasRole } = useCheckRole(); //this is a custom function to check for role of the logged in user
        
    const isShowMenu = (menu) =>{
        const setting = settings.find(setting => setting.slug === menu)
        return setting && setting.value !== 0 ? setting.value : ""
    }    

    //checks for sidebars meant only for admins
    const isAdmin = () => {
      return hasRole(ROLES.ADMIN)
    };



    const handleItemClick = (itemName) => {
        setActiveItem(itemName === activeItem ? null : itemName);
    };
    return  <div class="nk-sidebar-main is-light">
                <div class="nk-sidebar-inner" data-simplebar>
                    <div class="nk-menu-content menu-active" data-content="navHospital">
                        <h5 class="title">Hospital Management</h5>
                        <ul class="nk-menu">
                            <li class="nk-menu-item">
                                <NavLink to="/" class="nk-menu-link">
                                    <span class="nk-menu-icon"><em class="icon ni ni-dashboard-fill"></em></span>
                                    <span class="nk-menu-text">Dashboard</span>
                                </NavLink>
                            </li>
                       

                            <li className={`nk-menu-item has-sub ${activeItem === 'patient' ? 'active' : ''}`}>
                                <a href="#" className="nk-menu-link nk-menu-toggle" onClick={() => handleItemClick('patient')}>
                                    <span className="nk-menu-icon"><em className="icon ni ni-users-fill"></em></span>
                                    <span className="nk-menu-text">Patient</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <NavLink to="/patients/add-patient" className="nk-menu-link">
                                            <span className="nk-menu-text">Add Patient</span>
                                        </NavLink>
                                    </li>
                                    <li className="nk-menu-item">
                                        <NavLink to="/patients" className="nk-menu-link">
                                            <span className="nk-menu-text"> Patients List</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className={`nk-menu-item has-sub ${activeItem === 'patientVisits' ? 'active' : ''}`}>
                                <a href="#" className="nk-menu-link nk-menu-toggle" onClick={() => handleItemClick('patientVisits')}>
                                    <span className="nk-menu-icon"><em className="icon ni ni-plus-medi-fill"></em></span>
                                    <span className="nk-menu-text">Patient Visits</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <NavLink to="/patient-visits" className="nk-menu-link">
                                            <span className="nk-menu-text">List Patient Visits</span>
                                        </NavLink>
                                    </li>
                               
                                </ul>
                            </li> 

                            {isAdmin() && <li className={`nk-menu-item has-sub ${activeItem === 'Inventory' ? 'active' : ''}`}>
                                <a href="#" className="nk-menu-link nk-menu-toggle" onClick={() => handleItemClick('Inventory')}>
                                    <span className="nk-menu-icon"><em className="icon ni ni-plus-medi-fill"></em></span>
                                    <span className="nk-menu-text">Inventory</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    {isShowMenu('enable-suppliers-menu') && <li className="nk-menu-item">
                                        <NavLink to="/inventory/suppliers" className="nk-menu-link">
                                            <span className="nk-menu-text">Suppliers</span>
                                        </NavLink>
                            </li>}
                             <li className="nk-menu-item">
                                
                                        <NavLink to="/inventory/drugs" className="nk-menu-link">
                                            <span className="nk-menu-text">Drugs</span>
                                        </NavLink>
                                    </li>
                                    <li className="nk-menu-item">
                                        <NavLink to="/inventory/brands" className="nk-menu-link">
                                            <span className="nk-menu-text">Brands</span>
                                        </NavLink>
                                    </li>
                                   
                                    <li className="nk-menu-item">
                                        <NavLink to="/inventory/batches" className="nk-menu-link">
                                            <span className="nk-menu-text">Batches</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li> }
                    
                            {isAdmin() &&<li  className={`nk-menu-item  ${activeItem === 'report' ? 'active' : ''}`}>
                                    <NavLink to="/reports" class="nk-menu-link">
                                        <span class="nk-menu-icon"><em class="icon ni ni-plus-medi-fill"></em></span>
                                        <span class="nk-menu-text" >Report</span>
                                    </NavLink>
                            </li>}
                        
                            {isAdmin() &&  <li className={`nk-menu-item mt-1 ${activeItem === 'Settings' ? 'active' : ''}`} >
                                <NavLink to="/system-setup" class="nk-menu-link">
                                    <span class="nk-menu-icon"><em class="icon ni ni-plus-medi-fill"></em></span>
                                    <span class="nk-menu-text" >SetUp</span>
                                </NavLink>
                            </li>}
                    

                        </ul>
                    </div>
                </div>
            </div>
}

export default Sidebar;