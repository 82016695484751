
import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";
import Button from "../../../reusables/Button";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';

const EditBrand = () => {
    

    const { brandId } = useParams()
    
    const [error, setError] = useState("")
    const [name, setName] = useState("")
    const [drugName, setDrugName] = useState("")
    const [brand , setBrand] = useState({})
    const [drugs, setDrugs] =useState([])
    const [successMessage, setSuccessMessage] = useState("")
    const [isLoading, setIsLoading] = useState(true)      
    const [drugId,setDrugId] = useState("")
    
    const navigate = useNavigate()

     const options = drugs && drugs.map(drug => ({
        value: drug.id,
        label: drug.name
     }));
    
    
    const handleDrugChange = (seletedDrugOption) => {
        if (seletedDrugOption && seletedDrugOption.value) {
            const selectedBrand = drugs.find(brand => brand.id === parseInt(seletedDrugOption.value));
            setDrugId(selectedBrand.id) 
        } else {
            setDrugId(""); // Handle empty or undefined case
        }
    }


    const handleUpdate = async () => {
        if (name.trim() === "" || brandId === "") {
            setError("Brand name required")
        };

        let data = {
            name: name,
            drug_id:drugId,
        }
        try {
            const response = await apiClient.post(`brands/update/${brandId}`, data);
            if (response.status === 200) {
                setError("")
                setSuccessMessage("Success")
                setTimeout(() => {
                    setSuccessMessage("")
                    navigate("/inventory/brands")
                }, 1000);
            }
        } catch (error) {
            console.log("response", error.response)
        } finally {
            setIsLoading(false)
        }
    }

    

     const fetchBrand  = async () =>{
        let url = `brands/view/${brandId}`
        console.log("brand id", url)
        try {
            const response = await apiClient.post(url)
            
            if(response.status === 200){

                setName(response.data.name)
                setDrugName(response.data.drug.name)
                setBrand(response.data)

                
            }else {
                console.error('Failed to fetch Brand data');
            }

        } catch (error) {
            console.log(error)
            setIsLoading(true)
        }finally {
            setIsLoading(false); // Always set isLoading to false in finally block
        }
    }

     const fetchDrugs = async () => {
        let url = `drugs/list`;

        let data = {
            value:true
        }
        const response = await apiClient.post(url,data)
         if (response.data.status) {
            setDrugs(response.data.data)
        } else {
            console.log("something happend")
        }
    }


    useEffect(() => {
        fetchDrugs()
        fetchBrand()
    },[brandId])
    return  <>
        <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                    <div class="nk-block-head-content">
                        <h3 class="nk-block-title page-title">Update Brand </h3>
                        <div class="nk-block-des text-soft"><p>Update Brand information carefully.</p></div>
                    </div>
                    <div class="nk-block-des text-soft float-end"><p class="btn btn-primary">Bulk Upload</p></div>
                </div>
            </div>
            <div class="nk-block">
                <div class="card card-bordered">
                    <div class="card-inner-group">
                        <div class="card-inner">
                            <div class="nk-block-head">
                                <div class="nk-block-head-content">
                                    <h5 class="title nk-block-title">Update Brand for Drug: {drugName}  </h5>
                                    <p>Update Brand infomation like Name etc</p>
                                </div>
                            </div>
                            <div class="nk-block">
                            
                                <div class="row gy-4">

                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="full-name">Drug Name</label>
                                                <div class="form-control-wrap">
                                                <Select 
                                                    options={options} 
                                                    onChange={handleDrugChange} 
                                                    placeholder="Search Drug..." 
                                                />
                                            </div>
                                        </div>
                                    </div>

                                
                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Brand Name</label>
                                            <div class="form-control-wrap">
                                                <input type="text" className="form-control" name="lpo" value={name} onChange={(e)=>setName(e.target.value)} />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="card-inner">
                            <div class="nk-block">
                                <div class="row gy-4">
                                    <div class="col-12">
                                        <div class="form-group col-md-2 col-lg-2 col-sm-12 mx-auto">
                                            <Button onClick={handleUpdate}>Update Brand</Button>
                                            <p className="text-center text-danger fw-bold">{error && error }</p>
                                            <p className="text-center text-success fw-bold">{successMessage && successMessage}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
}
export default EditBrand ;