export const genders = [
    {
        "id":1,
        "gender":"Male",
        "slug":"male"
    },
    {
        "id":2,
        "gender":"Female",
        "slug":"female"
    },
    {
        "id":3,
        "gender":"Other",
        "slug":"other"
    },
]

