import { useAuth } from "../../../store/AuthContext";


const PersonalProfile = () => {

    const { authUser, roles, hospital } = useAuth()
    
    console.log("hossp ",roles)

    
    return <div className="tab-pane active" id="tabItem1" role="tabpanel">
                                    <div className="nk-block">
                                        <div className="nk-block-head">
                                            <div className="nk-block-head-content">
                                                <h5 className="nk-block-title">Personal Information</h5>
                                                {/* <div className="nk-block-des">
                                                    <p>Basic info, like your name and address, that you use on Nio Platform.</p>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="nk-data data-list">
                                                <div className="data-item" >
                                                    <div className="data-col">
                                                        <span className="data-label">Full Name</span>
                                                        <span className="data-value">{authUser.name}</span>
                                                    </div>
                                                    <div className="data-col data-col-end"><span className="data-more"><em className="icon ni ni-forward-ios"></em></span></div>
                                                </div>
                                                <div className="data-item">
                                                    <div className="data-col">
                                                        <span className="data-label">Email</span>
                                                        <span className="data-value">{authUser.email}</span>
                                                    </div>
                                                    <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
                                                </div>
                                                <div className="data-item" >
                                                    <div className="data-col">
                                                        <span className="data-label">Phone Number</span>
                                                        <span className="data-value text-soft">{authUser.phone}</span>
                                                    </div>
                                                    <div className="data-col data-col-end"><span className="data-more"><em className="icon ni ni-forward-ios"></em></span></div>
                                                </div>
                                                
                                                <div className="data-item" >
                                                    <div className="data-col">
                                                        <span className="data-label">Hospital</span>
                                                        <span className="data-value">{ authUser.hospital.hospital_name}</span>
                                                    </div>
                                                    <div className="data-col data-col-end"><span className="data-more"><em className="icon ni ni-forward-ios"></em></span></div>
                                                </div>
                        
                                                <div className="data-item">
                                                    <div className="data-col">
                                                        <span className="data-label">Role</span>
                                                        <span className="data-value">{roles.map(role =>  role )}</span>
                                                    </div>
                                                    <div className="data-col data-col-end"><span className="data-more"><em className="icon ni ni-forward-ios"></em></span></div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
}

export default PersonalProfile;