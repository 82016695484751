import { useState } from "react";
import HospSettings from "../../components/settings/SystemSettings/HospSettings";
import GeneralSettings from "../../components/settings/SystemSettings/GeneralSettings";
import useCheckRole from "../../reusables/CheckRoleOrPermission";
import { ROLES } from "../../constants/rolesPermissions";
import Notifications from "../../components/settings/SystemSettings/Notifications";

const SystemSettings = () => {
    
    
  
  const { hasRole } = useCheckRole();
  
  
  const isAdmin = () => {
      return hasRole(ROLES.SUPER_ADMIN)
  };
  
  const initialTab = isAdmin() ? 'general' : 'businessInfo';
  const [activeTab, setActiveTab] = useState(initialTab);
  
  const renderTabContent = () => {
    switch (activeTab) {
      case 'general':
        return isAdmin() ? <GeneralSettings /> : <HospSettings/>;
      case 'businessInfo':
        return <HospSettings/>
      case 'notifications':
        return <Notifications/>
      default:
        return null;
    }
  };


return <div className="nk-content-body">
           
            <div className="nk-block nk-block-lg">
                <div className="card">
                    <div className="card-content">
                        <ul className="nav nav-tabs nav-tabs-card" role="tablist">
                            {isAdmin() && <li className="nav-item" role="presentation">
                              <a className={`nav-link ${activeTab === 'general' ? 'active ' : ''}`} onClick={() => setActiveTab('general')} aria-selected="true" role="tab"><span>General</span></a>
                            </li>}
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${activeTab === 'businessInfo' ? 'active ' : ''}`} onClick={() => setActiveTab('businessInfo')}   aria-selected="false" tabindex="-1" role="tab"><span>Settings</span></a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${activeTab === 'notifications' ? 'active ' : ''}`} onClick={() => setActiveTab('notifications')}   aria-selected="true" role="tab"><span>Notifications</span></a>
                            </li>
                           
                        </ul>
                        <div className="card-inner">
                            <div className="tab-content">
                                {renderTabContent()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

  

  // return (
  //   <div className="container-main">
  //     <div className="tabs">
  //       {isAdmin() && <button
  //         className={`tab-button ${activeTab === 'general' ? 'active' : ''}`}
  //         onClick={() => setActiveTab('general')}
  //       >
  //         General
  //       </button>}
  //       <button
  //         className={`tab-button ${activeTab === 'businessInfo' ? 'active' : ''}`}
  //         onClick={() => setActiveTab('businessInfo')}
  //       >
  //         Settings
  //       </button>
  //       <button
  //         className={`tab-button ${activeTab === 'notifications' ? 'active' : ''}`}
  //         onClick={() => setActiveTab('notifications')}
  //       >
  //         Notifications
  //       </button>
  //     </div>
  //     <div className="tab-content">
  //       {renderTabContent()}
  //     </div>
  //   </div>
  // );
}


export default SystemSettings;