const ProceduresTable = ({ myProcedures }) => {
    

    const tableData = myProcedures.length > 0 ? myProcedures.map(procedure => (
            <tr key={procedure.id}>
                {/* <th scope="row">1</th> */}
                <td>{procedure.procedure}</td>
                <td>{procedure.quantity}</td>
            <td>{procedure.description}</td>
            <td>{procedure.dateDone}</td>
            </tr>
    )) : []
    return <div>
       
            <div className="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            {/* <th scope="col">#</th> */}
                            <th scope="col">Procedure</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Description</th>
                            <th scope="col">Date Added</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </table>
            </div>
        </div>
}

export default ProceduresTable;