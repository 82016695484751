import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";
import Button from "../../../reusables/Button";
import { useNavigate ,useParams } from "react-router-dom";
import Select from 'react-select';


const EditDrug = () => {
    

    const { drugId } = useParams()
    
    const [name, setName] = useState("                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ");
    const [error, setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [isLoading,setIsLoading] = useState(true)    
    const [brandId, setBrandId] = useState("");                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
    
    const navigate = useNavigate()

    const handleUpdate = async () => {

        if (name.trim() === "") {
            setError("Drug name required")
        };

        let data = {
            name:name,
        }
        try {
            const response = await apiClient.post(`drugs/update/${drugId}`, data);
            if (response.status === 200) {
                setError("")
                setSuccessMessage("Success")
                setTimeout(() => {
                    setSuccessMessage("")
                    navigate("/inventory/drugs")
                }, 1000);
            }
        } catch (error) {
            console.log("response", error.response)
        } finally {
            setIsLoading(false)
        }
    }

    

     const fetchDrug  = async () =>{
        let url = `drugs/view/${drugId}`
        try {
            const response = await apiClient.post(url)
            console.log("drug",response)
            if(response.status === 200){
                setName(response.data.drug.name)
            }else {
                console.error('Failed to fetch Brand data');
            }

        } catch (error) {
            console.log(error)
            setIsLoading(true)
        }finally {
            setIsLoading(false); // Always set isLoading to false in finally block
        }
    }

    // const fetchBrands = async () => {
    //     try {
    //         let url = `brands/list`;
    //         // let data = {
    //         //     value:true
    //         // }
    //         const response = await apiClient.post(url)
    //         if (response.status === 200) {
    //             setBrands(response.data)
    //         } else {
    //             console.log("something happend")
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }




    useEffect(() => {
        fetchDrug()
    },[drugId])
    return  <>
        <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                    <div class="nk-block-head-content">
                        <h3 class="nk-block-title page-title">Update Drug</h3>
                        <div class="nk-block-des text-soft"><p>Update Drug information carefully.</p></div>
                    </div>
                    {/* <div class="nk-block-des text-soft float-end"><p class="btn btn-primary">Bulk Upload</p></div> */}
                </div>
            </div>
            <div class="nk-block">
                <div class="card card-bordered">
                    <div class="card-inner-group">
                        <div class="card-inner">
                            <div class="nk-block-head">
                                <div class="nk-block-head-content">
                                    <h5 class="title nk-block-title">Update Drug :  {name}</h5>
                                    <p>Update Drug infomation like Name etc</p>
                                </div>
                            </div>
                            <div class="nk-block">
                            
                                <div class="row gy-4">

                                {/* <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="full-name">Brand Name</label>
                                                <div class="form-control-wrap">
                                                <Select 
                                                    options={options} 
                                                    onChange={handleBrandChange} 
                                                    placeholder="Search Brand..." 
                                                />
                                            </div>
                                        </div>
                                    </div> */}


                                
                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Drug Name</label>
                                            <div class="form-control-wrap">
                                                <input type="text" className="form-control" name="lpo" value={name} onChange={(e)=>setName(e.target.value)} />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="card-inner">
                            <div class="nk-block">
                                <div class="row gy-4">
                                    <div class="col-12">
                                        <div class="form-group col-md-2 col-lg-2 col-sm-12 mx-auto">
                                            <Button onClick={handleUpdate}>Update Drug</Button>
                                            <p className="text-center text-danger fw-bold">{error && error }</p>
                                            <p className="text-center text-success fw-bold">{successMessage && successMessage}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
}
export default EditDrug ;