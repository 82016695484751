import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";
// import DepartmentsSetUp from './DepartmentsSetUp';

const DepartmentsSetUp = () => {

    const [error, setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddDepartment, setIsAddDepartment] = useState(false)
    const [isEditDepartment, setIsEditDepartment] = useState(false)
    const [departmentName, setDepartmentName] = useState("");
    const [departmentsList,setDepartmentsList] = useState([])
    const [id, setId] = useState("")


    const submitHandler = async (e) => {
        e.preventDefault();
        if (departmentName.trim() === "") {
            setError("department name cannot be empty")
            return;
        }

        if (isEditDepartment) {
            let data = {
                id:id,
                name:departmentName
            }

            const response = await apiClient.put(`departments/${id}`,data)
            if (response.status === 200 && response.data.status) {
                setError("")
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage("")
                }, 3000)
                getDepartmentData()
                setIsAddDepartment(false)
                setIsEditDepartment(false)
                setDepartmentName("")
            }
        } else {
            let data = {
                name:departmentName
            }
             const response = await apiClient.post(`departments/store`,data)
            if (response.status === 200 && response.data.status) {
                
                setError("")
                getDepartmentData()
                setIsAddDepartment(false)
                setIsEditDepartment(false)
                setDepartmentName("")
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage("")
                }, 3000)
                
            }
        }
            

    }

     const handleEditDepartment = (department) => {
        setId(department.id)
        setIsAddDepartment(true)
        setIsEditDepartment(true)
        setDepartmentName(department.name)
    }
    const handleDeleteDepartment = async (departmentId) => {
        const response = await apiClient.delete(`departments/${departmentId}`)
        if(response.status === 200 && response.data.status ){
            setSuccessMessage(response.data.message)
             setTimeout(() => {
                setSuccessMessage("")
             }, 3000);
            
            getDepartmentData()
        }
    }
    const backToList = () => {
        setIsAddDepartment(false)
        setIsEditDepartment(false)
        setDepartmentName("")
        setId("")
    }


    const getDepartmentData = async () => {
        setIsLoading(true)
        const response = await apiClient.post(`departments`)
        if (response.status === 200 ) {
            setIsLoading(false)
            console.log("response",response.data)
            setDepartmentsList(response.data)
		} else {
			console.log("something happened")
		}
    }

     useEffect(() => {
        getDepartmentData()
     }, [])
    


    const departmentsCreate = (<div class="row gy-4">
        
        <div class="example-alert">
            <div class="alert alert-success alert-icon">
            Use this Page to set up the <strong>departments</strong> you have 
            </div>
        </div>
        <div className="d-flex justify-content-between " style={{ cursor: 'pointer' }} >
            <div className="mt-3">{isEditDepartment ? 'Edit Department' : 'Add Department'}</div>
            <div onClick={backToList} className="mt-3"><em class="icon ni ni-plus" ></em><span>Back To Departments</span></div>
        </div>

        <div class="col-xxl-3 col-md-4">
            <div class="form-group">
                <label class="form-label" for="full-name">Departments</label>
                <div class="form-control-wrap">
                <input type="text" className="form-control" placeholder="Enter departments Name" value={departmentName} onChange={(e) => setDepartmentName(e.target.value)} />
                <button className="btn btn-primary mt-2" onClick={submitHandler}>{!isEditDepartment ? 'Submit' : 'Update'}</button>
                <div>
                        <span className="text-danger fw-bold mt-1">{error && error}</span>
                    <span className="text-success fw-bold mt-1">{ successMessage && successMessage}</span>
                </div>
                </div>
            </div>
        </div>
    </div>)

     const departmentList = <>
        <div className="float-end m-3" style={{ cursor:'pointer' }} onClick={()=>setIsAddDepartment(true)}>
            <em class="icon ni ni-plus" ></em><span>Add Department</span>
        </div>
        <span className="text-success fw-bold mt-3">{ successMessage && successMessage}</span>
         {isLoading && <p className="mt-1 text-center">Loading ...</p>}
         {!isLoading && departmentsList && departmentsList.length === 0 && <p className="mt-1 text-center">No Departments</p>}
         
        {!isLoading && departmentsList && departmentsList.length > 0 && <table className="table table-hover table-striped">
            
                <thead>
                    <tr>
                        
                        <td>Name</td>
                        {/* <td>Description</td> */}
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>

                
                    {departmentsList.map(department => (
                        
                        <tr>
                            
                            <td>{department.name}</td>
                            {/* <td>{department.description}</td> */}
                        <td className = "d-flex gap-1 " >
                                <button className="btn btn-primary p-2" onClick={() => handleEditDepartment(department)} style={{ marginRight: '10px' }}>Edit</button>
                                <button className="btn btn-danger p-2" onClick={() => handleDeleteDepartment(department.id)} >Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
        </table>}
        

    </>

    return isAddDepartment ? departmentsCreate :  departmentList ;
}
export default DepartmentsSetUp;
