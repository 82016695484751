import { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import apiClient from '../utils/axios';
import Button from '../reusables/Button';
import Allprescriptions from './Allprescriptions';

const PharmacyComponent = ({consultationId,id}) =>{

    const [brands,setBrands] = useState([])
    const [batchId,setBatchId] = useState("")
    const [dosage,setDosage] = useState("")
    const[noDispensed,setNoDispensed] = useState("")
    const [selectedBrand,setSeletedBrand] = useState({})
    const [loading,setLoading] = useState(false)
    const [rows, setRows] = useState([]);
    const [success, setSuccess] = useState("")
    const [error, setError] =useState("")
    const [showPrescriptionList,setShowPrescriptionList] = useState(false)
    const [isShowNotificationMessage,setIsShowNotificationMessage] = useState(false)

    //patients prescriptions list data
    const [prescriptions,setPrescriptions] = useState([]);
    const [pageNo,setPageNo] = useState(1);
    const [limit,setLimit] = useState(5);
    const [count,setCount] = useState(0)
    const [isLoading,setIsLoading] = useState(true)


    const options = brands.length > 0 ? brands.map(brand => ({
        value: brand.id,
        label: brand.name
    })) : [];

    const brandQuantityMap = useMemo(() => {
        const map = new Map();
        brands.forEach(brand => map.set(brand.id, brand.quantity_available));
        return map;
    }, [brands]);

    const handleChange = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
        setSeletedBrand(selectedOption);
    } else {
        setSeletedBrand(null); // Handle empty or undefined case
    }
    };

    const handleAddRow = () => {
        const isEmpty = Object.keys(selectedBrand).length === 0 && selectedBrand.constructor === Object
        if(isEmpty){
            return;
        }
        
        if (selectedBrand) {
            // Check if the drug already exists in rows
            const drugExists = rows.some(row => row.brandName === selectedBrand.label);
    
            if (!drugExists) {
                const newRow = {
                    brandId:selectedBrand.value,
                    brandName: selectedBrand.label,
                    dosage: '',
                    noDispensed: '',
                };
                setRows([...rows, newRow]);
            } else {
                // Optionally, provide feedback that the drug is already added
                console.log(`Drug "${selectedBrand.label}" is already added.`);
            }
        }
    };
    const handleSave = async (e) =>{
        e.preventDefault();
        
    //   console.log(rows)
    for (const row of rows) {
            
            if (!row.dosage || !row.noDispensed) {
                return;
            }
            const getNumber = brands.find(brand => brand.id === row.brandId);
            if (getNumber) {
                if (getNumber.quantity_available < row.noDispensed) {
                    setError(`The number dispensed cannot exceed the quantity available for ${row.brandName}`);
                    return;
                }
            } else {
                console.error(`Brand not found for brand ${row.brandName}`);
                setError(`Brand not found for ID ${row.brandName}`);
                return;
            }
            
    }
        
    setLoading(true)
    let url = `patient-prescriptions`
        let data = {
            consultation_id:consultationId,
            rows: JSON.stringify(rows)
        }
    
    try {
        const response = await apiClient.post(url,data)
            // console.log("response length",response.data.data.length)
            if(response.data.status) {
                setLoading(false)
                setSuccess("success")
                setError('')
                setTimeout(() => {
                    setSuccess("")
                    setRows([])
                }, 2000);
                getBrands()
                setShowPrescriptionList(true)

                getPatientPrescriptions()
                
            } else {
                setLoading(false)
                setError(response.data.message)
                
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    
    }

    const getPatientPrescriptions = async () =>{
        let data = {
            pageNo:pageNo,
            limit:limit

        }
        const response = await apiClient.post(`patient-prescriptions/consultations/${consultationId}`,data)
        console.log("reponse prescription",response.data.data.data)
        if(response.data.status){
            setIsLoading(false)
            setPrescriptions(response.data.data.data)
            setCount(response.data.totalCount)

        }else{
            console.log("something happened")
        }
    }

    const getBrands = async () =>{
    try {
        let url = `batches/available-brands`
        const response = await apiClient.get(url)
        console.log("response length",response.data.data.length)
        if(response.status === 200){
            console.log("test response data",response.data)
            if(response.data.data.length === 0 ){
                setIsShowNotificationMessage(true)
            }else{
                setBrands(response.data.data)
            }
            
        }else{
            console.log("something happened")
        }
    
    } catch (error) {
        console.log(error)
    }
    }

    useEffect(()=>{
        getBrands()
        getPatientPrescriptions()
    },[])

      const data1 = <>
      <div className="nk-block-head d-flex  justify-content-between">
            {isShowNotificationMessage && <span style={{color:'red'}}>(Please make sure you have atleast 1 brand in store with available quantity)</span> }
            <div> <h6 className="title">Pharmacy </h6></div>
            {/* <div className='text-primary fw-bold' style={{cursor:'pointer'}} onClick={()=>setShowPrescriptionList(true)}>View Prescription List</div> */}
        </div>
        <div className="nk-block d-flex gap-2">
            <div className="fw-bold " >Select Brand</div>
            <div className='patient-list col-5'>
                <Select
                    options={options}
                    onChange={handleChange}
                    placeholder="Search Brand..."
                />
            </div>
            <div className='col-2'>
                <span className="btn btn-lg btn-primary btn-block " onClick={handleAddRow}>Add</span>
            </div>
        </div>

        
        <div className="nk-block mt-3">
            <div className="card">
                <div className="nk-tb-list nk-tb-ulist is-compact">
                    {rows && rows.length > 0 && <div className="nk-tb-item nk-tb-head">
                          <div className="nk-tb-col"><span className="sub-text"><b>Brand Name</b></span></div>
                          <div className="nk-tb-col"><span className="sub-text"><b>Quantity available</b></span></div>
                        <div className="nk-tb-col"><span className="sub-text"><b>Number Dispensed</b></span></div>
                        <div className="nk-tb-col tb-col-sm"><span className="sub-text"><b>Dosage</b></span></div>
                        <div className="nk-tb-col"><span className="sub-text"><b>Remove</b></span></div>
                    </div>}
                      {rows.map((row, index) => {
                        const quantityAvailable = brandQuantityMap.get(row.brandId) || 0;
                        return (
                            
                                <div className="nk-tb-item" key={index}>
                                    <div className="nk-tb-col"><span>{row.brandName}</span></div>
                                        <div className="nk-tb-col"><span>{ quantityAvailable}</span></div>

                                    <div className="nk-tb-col"><span>
                                        <input
                                            placeholder='Enter Number'
                                            value={row.noDispensed}
                                            onChange={(e) => {
                                                const updatedRows = [...rows];
                                                updatedRows[index].noDispensed = e.target.value;
                                                setRows(updatedRows);
                                            }}
                                            type='number'
                                            className='form-control' /></span>
                                    </div>

                                        <div className="nk-tb-col tb-col-sm"><span>
                                            <textarea
                                                placeholder='Type Dosage'
                                                value={row.dosage}
                                                onChange={(e) => {
                                                    const updatedRows = [...rows];
                                                    updatedRows[index].dosage = e.target.value;
                                                    setRows(updatedRows);
                                                }}
                                                className='form-control'
                                                cols={30}
                                            style={{ minHeight:'0px' }}>
                                            </textarea></span>
                                    </div>
                                        
                                    <div className="nk-tb-col"><span className="btn btn-danger" onClick={() => {
                                        const updatedRows = [...rows];
                                        updatedRows.splice(index, 1);
                                        setRows(updatedRows);
                                    }}>X</span></div>
                                </div>
                            );
                      })}
                   
                </div>
            </div>
           {rows && rows.length > 0 &&  <div className="col-2 ms-auto">
                    <Button type="submit" onClick={handleSave} > Save </Button>
                    {loading && <p>please wait...</p>} 
                    <div className='text-danger fw-bold'> { error && error}</div>
                    <div className='text-success fw-bold'>{success && success}</div>
                    
                </div>}
               <div className='mt-3'>
                    <Allprescriptions prescriptions={prescriptions} count={count} isLoading={isLoading} />
                 {/* <Allprescriptions consultationId={consultationId} /> */}
               </div>
        </div>
      </>

  
    return (<>
        <div>
            {data1}
        </div>

    {/* {!showPrescriptionList ?  data1 : data2} */}
        
    
    </>)
}

export default PharmacyComponent;