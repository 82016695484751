import { useEffect, useState } from "react";
import apiClient from "../utils/axios";
import Select from 'react-select';
import Button from "../reusables/Button";
// import module from './investigations.module.css'
import { roles } from "../constants/roles";
import { ROLES } from "../constants/rolesPermissions";
import useCheckRole from "../reusables/CheckRoleOrPermission";


const Investigations = ({consultationId}) =>{

    const [investigations,setInvestigations] = useState([ ])
    const [selectedInvestigation,setSeletedInvestigation] = useState(null)
    const [investigationList, setInvestigationList] = useState([])
    const [canDelete,setCanDelete] =useState(false)
    const [openModal,setOpenModal] = useState(false)
    const [results,setResult] =useState("")
    
    const [success , setSuccess] = useState("")
    const [error , setError] = useState("")

    const [role,setRole] = useState(1);

    const { hasRole } = useCheckRole();
    console.log("role",hasRole)


    const isAdmin = () => {
        return hasRole(ROLES.ADMIN );
    };

    const isDoctor = () =>{
        return hasRole(ROLES.DOCTOR)
    }

  

    const options = investigations.map(investigation => ({
        value: investigation.id,
        label: investigation.name
      }));

      const handleChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            setSeletedInvestigation(selectedOption.value);
        } else {
            setSeletedInvestigation(null); // Handle empty or undefined case
        }
        
        // Handle the selected option as needed
      };

   
    
    const addToList = async () => {
    if (selectedInvestigation) {
        const selectedInvestigationDetails = investigations.find(
            (inv) => inv.id === selectedInvestigation
        );
        
        // Check if the investigation is already in the list
        const isAlreadyInList = investigationList.some(
            (inv) => inv.id === selectedInvestigation
        );

        if (!isAlreadyInList) {
            // Initialize pivot with default values if not present
            const investigationWithPivot = {
                ...selectedInvestigationDetails,
                pivot: selectedInvestigationDetails.pivot || { results: '' }
            };

            setInvestigationList((prevList) => [
                ...prevList,
                investigationWithPivot,
            ]);
        }

    }
}


    const handleInputChange = (id, value) => {
    setInvestigationList((prevList) =>
        prevList.map((investigation) =>
            investigation.id === id
                ? { ...investigation, pivot: { ...investigation.pivot, results: value } }
                : investigation
        )
    );
};


    

      //remove from list
    const removeFromList = async (id) => {
        // setInvestigationList((prevList) => prevList.filter((inv) => inv.id !== id));
      

        // let url = `patient-investigations/patient-investigations/${consultationId}/investigations/delete`
        // try {
        //     const response = await apiClient.post(url, data)
        //      setInvestigationList((prevList) => prevList.filter((inv) => inv.id !== id));
        // } catch (error) {
        //     console.log(error)
        // }

          //set a request to remove this investigation
        //   const response = await apiClient.post(url)

    };

    const saveInvestigationDoc = async (e) =>{
        e.preventDefault()
        if (investigationList.length === 0) return;
        let url = `patient-investigations/patient-investigations/${consultationId}/investigations`;

         const investigationsData = investigationList.map(({ id, file,pivot }) => ({
            id,
            results: pivot.results,
            file
         }));
        
        let formData = new FormData();
        investigationsData.forEach((investigation, index) => {
            formData.append(`investigations[${index}][id]`, investigation.id);
            formData.append(`investigations[${index}][results]`, investigation.results);
            if (investigation.file) {
                formData.append(`investigations[${index}][file]`, investigation.file);
            }
        });

       
        try {
            const response = await apiClient.post(url,formData,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
            })     
            
            if (response.status === 201) {
                setOpenModal(false)
                loadPreviousConsultations()
                setSuccess(response.data.message)
                setTimeout(() => {
                setSuccess("") 
                }, 3000);
            }else{
                console.log("reponse investigation", response)
            }
        } catch (error) {
            console.log(error)
        }
        

    }
    // const saveInvestigationLab = async  (e) =>{
    //     e.preventDefault()
    //     if (investigationList.length === 0) return;
        
    //     let url =  `patient-investigations/patient-investigations/${consultationId}/investigations`

    //     // const investigations = investigationList.map(({ id, pivot }) => ({ id, results: pivot.results  }));

    //     const investigationsData = investigationList.map(({ id, file,pivot }) => ({
    //         id,
    //         results: pivot.results,
    //         file: file
    //       }));

    //       console.log("file", investigationsData)
       
    //     let data = {
    //         'investigations' : investigationsData,
    //     }

    //     try {

    //         const response = await apiClient.put(url, data, {
    //             headers: {
    //               'Content-Type': 'multipart/form-data',
    //             },
    //           });

    //         console.log("reponse investigation updates", response)       
    //         if (response.status === 200) {
    //             setOpenModal(false)
    //             loadPreviousConsultations()
    //             setSuccess(response.data.message)
    //             setTimeout(() => {
    //             setSuccess("") 
    //             }, 3000);
    //         }else{
    //             console.log("reponse investigation ", response)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const loadPreviousConsultations = async () => {
        let previousConsUrl = `patient-investigations/patient-investigations/${consultationId}/investigations`
        try {
            const response = await apiClient.get(previousConsUrl);
            if (response.status === 200) {
                // console.log("previous", response.data)
                if (response.data.length > 0) {
                    setCanDelete(false)
                } else {
                    setCanDelete(true)
                }
                setInvestigationList(response.data);
                
            } else {
                console.error('Failed to load previous consultations:', response);
            }
        } catch (error) {
            console.error('Error loading previous consultations:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await apiClient.get('investigations');
            if (response.status === 200) {
                setInvestigations(response.data);
            } else {
                setInvestigations([]);
            }
        } catch (error) {
            console.error('Error fetching investigations:', error);
            setInvestigations([]);
        }
    };

   
    const handleFileChange = (id, event) => {
       
        const file = event.target.files[0];
        setInvestigationList((prevList) =>
            prevList.map((investigation) =>
                investigation.id === id
                        ? { ...investigation, file: file }
                        : investigation
                )
            );

        

    };
    
    const downLoadFile = async (id) => {
        let data = {
            consultationId:consultationId,
            investigationId: id
        }
        try {
            const response = await apiClient.post(`patient-investigations/download-file`, data)
            const { file_path } = response.data;

            // // If file path exists, initiate download
            if (file_path) {
                const fileURL  = file_path
                console.log("file",fileURL)
                window.open(file_path, '_blank'); //after opening in a new tab,one can now downloadit from there if they want to
                // const a = document.createElement('a');
                // a.style.display = 'none';
                // a.href = file_path;
                // a.download = `investigation_${consultationId}.jpeg`; // Set the file name for download
                // document.body.appendChild(a);
                // a.click();
                // // Cleanup: Remove the anchor element
                // document.body.removeChild(a);
            } else {
                console.error('File path not found');
            }
        } catch (error) {
            console.log(error)
        }
        

    }

    useEffect(() => {
        fetchData();
        loadPreviousConsultations();
    }, []);



    useEffect(() => {
      }, [investigationList]);

    return <>
    <div class="nk-block-head">
        <div className="d-flex justify-content-between">
            <div>
                <h6 class="title">Investigations Tab</h6>
                <p>Add Investigation</p>
            </div>
            <div>
                 {/* <select className="form-select" onChange={(e)=>setRole(e.target.value)}>
                    {roles.map((role)=>(
                        <option value={role.id}>{role.role}</option>
                    ))}
                    
                </select>  */}
                <p className="text-success fw-bold">{success && success}</p>
                <p className="text-danger fw-bold">{error && error}</p>
            </div>
        </div>
    </div>
         { (isAdmin() || isDoctor()) && <div className="d-flex gap-2">
            <div className="mr-2 fw-bold" >Select Investigations</div>
            <div className="patient-list col-4">
                <Select
                    options={options}
                    onChange={handleChange}
                    placeholder="Search for an investigation..."
                // isClearable
                />
            </div>
            <div>
                <span className="btn btn-lg btn-primary btn-block" onClick={addToList}>Add To List</span>
            </div>
        </div>}

    <div className="mt-5">
    <form>
        <table className="table table-hover table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Investigation</th>
                    <th>Type</th>
                    <th>Results</th>
                    <th>Upload File</th>
                    <th>File </th>
                     <th>Remove</th>    
                </tr>
            </thead>
            <tbody>
                {investigationList.length > 0 && investigationList.map((investigation, index) => (
                    
                    <tr key={investigation.id}>
                        <td>{index + 1}</td>
                        <td>{investigation.name}</td>
                        <td>{investigation.type}</td>
                        <td>
                            <textarea
                                type="text"
                                className="form-control"
                                value={investigation.pivot.results === "" ? "" : investigation.pivot.results}
                                onChange={(e) =>
                                    handleInputChange(investigation.id, e.target.value)
                                }
                            />
                        </td>
                         <td>
                            <input type="file" className="form-control"
                                onChange={(e) => handleFileChange(investigation.id, e)} />
                        </td>
                        <td>
                             {investigation.pivot.file_path ? (
                                <span onClick={()=>downLoadFile(investigation.id)} target="_blank" rel="noopener noreferrer" style={{cursor:'pointer' }}>
                                        Download File
                                    </span>
                                ) : (
                                    'No File'
                                )}
                        </td>
                        <td>
                            <span className="btn btn-danger" disabled={!!investigation.pivot.file_path} onClick={(e) => removeFromList(investigation.id)}>
                                X
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>
            
        </table>
        <div className="col-2 ms-auto">
            {/* <Button type="submit" onClick={saveInvestigationDoc} >{role == 1 ? "Send Order" : "Update Results"}</Button>  */}
            { (isAdmin() || isDoctor())  && <Button type="submit" onClick={saveInvestigationDoc} >Send Order</Button>}
             {/* <Button type="submit" onClick={saveInvestigationDoc}>Update Results</Button> */}
        </div>
    </form>
           
    
</div>


    </>
}
export default Investigations;