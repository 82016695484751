import { useParams } from "react-router-dom";
import apiClient from "../../../utils/axios";
import { useEffect, useState } from "react";
import ViewBrandComponent from "../../../components/brands/ViewBrandComponent";

const ViewBrand = () => {
    const { brandId } = useParams()

    const [brand, setBrand] = useState("")
   
    const [isLoading,setIsLoading] = useState(true)

    const fetchBrand  = async () =>{
        let url = `brands/view/${brandId}`
        try {
            const response = await apiClient.post(url)
            console.log("Brand info", response.data)
            if(response.status === 200){
                setBrand(response.data)
            }else {
                console.error('Failed to fetch Brand data');
            }

        } catch (error) {
            console.log(error)
            setIsLoading(true)
        }finally {
            setIsLoading(false); // Always set isLoading to false in finally block
        }
    }

    useEffect(() => {
        fetchBrand()
    },[brandId])

   
    return <>
           {isLoading && <p>loading ...</p>}
           {!isLoading && <ViewBrandComponent brand={brand} />}
    </>
    
    
}

export default ViewBrand;