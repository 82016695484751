import { useState } from "react";
import PersonalProfile from "../../components/settings/AccountProfile/PersonalProfile";
import UserNotification from "../../components/settings/AccountProfile/UserNotification";
import Security from "../../components/settings/AccountProfile/Security";
import ActivityLogs from "../../components/settings/AccountProfile/ActivityLogs";

const AccountProfile = () => {
    const itemName = 'tabItem1'

    const [activeTab, setActiveTab] = useState(itemName);
    
    const setActiveHandler = (itemName) => {
      setActiveTab(itemName === activeTab ? null : itemName);
  };


  let component = <h1>Welcome to the System Setup Page</h1>

  if(activeTab === 'tabItem1'){
         component = <PersonalProfile/>
  }
  if (activeTab === 'tabItem2') {
    component = <UserNotification/>
  }
  if(activeTab === 'tabItem3'){
        component = <Security/>
  }
  if(activeTab === 'tabItem4'){
        component = <ActivityLogs/>
  }

    
    return <div className="nk-content-body">
            <div className="nk-block-head">
                <div className="nk-block-head-content">
                    <h2 className="nk-block-title page-title">My Profile</h2>
                    <div className="nk-block-des">
                        <p>You have full control to manage your own account setting. <span className="text-primary"><em className="icon ni ni-info"></em></span></p>
                    </div>
                </div>
            </div>
            <div className="nk-block nk-block-lg">
                <div className="card">
                    <div className="card-content">
                        <ul className="nav nav-tabs nav-tabs-card" role="tablist">
                        <li className="nav-item" role="presentation">
                                <a className={`nav-link ${activeTab === 'manage-roles' ? 'active ' : ''}`} onClick={() => setActiveHandler('tabItem1')}   aria-selected="true" role="tab"><span>Personal</span></a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${activeTab === 'manage-roles' ? 'active ' : ''}`} onClick={() => setActiveHandler('tabItem2')}   aria-selected="false" tabindex="-1" role="tab"><span>Notification</span></a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${activeTab === 'manage-roles' ? 'active ' : ''}`} onClick={() => setActiveHandler('tabItem3')}   aria-selected="false" tabindex="-1" role="tab"><span>Security</span></a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${activeTab === 'manage-roles' ? 'active ' : ''}`} onClick={() => setActiveHandler('tabItem4')}   aria-selected="false" tabindex="-1" role="tab"><span>Activity</span></a>
                            </li>
                        </ul>
                        <div className="card-inner">
                            <div className="tab-content">
                                {component}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

export default AccountProfile;