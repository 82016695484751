import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";
import Button from "../../../reusables/Button";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';


const CreateDrug = () => {
    
    const [name, setName] = useState("");
    // const [brandId, setBrandId] = useState("");
    const [brands, setBrands] = useState([]);
    const [error, setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    
    const navigate = useNavigate()


    // const options = brands.map(brand => ({
    //     value: brand.id,
    //     label: brand.name
    // }));

    

    // const handleBrandChange = (seletedBrandOption) => {
    //     if (seletedBrandOption && seletedBrandOption.value) {
    //         const selectedBrand = brands.find(brand => brand.id === parseInt(seletedBrandOption.value));
    //         setBrandId(selectedBrand.id) 
    //     } else {
    //         setBrandId(""); // Handle empty or undefined case
    //     }
    // }

    

   
    

    const handleSubmit = async () => {

        if (name.trim() === "" ) {
            setError("Name fields are required")
            return;
        };

        let data = {
            name: name,
        }
        try {
            const response = await apiClient.post(`drugs/create`, data);
            console.log("response", response)
            if (response.status === 201) {
                setSuccessMessage("Success")
                setTimeout(() => {
                    setSuccessMessage("")
                    navigate("/inventory/drugs")
                }, 1000);
            }
        } catch (error) {
            console.log("response", error.response)
        }
    }

    // const fetchData = async () => {
    //     try {
    //         let url = `brands/list`;
    //         const response = await apiClient.post(url)
    //         if (response.status === 200) {
    //             setBrands(response.data)
    //         } else {
    //             console.log("something happend")
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // useEffect(() => {
    //     fetchData()
    // },[])

    return  <>
        <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                    <div class="nk-block-head-content">
                        <h3 class="nk-block-title page-title">Add Drug</h3>
                        <div class="nk-block-des text-soft"><p>Input new Drug information carefully.</p></div>
                    </div>
                    <div class="nk-block-des text-soft float-end"><p class="btn btn-primary">Bulk Upload</p></div>
                </div>
            </div>
            <div class="nk-block">
                <div class="card card-bordered">
                    <div class="card-inner-group">
                        <div class="card-inner">
                            <div class="nk-block-head">
                                <div class="nk-block-head-content">
                                    <h5 class="title nk-block-title">Create Drug </h5>
                                    <p>Add Drug infomation like Name etc</p>
                                </div>
                            </div>
                            <div class="nk-block">
                            
                                <div class="row gy-4">
                                
                                     {/* <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="full-name">Brand Name</label>
                                                <div class="form-control-wrap">
                                                <Select 
                                                    options={options} 
                                                    onChange={handleBrandChange} 
                                                    placeholder="Search Brand..." 
                                                    // isClearable
                                                />
                                            </div>
                                        </div>
                                    </div> */}

                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="full-name">Drug Name</label>
                                                <div class="form-control-wrap">
                                                <input type="text" className="form-control" value={name} onChange={(e)=>setName(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="card-inner">
                            <div class="nk-block">
                                <div class="row gy-4">
                                    <div class="col-12">
                                        <div class="form-group col-md-2 col-lg-2 col-sm-12 mx-auto">
                                            <Button onClick={handleSubmit}>Submit</Button>
                                            <p className="text-center text-danger fw-bold">{error && error }</p>
                                            <p className="text-center text-success fw-bold">{successMessage && successMessage}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
}
export default CreateDrug ;