import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import apiClient from "../../utils/axios";
import PatientInfo from "../../components/PatienInfo";

const ViewPatient = () => {

    
    const { id } = useParams();

    const [patient,setPatient] = useState("")
    const [isLoading,setIsLoading] = useState(true)

    
    const fetchPatientData = async () =>{
        let url = "patients/show"
        try {
            const response = await apiClient.post(url,{
                patient_id:id
            })

            if(response.status == 200){
                console.log("patients data",response.data.data)
                setPatient(response.data.data)
            }else {
                console.error('Failed to fetch patient data');
            }

        } catch (error) {
            console.log(error)
            setIsLoading(true)
        }finally {
            setIsLoading(false); // Always set isLoading to false in finally block
        }
    }

    useEffect(()=>{

        const fetchPatient = async () => {
            await fetchPatientData(); // Call fetchPatientData function
        };
        fetchPatient()
    },[id])

    const patientData =  <>
            <div class="nk-block-head">
                <div class="nk-block-head-content">
                  <NavLink to="/patients" className='btn btn-primary mb-3' >Patients</NavLink>
                    <h3 class="nk-block-title page-title">Patient Information: <strong class="text-primary small"> {patient.first_name} {patient.last_name} </strong></h3>
                </div>
            </div>
            <div class="nk-block nk-block-lg">
                <div class="card">
                    <div class="card-aside-wrap">
                        <div class="card-content">
                            <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#tabItem1" aria-selected="true" role="tab"><em class="icon ni ni-user-circle-fill"></em><span>Personal information</span></a>
                                </li>
                              
                            </ul>
                            <div class="card-inner">
                                <div class="tab-content">
                                    <div class="tab-pane active" id="tabItem1" role="tabpanel">
                                        <PatientInfo person={patient} id={id}/>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
    </>;
    return <>
           {isLoading && <p>loading ...</p>}
           {!isLoading && patientData}
        </>

}

export default ViewPatient;