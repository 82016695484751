import { useEffect, useState } from "react";
import { PERMISSIONS } from "../../constants/rolesPermissions";
import apiClient from "../../utils/axios";
import { useAuth } from "../../store/AuthContext";

const RolePermissions = ({ roleId }) => {
  
  // console.log("role id",roleId)
  const {fetchRolesAndPermissions} = useAuth()

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [message,setMessage] = useState("")

    const permissions = Object.entries(PERMISSIONS)
     


  const fetchRoleSelectedPermissions = async () => {
      try {
        let url = `roles-permissions/get-role-permissions/${roleId}`;
        const response = await apiClient.get(url)
        if (response.status === 200) {
          setSelectedPermissions(response.data.permissions);
        } else {
          console.log("something happened")
        }
      } catch (error) {
        console.log("something happened",error)
      }
      
      
      
    };

  const handleCheckboxChange = (permission) => {
    // Toggle selected permissions in local state
    const isSelected = selectedPermissions.includes(permission);
        if (isSelected) {
         setSelectedPermissions(selectedPermissions.filter(p => p !== permission));
        } else {
         setSelectedPermissions([...selectedPermissions, permission]);
        }
    };

    const updatePermissions = async () => {
      // Example: Update selected permissions for the specific role in database
      let url = `roles-permissions/update-role-permissions`;
      let data = {
        roleId:roleId,
        selectedPermissions:selectedPermissions
      }
      try {
        const response = await apiClient.post(url, data)
        if (response.data.status) {
          setMessage(response.data.message)
          fetchRolesAndPermissions()
          setTimeout(() => {
            setMessage("")
          },3000)
        }
      } catch (error) {
        console.log("something happened",error)
      }
      
     
  };
    


     useEffect(() => {
        fetchRoleSelectedPermissions(); // Fetch initial selected permissions on component mount
    }, [roleId]);


    
    return <>
            <div className="row">
            {permissions.map(([key, permission]) => (
                // console.log("hskdjhs",permission)
             <div key={key} className="col-md-12">
                    <ul>
                        <li>
                            <input
                                type="checkbox"
                                id={`checkbox-${permission}`}
                                checked={selectedPermissions.includes(permission)}
                                onChange={() => handleCheckboxChange(permission)}
                                />
                            <label htmlFor={`checkbox-${permission}`}>{permission}</label>
                    </li>
                </ul>
            </div>
            ))}
        </div>

      <button className="btn btn-primary mt-2" onClick={updatePermissions}>Update Permissions</button>
      <span className="text-success m-2">{ message && message}</span>
        
       
    </>
}
export default RolePermissions;