import { useParams } from "react-router-dom";
import apiClient from "../../../utils/axios";
import { useEffect, useState } from "react";
import ViewDrugComponent from "../../../components/drugs/ViewDrugComponent";

const ViewDrug = () => {
    const { drugId } = useParams()

    console.log("drug", drugId)
    const [drug, setDrug] = useState("")
    const [brands,setBrands] = useState([])
   
    const [isLoading,setIsLoading] = useState(true)

    const fetchDrug  = async () =>{
        let url = `drugs/view/${drugId}`
        try {
            const response = await apiClient.post(url)
            console.log("Drug info", )
            if(response.status === 200){
                setDrug(response.data.drug)
                setBrands(response.data.brands)
            }else {
                console.error('Failed to fetch drug data');
            }

        } catch (error) {
            console.log(error)
            setIsLoading(true)
        }finally {
            setIsLoading(false); // Always set isLoading to false in finally block
        }
    }

    useEffect(() => {
        fetchDrug()
    },[drugId])

   
    return <>
           {isLoading && <p>loading ...</p>}
           {!isLoading && <ViewDrugComponent drug={drug} brands={brands}/>}
    </>
    
    
}

export default ViewDrug;