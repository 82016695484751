import { useState } from "react";
import Button from "../../../reusables/Button";
import apiClient from "../../../utils/axios";
import { useNavigate } from "react-router-dom";

const CreateSupplier = ({isEdit,data}) =>{

  
    const [name,setName] = useState(isEdit ?  data.name : "")
    const [email,setEmail] = useState(isEdit ?  data.email : "")
    const [phone,setPhone] = useState(isEdit ?  data.phone : "")
    

    const [emailError,setEmailError] = useState("")
    const [error,setError] = useState("")
    const [successMessage,setSuccessMessage] = useState("")

    const navigate = useNavigate();

    const validateEmail = (email) => {
        // Basic email regex pattern
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };


     

    const handleSupplier = async (e) =>{
        e.preventDefault()
        if(email.trim() !== "" || name.trim() !== "" || phone.trim() !== ""){

            if (!validateEmail(email)) {
                setEmailError('Please enter a valid email');
                return ;
              }


            let url = "suppliers/create"
            let data = {
                name:name,
                email:email,
                phone:phone
            }
            try {
                const response = await apiClient.post(url,data)
                if(response.status == 200 && response.data.status){
                    setSuccessMessage(response.data.message)
                    setError("")
                    setTimeout(() => {
                        setSuccessMessage("")
                        navigate("/inventory/suppliers")
                    }, 1000);

                }else{
                    setSuccessMessage("")
                    setError(response.data.message)
                }
            } catch (error) {
                setError("")
                setSuccessMessage("")
                setEmailError(error.response.error)
            }
        }
    }

return <div class="nk-content-body">
<div class="nk-block-head nk-block-head-sm">
    <div class="nk-block-between">
        <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">Add Supplier</h3>
            <div class="nk-block-des text-soft"><p>Input new Supplier information carefully.</p></div>
        </div>
        <div class="nk-block-des text-soft float-end"><p class="btn btn-primary">Bulk Upload</p></div>
    </div>
</div>
<div class="nk-block">
    <div class="card card-bordered">
        <div class="card-inner-group">
            <div class="card-inner">
                <div class="nk-block-head">
                    <div class="nk-block-head-content">
                        <h5 class="title nk-block-title">Supplier Info</h5>
                        <p>Add Supplier infomation like Name, Email etc</p>
                    </div>
                </div>
                <div class="nk-block">
                   
                    <div class="row gy-4">
                      
                        <div class="col-xxl-3 col-md-4">
                            <div class="form-group">
                                <label class="form-label" for="full-name"> Name</label>
                                <div class="form-control-wrap"><input type="text" class="form-control" id="full-name"
                                value={name} 
                                onChange={(e)=>setName(e.target.value)}
                                placeholder="Supplier Name" /></div>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-md-4">
                            <div class="form-group">
                                <label class="form-label" for="email">Email</label>
                                <div class="form-control-wrap"><input type="email" class="form-control" id="email" 
                                value={email} 
                                onChange={(e)=>setEmail(e.target.value)}
                                placeholder="Email"  /></div>
                                 <p className="text-danger">{emailError && emailError}</p>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-md-4">
                            <div class="form-group">
                                <label class="form-label" for="email">Phone</label>
                                <div class="form-control-wrap">
                                    <input type="number" class="form-control" id="email" placeholder="Phone"
                                     value={phone}
                                     onChange={(e)=>setPhone(e.target.value)}
                                      />
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-xxl-3 col-md-4">
                            <div class="form-group">
                                <label class="form-label" for="email">Location</label>
                                <div class="form-control-wrap"><input type="email" class="form-control" id="email" placeholder="Email" value="" /></div>
                            </div>
                        </div> */}
                        
                       
                       
                    </div>
                </div>
            </div>
            <div class="card-inner">
                <div class="nk-block">
                    <div class="row gy-4">
                        <div class="col-12">
                            <div class="form-group col-md-2 col-lg-2 col-sm-12 mx-auto">
                                <Button onClick={handleSupplier}>Add Supplier</Button>
                                <p className="text-center text-danger fw-bold">{error && error }</p>
                                <p className="text-center text-success fw-bold">{successMessage && successMessage}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

}


export default CreateSupplier;