import { useEffect, useState } from 'react';
import Label from '../../reusables/Label';
import { useAuth } from '../../store/AuthContext';
import './Sickleave.css'
import apiClient from '../../utils/axios';

const MedicalEvacuationTemp = ({patient}) =>{

  const [isLoading,setIsLoading] = useState(false)

  const [formData, setFormData] = useState({
    workingDays: '',
    startDate: '',
    endDate: '',
    reason: '',
  });

  const handleChange = (e) =>{
    const {name,value} = e.target

    // console.log("name to change",name)

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,  // Make sure this matches the input's `name` attribute
    }));
    
  }


  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setIsLoading(true)
    console.log(formData); // For demonstration purposes
    let data = {
      ...formData,
      patientId: patient.id,
      leaveTypeId:3
    }
    console.log("formdata",data)
    try {
      const response = await apiClient.post(`leave/apply-leave`,data,{responseType: 'blob'})
      if(response.status === 200){
        setIsLoading(false)
        console.log("response",response)
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${patient.name}-medical_evacuation_leave.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log("error",error)
    }
    

  };

  const { authUser } = useAuth()

  useEffect(() => {
    if (formData.startDate && formData.workingDays) {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + parseInt(formData.workingDays));
      setFormData((prevData) => ({
        ...prevData,
        endDate: endDate.toISOString().split('T')[0],
      }));
    }
  }, [ formData.workingDays]);

  useEffect(() => {
    if (formData.startDate && formData.endDate) {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(formData.endDate);
      const differenceInTime = endDate - startDate;
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      setFormData((prevData) => ({
        ...prevData,
        workingDays: differenceInDays.toString(),
      }));
    }
  }, [formData.endDate]);


    return <>

    <form className="" method="post" name="" id="">
      
      <div role="main" className="form-all">
        <ul className="form-section page-section">
          <li id="cid_1" className="form-input-wide" data-type="control_head" data-css-selector="id_1">
            <div className="form-header-group header-large">
              <div className="header-text httac htvam">
              <div className=''>
                {/* <div className='img-logo'>
                  <img class="round" src={`${process.env.PUBLIC_URL}/assets/images/kenyattalogo.jpg`} style={{width:'100%',height:'100%'}}/>
                </div> */}
              </div>
              <h4 id="header_1" className="form-header" style={{ textAlign: "center", color: "#ffffff" }}>
                  {authUser.hospital.hospital_name}
                </h4>
                <h4 id="header_1" className="form-header" style={{ textAlign: "center", color: "#ffffff" }}>
                  Medical Evacuation Leave Form
                </h4>
              </div>
            </div>
          </li>
        </ul>

        <div>
          <Label>Patient Name: {patient.name}</Label>
        <br/>
        <label className="form-label form-label-top" htmlFor="input_19">
             
        </label>
        <Label> Patient ID: {patient.national_id}</Label>
        
        </div>
          
          <div id="cid_19" className="form-input-wide" data-layout="half">
            <div className='row'>
              <div className='col-4'>

                <Label>Start Date</Label>
                <input
                type="date"
                    id="input_19"
                    className="form-control"
                    style={{ width: "310px" }}
                    placeholder="Start date"
                    aria-labelledby="label_19"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                />


              </div>
            </div>
             
            </div>

            <div className='mt-3'>
               
                <div className='row'>
                  <div className='col-4'>
                    {/* working days goes here */}
                    <Label>How many working days are you applying for sickness leave?</Label>
                      <input
                        type="number"
                        id="input_19"
                        className="form-control"
                        style={{ width: "310px" }}
                        placeholder="e.g., 23"
                        aria-labelledby="label_19"
                        name="workingDays"
                        value={formData.workingDays}
                        onChange={handleChange}
                      />
                  </div>
                  <div className='col-6'>
                      <Label>End Date</Label>
                          <input
                          type="date"
                          className="form-control"
                          style={{ width: "310px" }}
                          placeholder="End date"
                          name="endDate"
                          aria-labelledby="label_19"
                          value={formData.endDate}
                          onChange={handleChange}
                      />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-3 mt-3 mb-3'>
                    <Label>Reason for Sickness Leave</Label>
                  
                  </div>

                  <div className='col-6 mt-3 mb-3'>
                    <textarea className='form-control'
                      value={formData.reason}
                      name="reason"
                      onChange={handleChange}
                     ></textarea>
                  </div>
                </div>
              
                <div className='row'>
                  <div className='col-12 mt-3 mb-3'>
                    <Label> Doctor Name : {authUser.name}</Label>
                  </div>
                </div>


              <div className="d-flex gap-2">
                {/* <button className="btn btn-outline-primary p-3" >Print Form</button> */}
                {!isLoading  && <button className="btn btn-primary p-3" onClick={handleSubmit}>Submit</button>}
                {isLoading  && <button className="btn btn-primary p-3">Submit ...</button>}
            </div>

                 

            </div>

        
      </div>

      
    </form>
    </>
}
export default MedicalEvacuationTemp;