import { useEffect, useState } from 'react';
import Button from '../../reusables/Button';
import apiClient from '../../utils/axios';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { bloodgroups } from '../../constants/bloodgroups';
import { genders } from '../../constants/genders';
// import { allCities } from '../../constants/cities';
import Select from 'react-select';


const EditPatient = () => {

    const { id } = useParams();

	const [firstName,setFirstName] = useState("")
	const [lastName,setLastName] = useState("")
	const [dob,setDob] = useState("")
	const [gender,setGender] = useState("1") //DEFAULT AS MALE
	const [cities,setCities] = useState([])
	const [cityId,setCityId] = useState("")
	const [email,setEmail] = useState("")
	const [bloodGroup, setBloodGroup] = useState("") //DEFAULT AS A DEFAULT BLOOD GROUP
	const [nationalId, setNationalId] = useState("") 
	const [phoneNumbers, setPhoneNumbers] = useState(['']);
	const [isLoading, setIsLoading] = useState(false)
	const [isSuccess,setIsSuccess] = useState(false)
	const [message,setMessage] = useState(false)
	const [error, setError] = useState("")
	const [phoneError, setPhoneError] = useState("")
	const [workPlaces, setWorkPlaces] = useState([])
	const [workPlaceId,setWorkPlaceId] = useState("")
	const [insuranceCardNumber,setInsuranceCardNumber] = useState("")
	const [medInsuranceCardNumber,setMedInsuranceCardNumber] = useState("")


	

	const navigate = useNavigate()

	const goBack = () => {
        navigate.goBack();
    };

	const options = workPlaces && workPlaces.map(workPlace => ({
        value: workPlace.id,
        label: workPlace.name
    }));

	const handleWorkPlaceChange = (seletedWorkOption) => {
        if (seletedWorkOption && seletedWorkOption.value) {
            const selectedWorkPlace = workPlaces.find(workPlace => workPlace.id === parseInt(seletedWorkOption.value));
            setWorkPlaceId(selectedWorkPlace.id) 
        } else {
            setWorkPlaceId(""); // Handle empty or undefined case
        }
	}



	const submitHandler = async () => {
		//check that city has value
		
		let gId = genders.find(g => g.id === parseInt(gender)).gender
		
		setIsLoading(true);

		let url = `patients/update`

		let data = {
            id:id,
			date_of_birth:dob,
			gender:gId,
			phones:phoneNumbers,
			last_name:lastName,
			first_name:firstName,
			email:email,
			city_id:cityId,
			blood_group: bloodGroup,
			identifier_number:nationalId,
			// place_of_work:workPlace,
			place_of_work_id:workPlaceId,
			insurance_card_number:insuranceCardNumber,
			med_Insurance_card_number:medInsuranceCardNumber
		}
		console.log("data to update",data)
		
		try {
			const response = await apiClient.post(url, data);

			if(response.data.status){

				setIsSuccess(true)
				setIsLoading(false)
				setError("")
				setMessage("Success")
				setTimeout(() => {
					setMessage("")
					navigate("/patients")
				}, 2000);
				//set form values to empty again
			} else {
                setIsLoading(false)
				setIsSuccess(false)
                setError(response.data.message)
            }
		

		} catch (error) {
			setIsLoading(false)
			setIsSuccess(false)
			console.log("Unable to Update",error.response.data.errors)
            setError("Unable to Update",error.response)
		} finally{
            setIsLoading(false)
        }
	}

	const fetchCitiesData = async  () =>{
		const response = await apiClient.post("cities");
		if(response.status === 200 || response.status === 201){
			setCities(response.data.cities);
		}
	}

	
	

    

    const fetchPatientData = async () =>{
        let url = "patients/show"
        try {
            const response = await apiClient.post(url,{
                patient_id:id
			})

            if(response.status === 200){
                //update the state with the users information
				let patient = response.data.data
				let gId = genders.find(g => g.gender === patient.gender).id
				setInsuranceCardNumber(patient.insurance_card_number)
				setMedInsuranceCardNumber(patient.med_insurance_card_number)
                setFirstName(patient.first_name)
                setLastName(patient.last_name)
                setDob(patient.dob)
                setGender(gId)
				setPhoneNumbers(patient.phones)
                setCityId(patient.city_id)
                setEmail(patient.email)
                setBloodGroup(patient.blood_group)
				setNationalId(patient.national_id)
				setWorkPlaceId(patient.work_place_id)

            }else {
                console.error('Failed to get patient data');
            }

        } catch (error) {
            console.log(error)
            setIsLoading(true)
        }finally {
            setIsLoading(false); // Always set isLoading to false in finally block
        }
	}
	
	const handleAddPhoneNumber = () => {
		//patient should not have more that three numbers
		if (phoneNumbers.length < 3) {
			setPhoneNumbers([...phoneNumbers, '']);
		} else {
			setPhoneError("Only 3 phone numbers allowed")
			setTimeout(() => {
				setPhoneError("")
			}, 3000);
			return;
		}
		
		
	  };
	
	const handlePhoneNumberChange = (index, event) => {
		const newPhoneNumbers = phoneNumbers.slice();
		newPhoneNumbers[index] = event.target.value;
		setPhoneNumbers(newPhoneNumbers);
	};


	const getWorkPlaces = async () => {

        const response = await apiClient.get(`work-places`)
        if (response.status === 200 && response.data.data.status) {
            console.log("response",)
            setWorkPlaces(response.data.data.data)
		} else {
			console.log("somthing happened")
		}
        
    }



    useEffect(()=>{
		const fetchWorkPlaces = async () => {
            await getWorkPlaces(); // Call fetchPatientData function
        };


        const fetchCities = async () =>{
			await fetchCitiesData()
		}
        const fetchPatient = async () => {
            await fetchPatientData(); // Call fetchPatientData function
        };
		fetchWorkPlaces()
        fetchPatient()
        fetchCities()
		
        
    },[id])


return <>
    <div className="nk-block-head nk-block-head-sm">
	<div className="nk-block-between">
		<div className="nk-block-head-content">
			<NavLink to="/patients" className='btn btn-primary mb-3' >Patients</NavLink>
			<h3 className="nk-block-title page-title">Update Patient : {firstName} {lastName}</h3>
			<div className="nk-block-des text-soft"><p>Update new Patient information carefully.</p></div>
		</div>
	</div>
</div>
<div className="nk-block">
	<div className="card card-bordered">
		<div className="card-inner-group">
			<div className="card-inner">
				<div className="nk-block-head">
					<div className="nk-block-head-content">
						<h5 className="title nk-block-title">Personal Info</h5>
						<p>Update common infomation like Name, Email etc</p>
					</div>
				</div>
				<div className="nk-block">
					<div className="row gy-4">
						<div className="col-xxl-3 col-md-4">
								<div className="form-group">
									<label className="form-label" for="full-name">First Name</label>
										<div className="form-control-wrap">
											<input type="text" className="form-control" id="full-name" placeholder="First Name"
											value={firstName}
											 onChange={(e)=>setFirstName(e.target.value)}/>
										</div>
								</div>
								</div>
								
								<div className="col-xxl-3 col-md-4">
									<div className="form-group">
										<label className="form-label" for="full-name">Last Name</label>
											<div className="form-control-wrap">
												<input type="text" className="form-control" id="full-name" placeholder="Second Name"
												value={lastName}
												 onChange={(e)=>setLastName(e.target.value)}/>
											</div>
									</div>
								</div>
								
						<div className="col-xxl-3 col-md-4">
							<div className="form-group">
								<label className="form-label">Date of Birth</label> - <span>({dob})</span>
								<div className="form-control-wrap">
									<div className="form-icon form-icon-right"></div>
										<input type="date" className="form-control " data-date-format="dd-mm-yyyy" placeholder="dd-mm-yyyy"
										 value={dob} onChange={(e)=>setDob(e.target.value)} />
									</div>
							</div>
						</div>
						<div className="col-xxl-3 col-md-4">
							<div className="form-group">
								<label className="form-label">Gender</label>
								<div className="form-control-wrap">
									<select
												className="form-select "
												value={gender}
												onChange={(e) => setGender(e.target.value)}
												aria-hidden="true"
											>
										<option value="" disabled>Select Gender</option>
										{genders.map((gender)=>(
											<option value={gender.id} >{gender.gender}</option>
										))}
									</select>
								</div>
							</div>
						</div>
                        <div className="col-xxl-3 col-md-4">
							<div className="form-group">
									<label className="form-label" for="email">National ID/Passport</label> 
								<div className="form-control-wrap"><input type="email" className="form-control" id="email" placeholder="National ID/ Passport"
								value={nationalId}
								 onChange={(e)=>setNationalId(e.target.value)}/></div>
							</div>
						</div>
						<div className="col-xxl-3 col-md-4">
							<div className="form-group">
								<label className="form-label" for="email">Email</label>
								<div className="form-control-wrap"><input type="email" className="form-control" id="email" placeholder="Email"
								value={email}
								 onChange={(e)=>setEmail(e.target.value)}/></div>
							</div>
						</div>

						<div className="col-xxl-3 col-md-4">
							<div className="form-group">
								<label className="form-label">Blood Group</label>
								<div className="form-control-wrap">
									
										<select className="form-select"  onChange={(e) => setBloodGroup(e.target.value)} aria-hidden="true">
											<option value="" selected>{ bloodGroup}</option>
											{bloodgroups.map((bgroup) => (<>
												<option value={bgroup.value}>{bgroup.group}</option>
											</>
												
											))}
										</select>
									
								</div>
							</div>
						</div>
						<div className="col-xxl-3 col-md-4">
								<div className="form-group">
									<label className="form-label" for="email">Company</label>
									<div className="form-control-wrap">
										{/* <input type="email" className="form-control" id="email" placeholder="Email"
											value={workPlace}
											onChange={(e)=>setWorkPlace(e.target.value)}
										/> */}

									<Select 
											options={options} 
											onChange={handleWorkPlaceChange} 
											placeholder="Search Company ..." 
										/>

								</div>
							</div>
						</div>

						<div className="col-xxl-3 col-md-4">
							<div className="form-group">
								<label className="form-label">City</label>
								<div className="form-control-wrap">
									<select className="form-select" value={cityId}  onChange={(e)=>setCityId(e.target.value)} aria-hidden="true">
										<option value="" disabled>Select City</option>
											{cities.map((city) => (
											<option key={city.id} value={city.id}>
												{city.name}
											</option>
										))}
										
									</select>
								</div>
							</div>
						</div>

						<div className="col-xxl-3 col-md-4">
						<div className="form-group">
							<label className="form-label">Insurance card number</label>
							<div className="form-control-wrap">
								<input type='text' className='form-control'
								 placeholder='Enter Insurance Card Number'
								 value={insuranceCardNumber}
								 onChange={(e)=> setInsuranceCardNumber(e.target.value)}
								/>
							</div>
						</div>
					</div>
					
					<div className="col-xxl-3 col-md-4">
						<div className="form-group">
							<label className="form-label">Medevac Insurance Card Number</label>
							<div className="form-control-wrap">
								<input type='text' className='form-control' 
									placeholder='Medevac Insurance Card Number'
									value={medInsuranceCardNumber}
									onChange={(e)=> setMedInsuranceCardNumber(e.target.value)}
								/>
							</div>
						</div>
					</div>


				


							<div className='row'>
								{phoneNumbers.length > 0 &&  phoneNumbers.map((phoneNumber, index) => (
									<div className="col-xxl-3 col-md-4" key={index+1}>
											<div className="form-group" >
											
													<label className="form-label" htmlFor={`phone-${index}`}>Phone {index + 1}</label>
													<div className="form-control-wrap">
													<input
														type="number"
														className="form-control"
														id={`phone-${index}`}
														placeholder="Phone Number"
														value={phoneNumber}
														onChange={(event) => handlePhoneNumberChange(index, event)}
														/>
													</div>
											</div>
										</div>
								))}
								
								{phoneNumbers.length === 0 && <>
									{phoneNumbers.map((phoneNumber, index) => (
										<div className="col-xxl-3 col-md-4" key={index+1}>
												<div className="form-group" >
												
														<label className="form-label" htmlFor={`phone-${index}`}>Phone {index + 1}</label>
														<div className="form-control-wrap">
														<input
															type="number"
															className="form-control"
															id={`phone-${index}`}
															placeholder="Phone Number"
															value={phoneNumber}
															onChange={(event) => handlePhoneNumberChange(index, event)}
															/>
														</div>
												</div>
											</div>
									))}
									
								</>}
								<span type="button" onClick={handleAddPhoneNumber} style={{ color: 'blue', textDecoration: 'underline' }}>Add Phone Number </span>
								{phoneError && <span className='text-danger fw-bold'> {phoneError}</span> }
								
								

							</div>
							

						
					</div>
				</div>
			</div>
			
			<div className="card-inner">
			
					
				<div className="nk-block">
					<div className="row gy-4">

						<div className="col-12">
							<div className="form-group col-md-2 col-lg-2 col-sm-12 mx-auto">
									<Button onClick={submitHandler} >Update Patient</Button>
									{isLoading && <p className='mb-3 text-center'>Submitting ...</p>}
									{!isLoading && isSuccess && <p className='mb-3 mt-3 text-center text-success'><b>{message}</b></p>}
									{error && !isLoading && <p className='mb-3 text-center text-danger'>{error}</p>}
									 
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

    </>
}

export default EditPatient;