import { useEffect, useState } from "react";
import Button from "../reusables/Button";
import apiClient from "../utils/axios";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import MyCustomUploadAdapterPlugin from "./MyCustomUploadAdapterPlugin ";
import { reasonsForVisit } from "../constants/visitReasons";

const TriageForm = ({ triage }) => {
    
    const [id,setId] = useState(0);
    const [height, setHeight] = useState(0 )
    const [weight, setWeight] = useState(0)
    const [currentMed, setCurrentMed] =  useState("")
    const [visitReason, setVisitReason] = useState("")
    const [bloodPressure, setbloodPressure] =  useState("")
    const [temperature, setTemperature] = useState("")
    const [respiratoryRate, setRespiratoryRate] = useState("")
    const [oxygenSaturation, setOxygenSaturation] = useState("")
    const [heartRate, setHeartRate] = useState("")
    const [allergies, setAllergies] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [errors, setErrors] = useState({})
    const [reasons, setReasons] = useState([])
    const [selectedReason, setSelectedReason] = useState('');
    
    const allErrors = Object.keys(errors).map((field, index) => (
        <div key={index} className="error-message">
          {errors[field].map((error, idx) => (
            <p key={idx}>{error}</p>
          ))}
        </div>
      ))

    const submitTriageFormHandler = async (e) =>{
        e.preventDefault()
        let url = "consultation/update"
        //check if form fields are empty

        let data = {
            'id':id,
            'height_cm':height,
            'weight_kg' : weight,
            'allergies' : allergies,
            'current_medications' :currentMed,
            'reason_for_visit': visitReason,
            'blood_pressure' :bloodPressure,
            'heart_rate': heartRate,
            'temperature' : temperature,
            'respiratory_rate' :respiratoryRate,
            'oxygen_saturation': oxygenSaturation,
        };

        try {
            const response = await apiClient.post(url,data)
            if(response.status === 200 ){
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage("") 
                }, 3000); 
            }else{
                console.log("response failed message",)
            }
        } catch (error) {
            let errors = error.response.data.errors;
            setErrors(errors);
            console.log("error" , errors)
        }

    }


    const findReasonById = (id) => {
        for (let reason of reasonsForVisit) {
            if (reason.subcategories) {
                for (let sub of reason.subcategories) {
                    if (sub.id === parseFloat(id)) {
                        return sub;
                    }
                }
            }
            if (reason.id === parseFloat(id)) {
                return reason;
            }
        }
        return null;
    };

    const findReasonByName = (name) => {
        for (let reason of reasonsForVisit) {
            if (reason.subcategories) {
                for (let sub of reason.subcategories) {
                    if (sub.name === name) {
                        return sub.id;
                    }
                }
            }
            if (reason.name === name) {
                return reason.id;
            }
        }
        return null;
    };
    

    const handleChange = (event) => {
        const selectedId = event.target.value;
        const selectedReasonData = findReasonById(selectedId);

        console.log("test",selectedReasonData)
        if (selectedReasonData) {
            setSelectedReason(selectedReasonData.id)
            setVisitReason(selectedReasonData.name)
        }
    };

    useEffect(() => {
        if (triage && triage.reason_for_visit !== "") {
            const selectedId = findReasonByName(triage.reason_for_visit);

            if (selectedId !== null) {
                setSelectedReason(selectedId);
            }
        }

        setId(triage.id ?  triage.id : null )
        setHeight(triage.height_cm ? triage.height_cm : 0)
        setWeight(triage.weight_kg ? triage.weight_kg : 0)
        setVisitReason(triage.reason_for_visit ? triage.reason_for_visit : "")
        setbloodPressure(triage.blood_pressure ?  triage.blood_pressure : "")
        setTemperature(triage.temperature ? triage.temperature : "")
        setRespiratoryRate(triage.respiratory_rate ? triage.respiratory_rate : "")
        setOxygenSaturation(triage.oxygen_saturation ? triage.oxygen_saturation : "")
        setHeartRate(triage.heart_rate ? triage.heart_rate : "")
        setAllergies(triage.allergies ? triage.allergies : "")
        setCurrentMed(triage.current_medications ? triage.current_medications : "")
        

    }, [triage])
    
    useEffect(() => {
        setReasons(reasonsForVisit);
    },[])
    return (
        <div className="nk-blocks">
            {allErrors}

            <div className="nk-block nk-block-between">
                <div className="nk-block-head">
                    <h6 className="title">Triage </h6>
                </div>
            </div>
            
            <div className="row gy-4 mt-1 card-inner">
                <div className="col-xxl-3 col-md-4">
                    <div className="form-group">
                        <label className="form-label" for="height">Height</label> 
                        <div className="form-control-wrap">
                            <input type="number" className="form-control" placeholder="Height" value={height} onChange={(e)=>setHeight(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-3 col-md-4">
                    <div className="form-group">
                        <label className="form-label">Weight</label>
                        <div className="form-control-wrap">
                            <input type="number" className="form-control date-picker"  
                            value={weight}
                            onChange={(e)=> setWeight(e.target.value)}
                            placeholder="Weight" />
                        </div>
                    </div>
                </div>
               
                
                <div className="col-xxl-3 col-md-4">
                    <div className="form-group">
                        <label className="form-label">Blood Pressure</label>
                        <div className="form-control-wrap">
                            <input type="number" className="form-control" id="email"
                            value={bloodPressure}
                            onChange={(e)=> setbloodPressure(e.target.value)}
                             placeholder="blood pressure" />
                        </div>
                    </div>
                </div>
                <div className="col-xxl-3 col-md-4">
                    <div className="form-group">
                        <label className="form-label">Temperature</label>
                        <div className="form-control-wrap">
                            <input type="number" className="form-control" id="email"
                            value={temperature}
                            onChange={(e)=> setTemperature(e.target.value)}
                             placeholder="Temperature" />
                        </div>
                    </div>
                </div>
                 <div className="col-xxl-3 col-md-4">
                    <div className="form-group">
                        <label className="form-label">Respiratory Rate</label>
                        <div className="form-control-wrap">
                            <input type="number" className="form-control" id="email"
                            value={respiratoryRate}
                            onChange={(e)=> setRespiratoryRate(e.target.value)}
                             placeholder="Respiratory Rate" />
                        </div>
                    </div>
                </div>
                <div className="col-xxl-3 col-md-4">
                    <div className="form-group">
                        <label className="form-label">Oxygen Saturation</label>
                        <div className="form-control-wrap">
                            <input type="number" className="form-control" id="email" 
                            value={oxygenSaturation}
                            onChange={(e)=> setOxygenSaturation(e.target.value)}
                            placeholder="Oxygen Saturation" />
                        </div>
                    </div>
                </div>
                <div className="col-xxl-3 col-md-4">
                    <div className="form-group">
                        <label className="form-label">Heart Rate</label>
                        <div className="form-control-wrap">
                            <input type="text" className="form-control" id="address"
                            value={heartRate}
                            onChange={(e)=> setHeartRate(e.target.value)} 
                            
                            placeholder="Heart Rate" />
                        </div>
                    </div>
                </div>
             
                         
            </div>

          
            <div className="#dynamic-content card-inner">
              

            <hr className="" style={{border: '1px solid black'}}/>
                 <div className="reason-section">
                  
                    <div className="col-xxl-3 col-md-4">
                        <div className="form-group">
                            <label htmlFor="reasons" className="form-label">Reasons for Visit</label> 
                            
                            <select id="reasons" name="reasons" className="form-select" onChange={handleChange} value={selectedReason} >
                                <option value="" disabled>Select a reason</option>
                                {reasons.map(reason => (
                                    reason.subcategories ? (
                                        <optgroup key={reason.id} label={reason.name}>
                                            {reason.subcategories.map(sub => (
                                                <option key={sub.id} value={sub.id}>
                                                    {sub.name}
                                                </option>
                                            ))}
                                        </optgroup>
                                    ) : (
                                        <option key={reason.id} value={reason.id}>
                                            {reason.name}
                                        </option>
                                    )
                                ))}
                            </select>
                        </div>
                    </div>



                    
                </div>


                
                

                <div className="allergies-section">
                   
                    <div className="col-xxl-3 col-md-4">
                        <div className="form-group">
                            <label className="form-label">Allergies</label>
                            <div className="form-control-wrap">
                                <textarea type="text" className="form-control" id="address" 
                                value={allergies}
                                onChange={(e)=> setAllergies(e.target.value)} 
                                placeholder="Allergies" />
                            </div>
                        </div>
                    </div>


                    
                </div>
                <div className="allergies-section">
                    <div className="col-xxl-3 col-md-4">
                        <div className="form-group">
                            <label className="form-label">Current Medications</label>
                            <div className="form-control-wrap">
                                <textarea type="text" className="form-control" id="address"
                                value={currentMed}
                                onChange={(e)=> setCurrentMed(e.target.value)} 
                                
                                placeholder="Current Medications" />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

           
           <div className="col-3 mx-auto">
            <Button onClick={submitTriageFormHandler}>Submit</Button>  
                    <p className="text-success fw-bold mt-3 text-center">{successMessage &&  successMessage }</p>
            </div>

            
        

        </div>

    );
}

export default TriageForm;