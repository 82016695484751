import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";

const ListBrandsComponent = ({brands}) => {

    const [openModal, setOpenModal] = useState(false);
    const[brand,setBrand] =useState({})
    const modalRef = useRef (null);

    const deleteModaHandler = () => {

    }

    const handlePageClick = (data) =>{
        let dataSelected = data.selected;
    }

    const closeModal = () =>{

    }

    const removeBrandHandler = () =>{

    }
     // this is used to open the delete modal for confirmation
	useEffect(() => {
		if (openModal && modalRef.current) {
			const modal = new window.bootstrap.Modal(modalRef.current);
			modal.show();
			return () => modal.hide();
		}
		
    }, [openModal,brand]);
    

    return <>
        <div class="card-inner p-0 table-responsive">
            <div class="text-center">
                <div class="mb-3"></div>
                <div class="mb-3"></div>
                <div></div>
            </div>
            <div class="nk-tb-list nk-tb-ulist">
                <div class="nk-tb-item nk-tb-head">
                
                    <div class="nk-tb-col tb-col-sm"><span class="sub-text fw-bold">Brand Name</span></div>
                    <div class="nk-tb-col tb-col-sm"><span class="sub-text fw-bold">Drug Name</span></div>
                
                    
                    <div class="nk-tb-col nk-tb-col-tools">
                        <ul class="nk-tb-actions gx-1 my-n1">
                            <div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Actions</span></div>
                    
                        </ul>
                    </div>
                </div>
        
            {brands.length > 0 && brands.map((brand) => {
                return <div class="nk-tb-item" key={brand.id}>
                     <div class="nk-tb-col tb-col-sm">
                        <span class="fw-medium">{brand.name}</span>
                    </div>
            
                   
                     <div class="nk-tb-col tb-col-sm">
                        <span class="fw-medium">{brand.drug.name} </span>
                    </div>
                   
                   
                  
                    <div class="nk-tb-col nk-tb-col-tools">
                        <ul class="nk-tb-actions gx-1">
                        
                            <li>
                                <div class="drodown">
                                    <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                    <div class="dropdown-menu dropdown-menu-end">
                                        <ul class="link-list-opt no-bdr" style={{ cursor: 'pointer' }}>
                                            <li>
                                                <NavLink to={`/inventory/brands/${brand.id}`} >
                                                    <em class="icon ni ni-eye"></em><span>View Detailss</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/inventory/brands/Edit/${brand.id}`}><em class="icon ni ni-edit"></em><span>Edit</span></NavLink>
                                            </li>
                                            <li>
                                                <a onClick={() => deleteModaHandler(brand)}><em class="icon ni ni-trash" ></em><span>Delete </span>
                                                </a>
                                            </li>
                                        
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            })}
            </div>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modalRef}>
				<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
					<h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
					<button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
					</div>
					<div className="modal-body">
					<p><strong>Are you sure You want to delete this batch</strong></p>
					</div>
					<div className="modal-footer">
					<button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
					<button type="button" className="btn btn-danger"  onClick={()=>removeBrandHandler(brand.id)} >Confirm Delete</button>
					</div>
				</div>
				</div>
        </div>
        
    </>

}
export default ListBrandsComponent;