const ViewBrandComponent = ({brand}) => {
    return <>
        <div class="nk-block-head">
                <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">Brand Information: <strong class="text-primary small"> {brand.name}  </strong></h3>
                </div>
            </div>
            <div class="nk-block nk-block-lg">
                <div class="card">
                    <div class="card-aside-wrap">
                        <div class="card-content">
                            <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#tabItem1" aria-selected="true" role="tab"><em class="icon ni ni-user-circle-fill"></em><span>brand information</span></a>
                                </li>
                            </ul>
                            <div class="card-inner">
                                <div class="tab-content">
                                    <div class="tab-pane active" id="tabItem1" role="tabpanel">
                                    <div>
                                        <h2>Brand Name:  {brand.name}</h2>
                                        {/* <p>Drug:  {brand.drug.name}</p> */}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
    </>
}
export default ViewBrandComponent;