import { useState } from "react";
import apiClient from "../../../utils/axios";
import { NavLink } from "react-router-dom";

const PatientsDataReport = () =>{

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isLoadingWeekly,setIsLoadingWeekly] = useState(false)
    const [isLoadingMonthly,setIsLoadingMonthly] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('');

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        setError('');
    };
  
    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
         
          if (!startDate || !endDate) {
           setError('Start date and End dates are required.');
          } else {
              setIsLoading(true)
              setError('');
              
              const data = {
                  startDate: startDate,
                  endDate:endDate
              }
              try {
                  const response = await apiClient.post(`reports/load-patients-report`, data, {
                      responseType: 'blob' // Ensure the response is treated as a file
                  });
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', 'report.pdf'); // Change the file name and type as needed
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  
  
                  
              } catch (error) {
                  console.log(error)
              }finally {
              setIsLoading(false)
          }
        }
        
    };
  
    const downloadReport = async (r) =>{
      const report_name = r == 'W' ? 'Weekly_report.pdf' : 'Monthly_report.pdf';
      let data = {
          value:true,
          report : r ,
      }
      if(r == 'W'){
          setIsLoadingWeekly(true)
      }
      if (r == 'M'){
          console.log("monthly")
          setIsLoadingMonthly(true)
      }
  
      try {
          const response = await apiClient.post(`reports/load-patients-report`, data, {
              responseType: 'blob' 
          });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', report_name) 
          document.body.appendChild(link);
          link.click();
          link.remove();
      } catch (error) {
          console.log(error.reponse)
      }finally {
          if(r == 'W'){
              setIsLoadingWeekly(false)
          }
          if (r == 'M'){
              console.log("monthly")
              setIsLoadingMonthly(false)
          }
      
      }
  
  
     
    }


    return <div className="normal-report">
    <NavLink className="btn btn-primary" to={`/reports/patients-report`}>Back to Reports</NavLink>
    <div className="d-flex gap-3 mb-5 mt-5">
        <span className="btn btn-primary mr-2 ml-3 p-3" style={{background:'black'}} onClick={(e)=>downloadReport('W')}>{!isLoadingWeekly ? 'Weekly Report' : 'please wait ...'}  </span>
        <span className="btn btn-primary mr-2 ml-3 p-3" style={{background:'black'}} onClick={(e)=>downloadReport('M')}>{!isLoadingMonthly ? 'Monthly Report' : 'please wait ...'} </span>
    </div>
    <div className="row">
        <div className="col-md-6 ">
        <div className="cards">
            <div className="cards-header">
            <h4>Between Dates</h4>
            </div>
                <div className="cards-body">
                    {error && <div className="alert alert-danger">{error}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                            <label htmlFor="startDate">Start Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="startDate"
                                value={startDate}
                                onChange={handleStartDateChange}
                            />
                            </div>
                            <div className="form-group mb-3">
                            <label htmlFor="endDate">End Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="endDate"
                                value={endDate}
                                onChange={handleEndDateChange}
                            />
                            </div>
                            <button type="submit" className="btn btn-primary">
                            {!isLoading ? 'Submit' : 'please wait ...'}
                            </button>
                        </form>
            </div>
        </div>
        </div>
    </div>
</div>
}

export default PatientsDataReport;