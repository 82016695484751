import { NavLink } from "react-router-dom";

const ViewSupplierComponent = ({ supplier ,batches}) => {
    const batchesTable =  <table className="table">
                                <thead>
                                    <tr>
                                        <th>Drug Name</th>
                                        <th>Quantity Received</th>
                                        <th>Quantity Available</th>
                                        <th>LPO</th>
                                        <th>Buying Price</th>
                                        <th>Selling Price</th>
                                        <th>Pack Size ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {batches && batches.length > 0 && batches.map((batch) => (
                                    <tr key={batch.id}>
                                        <td>{batch.brand.name ? batch.brand.name : 'N/A'}</td>
                                        <td>{batch.quantity_received}</td>
                                        <td>{batch.quantity_available}</td>
                                        <td>{batch.lpo}</td>
                                        <td>{batch.buying_price}</td>
                                        <td>{batch.selling_price}</td>
                                        <td>{batch.pack_size.name}</td>
                                    </tr>
                                )) }
                                </tbody>
                            </table>;
return <>
        <div class="nk-block-head">
                <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">supplier Information: <strong class="text-primary small"> {supplier.name}  </strong></h3>
                </div>
            </div>
            <div class="nk-block nk-block-lg">
                <div class="card">
                    <div class="card-aside-wrap">
                        <div class="card-content">
                            <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#tabItem1" aria-selected="true" role="tab"><em class="icon ni ni-user-circle-fill"></em><span>Suppliers information</span></a>
                                </li>
                            </ul>
                            <div class="card-inner">
                                <div class="tab-content">
                                    <div class="tab-pane active" id="tabItem1" role="tabpanel">
                                    <div>
                                        <h2>{supplier.name}</h2>
                                        <p><b>Email</b>: {supplier.email}</p>
                                        <p><b>Phone</b>: {supplier.phone}</p>
                                        <p><b>Date Added</b>: {supplier.date_added}</p>
                                        
                                        <h3>Batches</h3>

                                        {batches.length === 0  && <NavLink to="/inventory/batch/add-batch" className="btn btn-primary" >Add Batch</NavLink>}
                                        {batches && batches.length > 0 && batchesTable}
                                        </div>
                                    {/*  */}
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
    </>
}

export default ViewSupplierComponent;