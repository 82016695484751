export const PERMISSIONS = {

    // super admin permissions
    ADD_USER: 'add_user',
    VIEW_USER: 'view_users',
    EDIT_USER: 'edit_user',
    DELETE_USER: 'delete_user',

    // Patient permissions
    
    ADD_PATIENT: 'add_patient',
    EDIT_PATIENT: 'edit_patient',
    VIEW_PATIENT: 'view_patients',
    DELETE_PATIENT: 'delete_patient',

    // Doctor permissions

    ADD_DOCTOR: 'add_doctor',
    VIEW_DOCTOR: 'view_doctors',
    EDIT_DOCTOR: 'edit_doctor',
    DELETE_DOCTOR: 'delete_doctor',

    // Patient Visits permissions
    ADD_PATIENT_VISIT:'add_patient_visit',
    VIEW_PATIENT_VISIT:'view_patient_visit',
    EDIT_PATIENT_VISIT:'edit_patient_visit',
    DELETE_PATIENT_VISIT:'delete_patient_visit',

    // supplier permissions
    ADD_SUPPLIER:'add_supplier',
    VIEW_SUPPLIER:'view_supplier',
    EDIT_SUPPLIER:'edit_supplier',
    DELETE_SUPPLIER:'delete_supplier',

    // report permissions
    
    VIEW_REPORTS: 'view_reports',
    EDIT_REPORT: 'edit_profile',
    DELETE_REPORTS: 'delete_report',
    // Add other permissions here as needed
};

export const ROLES = {
    SUPER_ADMIN:'Super Admin',
    ADMIN: 'Admin',
    DOCTOR: 'Doctor',
    NURSE: 'Nurse',
    PHARMACY: 'Pharmacy',
    RECEPTIONIST:'Receptionist'
    // Add other roles here as needed
};
