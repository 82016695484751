import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";
import Button from "../../../reusables/Button";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';


const CreateBrand = () => {
    
    const [name, setName] = useState("")
    const [drugs,setDrugs] = useState([])
    const [error, setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [drugId,setDrugId] = useState("")
    
    const navigate = useNavigate()

     const options = drugs && drugs.map(drug => ({
        value: drug.id,
        label: drug.name
     }));
    
    
    const handleDrugChange = (seletedDrugOption) => {
        if (seletedDrugOption && seletedDrugOption.value) {
            const selectedBrand = drugs.find(brand => brand.id === parseInt(seletedDrugOption.value));
            setDrugId(selectedBrand.id) 
        } else {
            setDrugId(""); // Handle empty or undefined case
        }
    }




    const handleSubmit = async () => {

        if (name.trim() === "" || drugId === "") {
            setError("all fields are required")
            return;
        } 

        let data = {
            name: name,
            drug_id:drugId
        }

        try {
            const response = await apiClient.post(`brands/create`, data);
            console.log("response", response)
            if (response.status === 201) {
                setSuccessMessage("Success")
                setTimeout(() => {
                    setSuccessMessage("")
                    navigate("/inventory/brands")
                }, 1000);
            }
        } catch (error) {
            console.log("response", error.response)
        }
    }

     const fetchDrugs = async (pageNo) => {
        let url = `drugs/list`;

        let data = {
            value:true
        }
        const response = await apiClient.post(url,data)
         if (response.data.status) {
            setDrugs(response.data.data)
        } else {
            console.log("something happend")
        }
    }

    useEffect(() => {
        fetchDrugs()
    },[])

    return  <>
        <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                    <div class="nk-block-head-content">
                        <h3 class="nk-block-title page-title">Add Brand</h3>
                        <div class="nk-block-des text-soft"><p>Input new Brand information carefully.</p></div>
                    </div>
                    <div class="nk-block-des text-soft float-end"><p class="btn btn-primary">Bulk Upload</p></div>
                </div>
            </div>
            <div class="nk-block">
                <div class="card card-bordered">
                    <div class="card-inner-group">
                        <div class="card-inner">
                            <div class="nk-block-head">
                                <div class="nk-block-head-content">
                                    <h5 class="title nk-block-title">Create Brand </h5>
                                    <p>Add Brand infomation like Name etc</p>
                                </div>
                            </div>
                            <div class="nk-block">
                            
                                <div class="row gy-4">
                                
                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Brand Name</label>
                                            <div class="form-control-wrap">
                                                <input type="text" className="form-control" name="lpo" value={name} onChange={(e)=>setName(e.target.value)} />
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="full-name">Drug Name</label>
                                                <div class="form-control-wrap">
                                                <Select 
                                                    options={options} 
                                                    onChange={handleDrugChange} 
                                                    placeholder="Search Drug..." 
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                        <div class="card-inner">
                            <div class="nk-block">
                                <div class="row gy-4">
                                    <div class="col-12">
                                        <div class="form-group col-md-2 col-lg-2 col-sm-12 mx-auto">
                                            <Button onClick={handleSubmit}>Add Brand</Button>
                                            <p className="text-center text-danger fw-bold">{error && error }</p>
                                            <p className="text-center text-success fw-bold">{successMessage && successMessage}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
}
export default CreateBrand ;