import { useEffect, useState } from "react";
import Button from "../../../reusables/Button";
import apiClient from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { useAuth } from "../../../store/AuthContext";


const CreateBatch = () => {

    const [brands, setBrands] = useState([])
    
    const [error,setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [errors, setErrors] = useState({});
    const [suppliers, setSuppliers] = useState([])
    const [packSizes,setPackSizes] =useState([])
    const [selectedSupplier, setSeletedSupplier] = useState(null)
    const [seletedUnit, setSeletedUnit] = useState(null)
    const [seletedPackSize,setSeletedPackSize] =useState(null)
    const [unitsOfMeasure, setUnitsOfMeasure] = useState([])

    const navigate = useNavigate();

    const { settings} = useAuth()
        

    const [formData, setFormData] = useState({
        brandId: '',
        quantityReceived: '',
        lpo: '',
        buyingPrice: '',
        sellingPrice: '',
        packSize: '',
        unitOfMeasure: ''
    });


    const options = suppliers.map(supplier => ({
        value: supplier.id,
        label: supplier.name
    }));

    const unitsOfMeasureOptions = unitsOfMeasure.map(unit => ({
        value: unit.id,
        label: unit.name
    }));

    const brandOptions = brands.map(brand => ({
        value: brand.id,
        label: brand.name
    }));

    const packSizeOptions = packSizes.map(size => ({
        value: size.id,
        label: size.name
    }));
    
    const isShowMenu = (menu) =>{
        const setting = settings.find(setting => setting.slug === menu)
        return setting && setting.value !== 0 ? setting.value : ""
    }    
        
    const handleUnitOfMeasureChange = (seletedUnitOfMeasure) => {
         
         if (seletedUnitOfMeasure && seletedUnitOfMeasure.value) {
            setSeletedUnit(seletedUnitOfMeasure.value);
        } else {
            setSeletedUnit(null); // Handle empty or undefined case
        }
    }

     const handlePackSizeChange = (selectedPackSize) => {
         
         if (selectedPackSize && selectedPackSize.value) {
            setSeletedPackSize(selectedPackSize.value);
        } else {
            setSeletedPackSize(null); // Handle empty or undefined case
        }
    }

    const handleBrandChange = (seletedBrandOption) => {
        if (seletedBrandOption && seletedBrandOption.value) {
            const selectedBrand = brands.find(brand => brand.id === parseInt(seletedBrandOption.value));

             setFormData({
                ...formData,
                brandId: selectedBrand.id
             });
            
        } else {
            setFormData({
                ...formData,
                brandId: ""
             });
        }
    }
    
    
    const handleSupplierChange = (selectedOption) => {
        
        if (selectedOption && selectedOption.value) {
            setSeletedSupplier(selectedOption.value);
        } else {
            setSeletedSupplier(null); // Handle empty or undefined case
        }
    };
    
    const handleChange = (e) => {
      console.log("test", e.target.value)
      console.log("name",e.target.name)
        setFormData({
        ...formData,
        [e.target.name]: e.target.value
        });
    };


    const validateForm = () => {
        const newErrors = {};
        if (!formData.brandId) newErrors.brandId = 'Brand is required';
        if (!formData.quantityReceived) newErrors.quantityReceived = 'Quantity Received is required';
        if (!formData.lpo) newErrors.lpo = 'LPO is required';
        if (!formData.buyingPrice) newErrors.buyingPrice = 'Buying Price is required';
        if (!formData.sellingPrice) newErrors.sellingPrice = 'Selling Price is required';
        if (isShowMenu('enable-suppliers-menu') && !selectedSupplier) {
            newErrors.selectedSupplier = 'Enter Supplier';
        }
        // if (!selectedSupplier) newErrors.selectedSupplier = 'Enter Supplier';
        if (!seletedUnit) newErrors.selectedUnit = 'Enter Unit';
        if(!seletedPackSize)  newErrors.seletedPackSize = 'Enter PackSize';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };  

    const handleSubmit = async (e) => {
        e.preventDefault();
        let url = `batches/create`;
        
        if (!validateForm()) {
        // Submit form data
            console.log('Errors submitted:', errors);
            return;
        } 

        let data = {
            brand_id: formData.brandId,
            quantity_received: formData.quantityReceived,
            supplier_id:selectedSupplier,
            lpo: formData.lpo,
            buying_price: formData.buyingPrice,
            selling_price: formData.sellingPrice,
            pack_size_id: seletedPackSize,
            unit_of_measure_id: seletedUnit
        }

        // console.log("batch data : ",data)

        try {
            const response = await apiClient.post(url, data);
            if (response.status === 201) {
                navigate('/inventory/batches')
            } else {
                console.log("something happened", response)
            }
        } catch (error) {
            console.log("error",error)
        }
   
  };

    const getBrands = async () => {
        let url = "brands/list"

        let data = {
            value:true
        }
        try {
             const response = await apiClient.post(url,data)
            if (response.data.status) {
                setBrands(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
       
    }

    const fetchSuppliers = async () => {
        let url = `suppliers/list`;
        let data  = {
            value:true
        }
        try {
            const response = await apiClient.post(url, data)
            if (response.data.status) {
                setSuppliers(response.data.data)
            } else {
                console.log("something happened", response)
            }
            
        } catch (error) {
            
        }
    }

    const getUnitOfMeasure = async () => {
        let url = `units-of-measure`;
        try {
            const response = await apiClient.get(url)
           setUnitsOfMeasure(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getPackSizes = async () => {
        let url = `pack-sizes`;
        try {
            const response = await apiClient.get(url)
            console.log("pack sizes", response.data)
           setPackSizes(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    

    useEffect(() => {
        getBrands()
        getUnitOfMeasure()
        getPackSizes()
        fetchSuppliers()
    }, [])
    
    return <>
        <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                    <div class="nk-block-head-content">
                        <h3 class="nk-block-title page-title">Add Batch</h3>
                        <div class="nk-block-des text-soft"><p>Input new Batch information carefully.</p></div>
                    </div>
                    <div class="nk-block-des text-soft float-end"><p class="btn btn-primary">Bulk Upload</p></div>
                </div>
            </div>
            <div class="nk-block">
                <div class="card card-bordered">
                    <div class="card-inner-group">
                        <div class="card-inner">
                            <div class="nk-block-head">
                                <div class="nk-block-head-content">
                                    <h5 class="title nk-block-title">Create Batch </h5>
                                    <p>Add Supplier infomation like Name, Email etc</p>
                                </div>
                            </div>
                            <div class="nk-block">
                            
                                <div class="row gy-4">
                                
                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="full-name"> Name</label>
                                                <div class="form-control-wrap">
                                                  
                                                <Select 
                                                    options={brandOptions} 
                                                    onChange={handleBrandChange} 
                                                    placeholder="Search Brand..." 
                                                />
                                                

                                                {errors.brandId && <p className="error text-danger">{errors.brandId}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    {isShowMenu('enable-suppliers-menu') && <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Select Supplier</label>
                                            <div class="form-control-wrap">
                                                <Select
                                                    options={options}
                                                    onChange={handleSupplierChange}
                                                    placeholder="Select Supplier..."
                                                />
                                                {errors.selectedSupplier && <p className="error text-danger">{errors.selectedSupplier}</p>}
                                            </div>
                                        </div>
                                    </div>}


                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Quantity Received</label>
                                            <div class="form-control-wrap">
                                               <input type="number" className="form-control" name="quantityReceived"  value={formData.quantityReceived} onChange={handleChange} />
                                               {errors.quantityReceived && <p className="error text-danger">{errors.quantityReceived}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">LPO</label>
                                            <div class="form-control-wrap">
                                                <input type="text" className="form-control" name="lpo" value={formData.lpo} onChange={handleChange} />
                                                {errors.lpo && <p className="error text-danger">{errors.lpo}</p>}
                                            </div>
                                        </div>
                                    </div>
                                                
                                                <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="bp">Buying Price</label>
                                            <div class="form-control-wrap">
                                                <input type="number" className="form-control" name="buyingPrice" value={formData.buyingPrice} onChange={handleChange} />
                                                 {errors.buyingPrice && <p className="error text-danger">{errors.buyingPrice}</p>}
                                            </div>
                                        </div>
                                                </div>
                                                
                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="sp">Selling Price</label>
                                            <div class="form-control-wrap">
                                                <input type="number" className="form-control" name="sellingPrice"  value={formData.sellingPrice} onChange={handleChange} />
                                                {errors.sellingPrice && <p className="error text-danger">{errors.sellingPrice}</p>}
                                            </div>
                                        </div>
                                    </div>
                                                


                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Pack Size</label>
                                            <div class="form-control-wrap">
                                              
                                                 <Select 
                                                    options={packSizeOptions} 
                                                    onChange={handlePackSizeChange} 
                                                    placeholder="Select pack size..." 
                                                />
                                                {errors.seletedPackSize && <p className="error text-danger">{errors.seletedPackSize}</p>} 

                                            </div>
                                        </div>
                                    </div>
                                                
                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="full-name"> Unit </label>
                                                <div class="form-control-wrap">
                                                  
                                                <Select 
                                                    options={unitsOfMeasureOptions} 
                                                    onChange={handleUnitOfMeasureChange} 
                                                    placeholder="Select Unit..." 
                                                />
                                                

                                                {errors.selectedUnit && <p className="error text-danger">{errors.selectedUnit}</p>}
                                            </div>
                                        </div>
                                    </div>

                                                
                                                

                                    
                                
                                
                                </div>
                            </div>
                        </div>
                        <div class="card-inner">
                            <div class="nk-block">
                                <div class="row gy-4">
                                    <div class="col-12">
                                        <div class="form-group col-md-2 col-lg-2 col-sm-12 mx-auto">
                                            <Button onClick={handleSubmit}>Add Batch</Button>
                                            <p className="text-center text-danger fw-bold">{error && error }</p>
                                            <p className="text-center text-success fw-bold">{successMessage && successMessage}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>

}

export default CreateBatch;