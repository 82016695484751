import { useState } from "react";
import { NavLink } from "react-router-dom";
import SickLeaveTemp from "./templates/SickLeaveTemp";
import LeaveIndex from "./Leaves/LeaveIndex";

const PatientInfo = ({person,id}) =>{

    const [showSickLeave,setShowSickLeave] = useState(false)

    const patient = person ;

    // const calculateAge = (dateOfBirth) => {
    //     const dob = new Date(dateOfBirth);
    //     const today = new Date();
    //     let age = today.getFullYear() - dob.getFullYear();
    //     const monthDifference = today.getMonth() - dob.getMonth();
    //     if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
    //         age--;
    //     }else{
    //         return dateOfBirth;
    //     }
    //     return age;
    // };

    const personalInfo = <><div className="nk-block nk-block-between">
    <div className="nk-block-head">
        <h6 className="title">Personal Information </h6>
        {/* <p>Patients personal information. </p> */}
    </div>
    <div className="nk-block"> 
        <NavLink className="btn btn-white btn-icon btn-outline-light" to={`/patients/edit/${id}`}>
            <em className="icon ni ni-edit" style={{fontSize:"20px"}}></em>
        
        </NavLink>
    </div>
</div>
<div className="nk-block">
<div className="profile-ud-list">
    <div className="profile-ud-item">
        <div className="profile-ud wider">
            <span className="profile-ud-label fw-bold">Name</span>
            <span className="profile-ud-value">{patient.name}</span>
        </div>
    </div>
    <div className="profile-ud-item">
        <div className="profile-ud wider">
            <span className="profile-ud-label fw-bold">Gender</span>
            <span className="profile-ud-value">{patient.gender}</span>
        </div>
    </div>
    <div className="profile-ud-item">
        <div className="profile-ud wider">
            <span className="profile-ud-label fw-bold">Age</span>
            <span className="profile-ud-value">{patient.age}</span>
        </div>
    </div>

    <div className="profile-ud-item">
        <div className="profile-ud wider">
            <span className="profile-ud-label fw-bold">Date of Birth</span>
            <span className="profile-ud-value">{patient.dob}</span>
        </div>
    </div>
    


    <div className="profile-ud-item">
        <div className="profile-ud wider">
            <span className="profile-ud-label fw-bold">Email Address</span>
            <span className="profile-ud-value">{patient.email}</span>
        </div>
    </div>
    <div className="profile-ud-item">
        <div className="profile-ud wider">
            <span className="profile-ud-label fw-bold">City</span>
            <span className="profile-ud-value">{patient.city}</span>
        </div>
</div>
<div className="profile-ud-item">
    <div className="profile-ud wider">
        <span className="profile-ud-label fw-bold" >ID</span>
        <span className="profile-ud-value">{patient.national_id} </span>
    </div>
</div>

<div className="profile-ud-item">
    <div className="profile-ud wider">
        <span className="profile-ud-label fw-bold">Blood Group</span>
        <span className="profile-ud-value">{patient.blood_group === "x" ? "Not known" : patient.blood_group } </span>
    </div>
</div>


    
<div className="profile-ud-item">
    <div className="profile-ud wider">
        <span className="profile-ud-label fw-bold">Mobile</span>
        
            {patient.phones && patient.phones.map((phone,index)=>(
                <span key={index} className="profile-ud-value" style={{ display: 'block', marginLeft: '10px' }}>
                    {phone}
                </span>
            ))}
        
    </div>
</div>

<div className="profile-ud-item">
    <div className="profile-ud wider">
        <span className="profile-ud-label fw-bold">Work Place</span>
        <span className="profile-ud-value">{patient.place_of_work}</span>
    </div>
</div>




<div className="profile-ud-item">
    <div className="profile-ud wider">
        <span className="profile-ud-label fw-bold">Insurance Card Number</span>
        <span className="profile-ud-value">{patient.insurance_card_number}</span>
    </div>
</div>

<div className="profile-ud-item">
    <div className="profile-ud wider">
        <span className="profile-ud-label fw-bold">Med Insurance Card Number</span>
        <span className="profile-ud-value">{patient.med_insurance_card_number}</span>
    </div>
</div>


</div>
</div>
</>
    return <>
        {!showSickLeave ? personalInfo : <>
            <button onClick={()=>setShowSickLeave(false)} style={{background:'black',color:'#fff'}}>Go Back</button>
            <LeaveIndex patient={patient}/>
        </>}

        <div className="mt-2">
            {!showSickLeave && <button className="btn btn-primary" onClick={()=>setShowSickLeave(true)}>Sick off</button>}
           
        </div>

    </>
}

export default PatientInfo;