import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";

const CompanySetUp = () => {
    
    const [companysList,setCompanysList] = useState([])
    const [companyName, setCompanyName] = useState("");
    const [error, setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddCompany, setIsAddCompany] = useState(false)
    const [isEditCompany, setIsEditCompany] = useState(false)
    const [companyDesc,setCompanyDesc] = useState("")
    const [id, setId] = useState("")
    
    

    const submitCompanyHandler = async (e) => {
        e.preventDefault();
        if (companyName.trim() === "") {
            setError("Company name cannot be empty")
            return;
        }
       
        if (isEditCompany) {
            let data = {
                companyName: companyName,
                companyDesc:companyDesc
            }

            const response = await apiClient.put(`work-places/${id}`,data)
            if (response.status === 200 && response.data.status) {
                setError("")
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage("")
                }, 3000)
                getCompanysData()
                setIsAddCompany(false)
                setIsEditCompany(false)
                setCompanyName("")
            }
        } else {
            let data = {
                companyName: companyName,
                companyDesc:companyDesc
            }
            const response = await apiClient.post(`work-places`,data)
            if (response.status === 200 && response.data.status) {
                setError("")
                setIsAddCompany(false)
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage("")
                }, 3000)
                getCompanysData()
                
            }
        }
        

    }

    const handleEditCompany = (company) => {
        setId(company.id)
        setIsAddCompany(true)
        setIsEditCompany(true)
        setCompanyName(company.name)
        setCompanyDesc(company.description)
    }
    const handleDeleteCompany = async (companyId) => {
        // setId(companyId)
        const response = await apiClient.delete(`work-places/${companyId}`)
        if(response.status === 200){
            setSuccessMessage(response.data.message)
            getCompanysData()
        }
    }
    


    const getCompanysData = async () => {
        setIsLoading(true)
        const response = await apiClient.get(`work-places`)
        if (response.status === 200 && response.data.data.status) {
            setIsLoading(false)
            console.log("response",response.data.data.data)
            setCompanysList(response.data.data.data)
		} else {
			console.log("something happened")
		}
    }
    

    const backToList = () => {
        setIsAddCompany(false)
        setCompanyName("")
        setCompanyDesc("")
        setId("")
    }

    useEffect(() => {
        getCompanysData()
    },[])


    const companyCreate = (<div class="row gy-4">
        <div class="example-alert">
                <div class="alert alert-success alert-icon">
                    Use this Page to set up the <strong>company</strong> your patients belong to.
                </div>
            </div>
        <div className="d-flex justify-content-between " style={{ cursor: 'pointer' }} >
            <div className="mt-3">{isEditCompany ? 'Edit Company' : 'Add Company'}</div>
            <div onClick={backToList} className="mt-3"><em class="icon ni ni-plus" ></em><span>Back To Company lists</span></div>
        </div>
            
            <div class="col-xxl-3 col-md-4">
                <div class="form-group">
                    <label class="form-label" for="full-name">Company</label>
                    <div class="form-control-wrap">
                        <input type="text" className="form-control" placeholder="Enter Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                    <div>

                    <label class="form-label" for="full-name">Description</label>
                        <div class="form-control-wrap">
                           
                        <textarea type="text" className="form-control" placeholder="Enter Company Name" value={companyDesc} onChange={(e) => setCompanyDesc(e.target.value)} />
                    </div>
                    <button className="btn btn-primary mt-2" onClick={submitCompanyHandler}>{!isEditCompany ? 'Add Company' : 'Update'}</button>
                        <span className="text-danger fw-bold mt-1">{error && error}</span>
                    </div>
                    </div>
                </div>
            </div>
    </div>)

    const companyList = <>
        <div className="float-end m-3" style={{ cursor:'pointer' }} onClick={()=>setIsAddCompany(true)}>
            <em class="icon ni ni-plus" ></em><span>Add Company</span>
        </div>
        <span className="text-success fw-bold mt-3">{ successMessage && successMessage}</span>
        {isLoading && <p className="mt-1 text-center">Loading ...</p>}
        {!isLoading && companysList && companysList.length === 0 && <p className="mt-1 text-center">No Companies </p>}
        {!isLoading && companysList && companysList.length > 0 && <table className="table table-hover table-striped">
            
                <thead>
                    <tr>
                        
                        <td>Name</td>
                        <td>Description</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>

                
                    {companysList.map(company => (
                        
                        <tr>
                            
                            <td>{company.name}</td>
                            <td>{company.description}</td>
                            
                        <td className = "d-flex gap-1 " >
                                <button className="btn btn-primary p-2" onClick={() => handleEditCompany(company)} style={{ marginRight: '10px' }}>Edit</button>
                                <button className="btn btn-danger p-2" onClick={() => handleDeleteCompany(company.id)} >Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
        </table>}
        

    </>
    return isAddCompany ? companyCreate :  companyList
}

export default CompanySetUp