
import { useState } from 'react';
import { PERMISSIONS } from './../../constants/rolesPermissions';
import Button from './../../reusables/Button';
import apiClient from './../../utils/axios';
import { useAuth } from './../../store/AuthContext';
const ManagePermissions = () => {

    const permissions = PERMISSIONS
    
    const [message,setMessage] =useState("")
    
    const updatePermissionsHandler = async () => {
        let data = {
            permissions:permissions
        } 
        try {
            const response = await apiClient.post(`roles-permissions/update-permissions`, data)
            if (response.status === 200) {
                setMessage("successfully updated permissions")
                setTimeout(() => {
                   setMessage("") 
                }, 3000);
            }

        } catch (error) {
            console.log("error",error)
        }
    }

    // Helper function to format permission names for display
    const formatPermissionName = (permissionName) => {
    // Example: Convert 'ADD_USER' to 'Add User'
        return permissionName.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase());
    };
    

    return <div className='col-4 '>
        <table className='table table-stripped card '>
            <thead>
                <tr>
                    <td>Permission</td>
                    <td>Description</td>
                </tr>
            </thead>
            <tbody>
                {Object.entries(permissions).map(([key, value]) => (
                        <tr key={key}>
                            <td>{value}</td>
                            <td>{formatPermissionName(key)}</td>
                        </tr>
                    ))}
            </tbody>
        </table>
        <Button onClick={updatePermissionsHandler}>Update Permissions</Button>
        <span className='text-success text-center mt-2'>{ message && message}</span>
    </div>
}
export default ManagePermissions;