

import {useNavigate} from 'react-router-dom'

const AllReports = () => {

    const navigate = useNavigate()
    const goToPatientsReport = (url) =>{
        navigate(url)
    }
    return <>
      
         <div className="row g-gs">
            <div className="col-xxl-3 col-sm-6 ">
                <div className="card" style={{ cursor: 'pointer' }} onClick={() => goToPatientsReport('patients-report')}>
                    <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h6 className="title">Patients Report</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

                

               

  
    </>
}

export default AllReports;