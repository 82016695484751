import { useEffect, useState } from "react";
import apiClient from "../../utils/axios";
import Button from "../../reusables/Button";
import ReactPaginate from "react-paginate";
import useCheckRole from "../../reusables/CheckRoleOrPermission";
import { ROLES } from "../../constants/rolesPermissions";
import { useAuth } from "../../store/AuthContext";

const ManageUsers = () =>{

    const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const [phone,setPhone] = useState("")
    const [password,setPassword] = useState("")
    const [roleId,setRoleId] = useState("")

    const [users,setUsers] = useState([])
    const [roles,setRoles] = useState([])
    const [error, setError] = useState("")
    const [emailError,setEmailError] =useState("")

    const [totalCount,setTotalCount] = useState([])
    const [limit,setLimit] =useState(8)
    const [pageNo,setPageNo] = useState(1) 
    const[isEdit ,setIsEdit] = useState(false)
    const [message,setMessage] = useState("")
    const [selectedRoles, setSelectedRoles] = useState([]);

    const { hasRole } = useCheckRole(); 
    const {authUser} = useAuth()


    const getRoles = async () =>{
        try {
            const response = await apiClient.get('roles');
            if (response.status === 200) {
                console.log("roles for users",response.data.roles)
                setRoles(response.data.roles)
            }else{
                console.log('something happened')
            }
        } catch (error) {
            console.log("error",error)
        }
    } 

    const getUsers = async (pageNo = 1) =>{
        try {
            let data = {
                pageNo:pageNo,
                limit:limit
            }
            
            const response = await apiClient.post(`users/get-users`,data);

            if(response.status == 200  && response.data.status){
                setUsers(response.data.data.data)
                setTotalCount(response.data.totalCount)
            }else{
                console.log('something happened')
            }
        } catch (error) {
            console.log("error",error)
        }
    }

    const validateEmail = (email) => {
        // Basic email regex pattern    
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };


    const addUserHandler = async () =>{
        //validate email

          if (!validateEmail(email)) {
            setEmailError('Please enter a valid email');
           return ;
           }

           if(email.trim() === "" || name.trim() === "" ||  phone.trim() === "" ||  selectedRoles.length < 1){
               return ;
           }

           let data = {
            name:name,
            email:email,
            phone:phone,
            password:password,
            role_id:roleId,
            selected_roles:selectedRoles
         }

        if(!isEdit ){
            //add new user
            
               try {
                   const response = await apiClient.post(`users`,data);
                   if(response.status === 200  && response.data.status){
                        setEmailError("")
                        setError("")
                        setMessage("Success")
                        setTimeout(() => {
                            setMessage("")
                        }, 3000);
                       getUsers()
                       addUser()
                   }else{
                       setError( response.data.message)
                   }
               } catch (error) {
                   console.log("error",error)
               }

        }else{
            //update user here            
            try {
                const response = await apiClient.post(`users/update`,data);
                if(response.status === 200  && response.data.status){
                    setMessage("Success")
                    setTimeout(() => {
                        setMessage("")
                    }, 3000);
                    getUsers()
                    setError("")
                    // addUser()
                }else{
                    setError( response.data.message)
                    setTimeout(() => {
                        setError("")
                    }, 3000);
                    
                }
            } catch (error) {
                console.log("error",error)
            }
        }
       
    }

    const addUser = () =>{
        setIsEdit(false)
        setName("")
        setEmail("")
        setPassword("")
        setPhone("")
        setRoleId("")
        setSelectedRoles([])
    }

    const handlePageClick = (data) =>{
        setPageNo(data.selected + 1)

    }

    const handleEditUser = async (userId) =>{
        setIsEdit(true)
        const response = await apiClient.get(`users/${userId}`)
        if(response.data.status){
            const userRoles = response.data.user.roles
            const userRoleIds = userRoles && userRoles.length > 0 && userRoles.map(role => {
                const foundRole = roles.find(r => r.name === role);
                return foundRole ? foundRole.id : null;
            })

            const allRoleIds = userRoleIds ? userRoleIds : []
            setSelectedRoles(allRoleIds);            
            setName(response.data.user.name)
            setEmail(response.data.user.email)
            setPhone(response.data.user.phone)
            setRoleId(roleId)
            setPassword("")
        }
    }
    const handleDeleteUser = async (userId) =>{

       let isConfirmed = window.confirm("A you sure you want to delete")
       if (isConfirmed) {
            try {
                const response = await apiClient.post(`users/delete`,{userId:userId});
                if (response.status === 200) {
                    getUsers()
                } else {
                    console.log('Something happened.Failed to delete the user.');
                }
            } catch (error) {
                console.error('There was an error deleting the user!', error);
            }
        }
    }

    const handleCheckboxChange = (roleId) => {
        setSelectedRoles(prevSelectedRoles => {
            if (prevSelectedRoles.includes(roleId)) {
                return prevSelectedRoles.filter(selectedRole => selectedRole !== roleId);
            } else {
                return [...prevSelectedRoles, roleId];
            }
        });
    };

     const isAdmin = () => {
      return hasRole(ROLES.ADMIN)
    };

    useEffect(()=>{
        getRoles()
        getUsers(pageNo)
        console.log("selecetd pageNo ",pageNo)
    },[pageNo])



    return <div className="row">
        <div className="col-md-3">
            <div className="mb-3">
                {!isEdit ? <h5>Create User</h5> : 
                <div className="d-flex justify-content-between">
                    <div><h5>Edit {name} </h5></div>
                    <div><span className="btn btn-primary" onClick={addUser}>Add User</span></div>
                </div>
            }
            </div>

        <div class="form-group">
            <label for="exampleInputEmail1">Name</label>
            <input type="text" class="form-control" id="exampleInputEmail1"  placeholder="Enter name" 
            value={name}
            onChange={(e)=>setName(e.target.value)}/>
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Email </label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" 
            value={email}
            onChange={(e)=>setEmail(e.target.value)}/>
            <span className="text-danger">{ emailError && emailError} </span>
        </div>
    
        <div class="form-group">
            <label for="exampleInputPassword1">Phone</label>
            <input type="number" class="form-control" id="exampleInputPassword1" placeholder="Phone"
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}/>
        </div>
        <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input type="password" class="form-control" id="exampleInputPassword1"
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
            placeholder="Password"/>
        </div>
        <div class="form-group">
            <label htmlFor="exampleInputEmail1">User Roles</label>
            {roles && roles.length > 0 && roles.filter((role)=>role.name !== ROLES.ADMIN && role.name !== ROLES.SUPER_ADMIN).map((role) => (
                <div key={role.id}>
                    <input
                        type="checkbox"
                        id={`role-${role.id}`}
                        checked={selectedRoles.includes(role.id)}
                        onChange={() => handleCheckboxChange(role.id)}
                    />
                    <label htmlFor={`role-${role.id}`} style={{ marginLeft:'5px' }}>{role.name}</label>
                </div>
            ))}

          
        </div>
    
            <Button onClick={ addUserHandler  }>{!isEdit ? 'Submit' : 'Update'}</Button>
            <span className="text-danger fw-bold">{error !== "" && error}</span>
            <span  className="text-success fw-bold">{message !== "" && message }</span>
        </div>
        <div className="col-md-9">
            <h5>Users List</h5>
            {users && users.length && <table className="table table-hover table-striped">
                <thead>
                    <tr>
                        <td>Roles</td>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Phone</td>
                        {/* <td>Status</td> */}
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>

                    
                    {users.map(user => (
                        
                       isAdmin  && user.email !== authUser.email &&  <tr>
                            <td>{user.roles.map(role => (<span>{role},<br /></span>))}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            {/* <td>{user.status}</td> */ }
                        <td className = "d-flex gap-1 " >
                                <button className="btn btn-primary p-2" onClick={() => handleEditUser(user.id)} style={{ marginRight: '10px' }}>Edit</button>
                                <button className="btn btn-danger p-2" onClick={() => handleDeleteUser(user.id)} >Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}

            <div className="mt-3">
                {totalCount > limit &&
                    <div className="mt-3 mb-2">
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={(totalCount / limit)}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        /> 
                    </div>
                }
            </div>

        </div>
</div>
}
export default ManageUsers;