import { useEffect, useRef, useState } from "react";
import apiClient from "../../../utils/axios";
import { NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

const Suppliers =  () =>{

    const [originalListSuppliers,setOriginalListSuppliers] = useState([])
    const [listSuppliers,setListSuppliers] = useState([])
    const [totalCount,setTotalCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
	const [limit, setLimit] = useState(10);
    const [search,setSearch] = useState( "")
    const [supplier,setSupplier] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false)
    const modalRef = useRef(null);


     


    const searchHandler = async (e) =>{
       let search = e.target.value;
        if(search.trim() === ""){
            setListSuppliers(originalListSuppliers)
            return;
        }else{
            let data = {
                search:search
            }
            try {
                const response  = await apiClient.post('suppliers/search-supplier',data);
                if(response.status == 200 ){
                    setListSuppliers(response.data.data)
                }else{
                    console.log("not found",response)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const closeModal = () => {
		setOpenModal(false)
		setSupplier({})	
	}


    const removeSupplierHandler = async () =>{
        let url = `suppliers/delete/${supplier.id}`
        try {
            const response = await apiClient.post(url)
                fetchSuppliers(pageNo)
                setOpenModal(false)
           
        } catch (error) {
            console.log("error",error)
        }
    }



    const deleteModaHandler = (data) =>{
        setOpenModal(true)
        setSupplier(data)
    }

    const handlePageClick = (data) => {
		setPageNo(data.selected + 1)
	}


    const fetchSuppliers = async () =>{
        let url = "suppliers/list";

        let data = {
			pageNo: pageNo,
			limit: limit
		}

        try {
            const response = await apiClient.post(url,data)

            if (response.status === 200 && response.data.status) {
                console.log("response", )
				setIsLoading(false)
				setListSuppliers(response.data.data.data)
                setOriginalListSuppliers(response.data.data.data)
				setTotalCount(response.data.count)
			} else {
				setIsLoading(false)
				// setListsuppliers([])
				setTotalCount(0)
			}


        } catch (error) {
            console.log(error)
        }
    }

    const hangleSearchSupplier = (e) =>{
        const search = e.target.value
        // setSearch(search)
        // console.log("search",search)
    }


    useEffect(()=>{
        fetchSuppliers();
    },[pageNo])

    useEffect(() => {
		if (openModal && modalRef.current) {
			const modal = new window.bootstrap.Modal(modalRef.current);
			modal.show();
			return () => modal.hide();
		}
		
	}, [openModal, supplier]);



    // 


    return <>
    <div class="nk-block-head nk-block-head-sm">
			<div class="nk-block-between">
				<div class="nk-block-head-content">
					<h3 class="nk-block-title page-title"> Suppliers</h3>
					
					<div class="nk-block-des text-soft"><p>You have total {totalCount} Suppliers</p></div>
					
				</div>
				{/* <p className="fw-bold fs-10 text-danger">{successMessage ? successMessage : "	"}</p> */}
				<div class="nk-block-head-content">
					<a href="/demo7/hospital/patient-add.html" class="btn btn-icon btn-primary d-md-none"><em class="icon ni ni-plus"></em></a>
					<NavLink to="/inventory/suppliers/add-supplier" class="btn btn-primary d-none d-md-inline-flex"><em class="icon ni ni-plus"></em><span>Add Supplier</span></NavLink>
				</div>
			</div>
    </div>
    {/*  */}
    <div class="nk-block">
        <div class="card card-bordered card-stretch">
            <div class="card-inner-group">
                <div class="card-inner position-relative card-tools-toggle">
                    <div class="card-title-group">
                        <div class="card-tools">
                            <div class="form-inline flex-nowrap gx-3">
                                <input type="search"
                                 onChange={searchHandler}
                                 placeholder="Search Supplier" class="form-control" style={{width: "280px;"}} 
                                /></div>
                        </div>
                        <div class="card-tools me-n1">
                            <ul class="btn-toolbar gx-1">
                                <li class="btn-toolbar-sep"></li>
                                <li>
                                    <div class="toggle-wrap">
                                        <a href="#" class="btn btn-icon btn-trigger toggle" data-target="cardTools"><em class="icon ni ni-menu-right"></em></a>
                                        <div class="toggle-content" data-content="cardTools">
                                            {/* <ul class="btn-toolbar gx-1">
                                                <li>
                                                    <div class="dropdown">
                                                        <a href="#" class="btn btn-trigger btn-icon dropdown-toggle" data-bs-toggle="dropdown"><em class="icon ni ni-setting"></em></a>
                                                        <div class="dropdown-menu dropdown-menu-xs dropdown-menu-end">
                                                            <ul class="link-check">
                                                                <li><span>Show</span></li>
                                                                <li class="active"><a>10</a></li>
                                                                <li class=""><a>15</a></li>
                                                                <li class=""><a>20</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-search search-wrap" data-search="search">
                        <div class="card-body">
                            <div class="search-content">
                                <a href="#" class="search-back btn btn-icon toggle-search" data-target="search"><em class="icon ni ni-arrow-left"></em></a>
                                <input type="text" class="form-control border-transparent form-focus-none" placeholder="Search by name or patient id" /><button class="search-submit btn btn-icon"><em class="icon ni ni-search"></em></button>
                            </div>
                        </div>
                    </div>
                    </div>
                <div class="card-inner p-0 table-responsive">
                    <div class="text-center">
                        <div class="mb-3"></div>
                        <div class="mb-3"></div>
                        <div></div>
                    </div>
                    <div class="nk-tb-list nk-tb-ulist">
            <div class="nk-tb-item nk-tb-head">
            
                <div class="nk-tb-col"><span class="sub-text fw-bold">Supplier Name</span></div>
                <div class="nk-tb-col tb-col-sm"><span class="sub-text fw-bold">Email</span></div>
                <div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Contact</span></div>
                <div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Date Added</span></div>
                
                <div class="nk-tb-col nk-tb-col-tools">
                    <ul class="nk-tb-actions gx-1 my-n1">
                        <div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Actions</span></div>
                
                    </ul>
                </div>
            </div>

            {/* debug */}
            
            {listSuppliers.length > 0 && listSuppliers.map((supplier) => {
                return <div class="nk-tb-item" key={supplier.id}>
            
                    <div class="nk-tb-col">
                        <NavLink to={`/suppliers/${supplier.id}`}>
                            <div class="user-card">
                                <div class="user-info"><span class="tb-lead">{supplier.name}</span> </div>
                            </div>
                        </NavLink>
                    </div>
                    <div class="nk-tb-col tb-col-sm">
                        <span class="fw-medium">{supplier.email}</span>
                    </div>
                    <div class="nk-tb-col tb-col-sm">
                        <span class="fw-medium">{supplier.phone}</span>
                    </div>
                    <div class="nk-tb-col tb-col-sm">
                        <span class="fw-medium">{supplier.date_added}</span>
                    </div>
                    
                    
                    <div class="nk-tb-col nk-tb-col-tools">
                        <ul class="nk-tb-actions gx-1">
                        
                            <li>
                                <div class="drodown">
                                    <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                    <div class="dropdown-menu dropdown-menu-end">
                                        <ul class="link-list-opt no-bdr" style={{ cursor: 'pointer' }}>
                                            <li>
                                                <NavLink to={`/invetory/suppliers/${supplier.id}`} >
                                                    <em class="icon ni ni-eye"></em><span>View Details</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/inventory/suppliers/Edit/${supplier.id}`}><em class="icon ni ni-edit"></em><span>Edit</span></NavLink>
                                            </li>
                                            <li>
                                                <a onClick={() => deleteModaHandler(supplier)}><em class="icon ni ni-trash" ></em><span>Delete </span>
                                                </a>
                                            </li>
                                        
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            })}
            {/* end debug */}
        </div>
            </div>
                
            <div className="mt-3">
                            {totalCount > limit &&
                                <div className="mt-3 mb-2">
                                    {/* <CustomPagination  totalCount={totalCount} limit={limit} handlePageClick={handlePageClick} /> */}
                                    <ReactPaginate
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        pageCount={(totalCount / limit)}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    /> 
                                </div>
                            }
            </div>
        </div>
    </div>


    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modalRef}>
				<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
					<h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
					<button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
					</div>
					<div className="modal-body">
					<p><strong>Are you sure You want to delete : {supplier.name}</strong></p>
					</div>
					<div className="modal-footer">
					<button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
					<button type="button" className="btn btn-danger"  onClick={()=>removeSupplierHandler(supplier.id)} >Confirm Delete</button>
					</div>
				</div>
				</div>
			</div>


    </div>
    {/*  */}



    </>
    
}

export default Suppliers;