import { useEffect, useState } from "react"
import apiClient from "../utils/axios"
import Select from 'react-select';
import Button from "../reusables/Button";
import ProceduresTable from "./ProceduresTable";

const Procedures = ({ consultationId, patientId }) => {

    const [proceduresList, setProceduresList] = useState()
    const [description, setDescription] = useState("")
    const[quantity,setQuantity] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingProcedures,setIsLoadingProcedures] = useState(true)
    const [procedureId, setProcedureId] = useState("")
    const [error, setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [myProcedures,setMyProcedures] = useState([])
    

     const options = proceduresList ? proceduresList.map(procedure => ({
        value: procedure.id,
        label: procedure.name
     })) : [];
    
    
    const handleProcedureChange = (seletedOption) => {
         
        console.log("id",seletedOption)
        if (seletedOption && seletedOption.value) {
            const selectedProcedure= proceduresList.find(procedure => procedure.id === parseInt(seletedOption.value));
            setProcedureId(selectedProcedure.id) 
        } else {
            setProcedureId(""); // Handle empty or undefined case
        }
    }

    

    const handleSubmit = async () => {

        if (procedureId === "" || quantity === 0) {
            setError("The fields marked in red are mandatory")
            setSuccessMessage("")
            return;
        }
        setIsSubmiting(true)
        try {
            let data = {
                procedure_id: procedureId,
                description: description,
                consultation_id: consultationId,
                quantity:quantity
            }

            const response = await apiClient.post(`patient-procedures`,data)
            if (response.status === 200 && response.data.status) {
                setIsSubmiting(false)
                setError("")
                setSuccessMessage(response.data.message)
                getPatientProcedures()
                setDescription("")
                setQuantity("")
                setProcedureId("");
                
            }
        } catch (error) {
            console.log("error",error.response.error)
            setError("Network error")
        }
        
    }

    const getProcedures = async () => {
        setIsLoading(true)
        try {
            const response = await apiClient.get('procedures')
            setProceduresList(response.data.data)
        } catch (error) {
            console.log("error loading procedures")
        }
        finally {
            setIsLoading(false)
        }
            
    }

    const getPatientProcedures = async () => {

        const response = await apiClient.post(`patient-procedures/all-procedures`, {
            consultationId:consultationId
        })
        if (response.status === 200 && response.data.status) {
            setIsLoadingProcedures(false)
            setMyProcedures(response.data.data)
        } else {
            console.log("something happened")
        }
    }
    

    useEffect(() => {
       
        const getData = async () => {
            await  getProcedures()
            await getPatientProcedures()
        }
        getData()
    }, [])
    
    return  <div class="nk-content-body">
                <div class="nk-block-head nk-block-head-sm">
                    <div class="nk-block-between">
                        <div class="nk-block-head-content">
                            <h3 class="nk-block-title page-title">Procedure</h3>
                        </div>
                    </div>
                </div>
                <div class="nk-block">
                    <div class="card card-bordered">
                        <div class="card-inner-group">
                            <div class="card-inner">
                                <div class="nk-block-head">
                                    <div class="nk-block-head-content">
                                        <h5 class="title nk-block-title">Add a Procedure </h5>
                                    </div>
                                </div>
                                <div class="nk-block">
                                
                                    <div class="row gy-4">
                                    
                                        
                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Procedures <span style={{ color:'red' }}>*</span></label>
                                            <div class="form-control-wrap">
                                            
                                                {!isLoading && <Select
                                                    options={options}
                                                    onChange={handleProcedureChange}
                                                    placeholder="Select Procedure..."
                                            />}
                                            {isLoading && <p>Loading Procedures ..</p>}

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xxl-3 col-md-4">
                                            <div class="form-group">
                                        <label class="form-label" for="email">Quantity <span style={{ color:'red' }}>*</span></label>
                                                <div class="form-control-wrap">
                                                <input
                                                    className="form-control"
                                                        type="number"    
                                                        value={quantity}
                                                        onChange={(e)=>setQuantity(e.target.value)} 
                                                        placeholder="Add Quantity" 
                                                    />
                                                </div>
                                            </div>
                                    </div>
                                    
                                    <div class="col-xxl-3 col-md-4">
                                            <div class="form-group">
                                                <label class="form-label" for="email">Notes <span style={{ color:'red' }}>*</span></label>
                                                <div class="form-control-wrap">
                                                <textarea 
                                                    className="form-control"
                                                        value={description}
                                                        onChange={(e)=>setDescription(e.target.value)} 
                                                    placeholder="Add Note" 
                                                    style={{ minHeight:'12px' }}
                                                    />
                                                </div>
                                            </div>
                                    </div>
                                    
                                </div>
                                </div>
                            </div>
                            <div class="form-group col-md-2 col-lg-2 col-sm-12 mx-auto">
                                    {!isSubmiting && <Button onClick={handleSubmit}>Submit</Button>}
                                    {isSubmiting && <Button >Submit...</Button>}
                                <p className="text-center text-danger fw-bold">{error && error }</p>
                                <p className="text-center text-success fw-bold">{successMessage && successMessage}</p>
                    </div>
                    <h5>All Procedures</h5>
                            {!isLoadingProcedures && <div>
                                
                                <ProceduresTable myProcedures={myProcedures} />
                            </div>}
                            {isLoadingProcedures && <p className="text-center">Loading ..</p>}

                        </div>
                    </div>
                </div>
            </div>
      
}

export default Procedures