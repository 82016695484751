export const reasonsForVisit = [
    {
        id: 1,
        name: 'Work Related',
        subcategories: [
            { id: 1.1, name: 'First Aid' },
            { id: 1.2, name: 'Medical Treatment' },
             { id: 1.3, name: 'Restricted Work Case (RWC)' },
            { id: 1.4, name: 'Lost Time Injury' }
        ]
    },
    { id: 2, name: 'Nonwork Related' },
    { id: 3, name: 'Referrals' }
];