const ViewDrugComponent = ({drug,brands}) =>{


    console.log(brands)
    return  <>
    <div class="nk-block-head">
            <div class="nk-block-head-content">
                <h3 class="nk-block-title page-title">Drug Information: <strong class="text-primary small"> {drug.name}  </strong></h3>
            </div>
        </div>
        <div class="nk-block nk-block-lg">
            <div class="card">
                <div class="card-aside-wrap">
                    <div class="card-content">
                        <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" data-bs-toggle="tab" href="#tabItem1" aria-selected="true" role="tab"><em class="icon ni ni-user-circle-fill"></em><span>Drug information</span></a>
                            </li>
                        </ul>
                        <div class="card-inner">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tabItem1" role="tabpanel">
                                <div>
                                    <h2>Drug Name:  {drug.name}</h2>
                                   
                                    {brands && <div>
                                       <h5> Brand Names</h5>
                                            {brands.map((brand) => (
                                                <div key={brand.data.id}>
                                                <p className="mt-3 mb-3"><b> {brand.data.name} </b></p>
                                                {brand.batches.length> 0 && <table border="1" className="table table">
                                                    <thead>
                                                    <tr>
                                                        <th>Batch ID</th>
                                                        <th>Supplier ID</th>
                                                        <th>Supplier Name</th>
                                                        <th>Quantity Received</th>
                                                        <th>LPO</th>
                                                        <th>Buying Price</th>
                                                        <th>Selling Price</th>
                                                        <th>Pack Size ID</th>
                                                        <th>Unit</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    { brand.batches.map((batch) => (
                                                        <tr key={batch.id}>
                                                        <td>{batch.id}</td>
                                                        <td>{batch.supplier.id}</td>
                                                        <td>{batch.supplier.name}</td>
                                                        <td>{batch.quantity_received}</td>
                                                        <td>{batch.lpo}</td>
                                                        <td>{batch.buying_price}</td>
                                                        <td>{batch.selling_price}</td>
                                                        <td>{batch.pack_size.name}</td>
                                                        <td>{batch.unit_of_measure.name}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>}
                                                </div>
                                            ))}
                                        </div>}

                                    
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
</>
}

export default ViewDrugComponent;