import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import ListBatchesComponent from "../../../components/batches/ListBatchesComponent";

const ListBatches = () => {


    const [listOriginalBatches,setListOriginalBatches] = useState([])
    const [listBatchesData, setListBatchesData] = useState([])

    const [totalCount,setTotalCount] = useState(0)
    const [pageNo,setPageNo] = useState(1)
	const [limit, setLimit] = useState(5);
    const [search, setSearch] = useState("")
    const [suppliers,setSuppliers] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false)
    
    const handlePageClick = (data) => {
        setPageNo(data.selected+1)
    }

    const loadFreshBatchData = () => {
        fetchBatches(pageNo)
    }

    const searchHandler = async (e) =>{
        let search = e.target.value;
         if(search.trim() === ""){
            setListBatchesData(listOriginalBatches)
            return;
         }else{
            //search form the existing batch
            let filteredBatches = listBatchesData.filter(batch => 
                batch.brand.name.toLowerCase().includes(search.toLowerCase()) ||
                batch.supplier.name.toLowerCase().includes(search.toLowerCase())
            );
            setListBatchesData(filteredBatches);
         }
     }
    

    const fetchBatches = async (pageNo = 1) => {
        let url = `batches/list`;

        let data = {
			pageNo: pageNo,
			limit: limit
        }


         try {
            const response = await apiClient.post(url, data)
            if (response.status === 200) {
                setListOriginalBatches(response.data.data.data)
                setListBatchesData(response.data.data.data)
                setTotalCount(response.data.totalCount)
            } else {
                console.log("something happend", response)
            }
        } catch (error) {
            console.log("error",error)
        }        
    }

    
    useEffect(() => {
		fetchBatches(pageNo);
    }, [pageNo])

    
    return <>
    <div class="nk-block-head nk-block-head-sm">
			<div class="nk-block-between">
				<div class="nk-block-head-content">
					<h3 class="nk-block-title page-title"> Batches</h3>
					
					<div class="nk-block-des text-soft"><p>You have total {totalCount} Batches</p></div>
					
				</div>
				<div class="nk-block-head-content">
					{/* <a href="/demo7/hospital/patient-add.html" class="btn btn-icon btn-primary"><em class="icon ni ni-plus"></em></a> */}
					<NavLink to="/inventory/batch/add-batch" class="btn btn-primary d-none d-md-inline-flex"><em class="icon ni ni-plus "></em><span cla>Add Batch</span></NavLink>
				</div>
			</div>
    </div>
    {/*  */}
    <div class="nk-block">
        <div class="card card-bordered card-stretch">
            <div class="card-inner-group">
                <div class="card-inner position-relative card-tools-toggle">
                    <div class="card-title-group">
                        <div class="card-tools">
                            <div class="form-inline flex-nowrap gx-3">
                                <input type="search" onChange={searchHandler} placeholder="Search Batch" class="form-control" style={{width: "280px;"}} /></div>
                        </div>
                        <div class="card-tools me-n1">
                            <ul class="btn-toolbar gx-1">
                                <li class="btn-toolbar-sep"></li>
                                <li>
                                    <div class="toggle-wrap">
                                        <a href="#" class="btn btn-icon btn-trigger toggle" data-target="cardTools"><em class="icon ni ni-menu-right"></em></a>
                                        <div class="toggle-content" data-content="cardTools">
                                            {/* <ul class="btn-toolbar gx-1">
                                                <li>
                                                    <div class="dropdown">
                                                        <a href="#" class="btn btn-trigger btn-icon dropdown-toggle" data-bs-toggle="dropdown"><em class="icon ni ni-setting"></em></a>
                                                        <div class="dropdown-menu dropdown-menu-xs dropdown-menu-end">
                                                            <ul class="link-check">
                                                                <li><span>Show</span></li>
                                                                <li class="active"><a>10</a></li>
                                                                <li class=""><a>15</a></li>
                                                                <li class=""><a>20</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-search search-wrap" data-search="search">
                        <div class="card-body">
                            <div class="search-content">
                                <a href="#" class="search-back btn btn-icon toggle-search" data-target="search"><em class="icon ni ni-arrow-left"></em></a>
                                <input type="text" class="form-control border-transparent form-focus-none" placeholder="Search by name or patient id" /><button class="search-submit btn btn-icon"><em class="icon ni ni-search"></em></button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <ListBatchesComponent listBatches={listBatchesData} refetchBatch={ loadFreshBatchData} />
                
                    <div className="mt-3">
                        {totalCount > limit &&
                            <div className="mt-3 mb-2">
                                <ReactPaginate
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    pageCount={(totalCount / limit)}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                /> 
                            </div>
                        }
                    </div>
                    </div>
                </div>
            </div>
    {/*  */}



    </>
}

export default ListBatches;