import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import apiClient from "../../utils/axios";
import { useAuth } from "../../store/AuthContext";

const ListBatchesComponent = ({listBatches , refetchBatch}) => {

    const [totalCount,setTotalCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
	const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false)
    const [batch,setBatch] = useState({})
    
    const modalRef = useRef(null);
    const { settings} = useAuth()

    const deleteModaHandler = (data) => {
        setOpenModal(true)
		setBatch(data)	
    }
    const closeModal = () => {
		setOpenModal(false)
		setBatch({})	
    }
    
    const removeBatchHandler = async (id) => {
        let url = `batches/delete/${id}`;
       try {
           const response = await apiClient.post(url)
           console.log("resrponse after : ", response)
		   if (response.status === 204) {
			
            setOpenModal(false)
            setSuccessMessage("Batch deleted successfully")
            setTimeout(() => {
                setSuccessMessage("")
            }, 3000);
                refetchBatch()
            }
      } catch (error) {
        console.log(error)
      }
    }

    const isShowMenu = (menu) =>{
        const setting = settings.find(setting => setting.slug === menu)
        return setting && setting.value !== 0 ? setting.value : ""
    }    

   
    // this is used to open the delete modal for confirmation
	useEffect(() => {
		if (openModal && modalRef.current) {
			const modal = new window.bootstrap.Modal(modalRef.current);
			modal.show();
			return () => modal.hide();
		}
		
    }, [openModal, batch]);
    

    return <>
        <div class="card-inner p-0 table-responsive">
            <div class="text-center">
                <div class="mb-3"></div>
                <div class="mb-3"></div>
                <div></div>
            </div>
            <div class="nk-tb-list nk-tb-ulist">
                <div class="nk-tb-item nk-tb-head">
                

            <div class="nk-tb-col tb-col-sm"><span class="sub-text fw-bold">Brand Name</span></div>
                    {isShowMenu('enable-suppliers-menu') && <div class="nk-tb-col tb-col-sm"><span class="sub-text fw-bold">Supplier</span></div>}
                    <div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Quantity Received</span></div>
                    {/* <div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Quantity Available</span></div> */}
                    <div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Buying Price</span></div>
                    <div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Selling Price</span></div>
                    
                    <div class="nk-tb-col nk-tb-col-tools">
                        <ul class="nk-tb-actions gx-1 my-n1">
                            <div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Actions</span></div>
                        </ul>
                    </div>
                </div>
        
            {listBatches.length > 0 && listBatches.map((batch) => {
                return <div class="nk-tb-item" key={batch.id}>
            
                    <div class="nk-tb-col tb-col-sm">
                        <span class="fw-medium">{batch.brand.name}</span>
                    </div>
                    {isShowMenu('enable-suppliers-menu') && <div class="nk-tb-col tb-col-sm">
                        <span class="fw-medium">{batch?.supplier?.name}</span>
                    </div>}
                    <div class="nk-tb-col tb-col-sm">
                        <span class="fw-medium">{batch.quantity_received}</span>
                    </div>
                    
                    <div class="nk-tb-col tb-col-sm">
                        <span class="fw-medium">{batch.buying_price}</span>
                    </div>
                        <div class="nk-tb-col tb-col-sm">
                        <span class="fw-medium">{batch.selling_price}</span>
                    </div>

                    <div class="nk-tb-col nk-tb-col-tools">
                        <ul class="nk-tb-actions gx-1">
                        
                            <li>
                                <div class="drodown">
                                    <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                    <div class="dropdown-menu dropdown-menu-end">
                                        <ul class="link-list-opt no-bdr" style={{ cursor: 'pointer' }}>
                                            <li>
                                                <NavLink to={`/inventory/batches/${batch.id}`} >
                                                    <em class="icon ni ni-eye"></em><span>View Details</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/inventory/batches/Edit/${batch.id}`}><em class="icon ni ni-edit"></em><span>Edit</span></NavLink>
                                            </li>
                                            <li>
                                                <a onClick={() => deleteModaHandler(batch)}><em class="icon ni ni-trash" ></em><span>Delete </span>
                                                </a>
                                            </li>
                                        
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            })}
            </div>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modalRef}>
				<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
					<h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
					<button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
					</div>
					<div className="modal-body">
					<p><strong>Are you sure You want to delete this batch</strong></p>
					</div>
					<div className="modal-footer">
					<button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
					<button type="button" className="btn btn-danger"  onClick={()=>removeBatchHandler(batch.id)} >Confirm Delete</button>
					</div>
				</div>
				</div>
        </div>
        
    </>
}

export default ListBatchesComponent;