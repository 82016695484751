import React, { useContext, useState } from "react"
import Cookies from 'js-cookie';
import apiClient from "../utils/axios";
import { useNavigate } from "react-router-dom";


const AuthContext = React.createContext()


export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [authUser, setAuthUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [settings,setSettings] =useState([])

    const navigate = useNavigate()


    const fetchRolesAndPermissions = async () =>{
        const authToken = Cookies.get('authToken')
        if(authToken){
            try {
                const response = await apiClient.get(`roles-permissions/get-user-permissions`);

                const rolesAndPermissions = response.data.rolesAndPermissions
                const roles = rolesAndPermissions.flatMap(role => role.role);
                const permissions = rolesAndPermissions.flatMap(role => role.permissions);
                // Cookies.set('roles',response.data.role)
                setRoles(roles);
                setPermissions(permissions);
            } catch (error) {
                
            }
        }
    }
    const fetchSystemSettings = async (value = false) =>{
        const response  = await apiClient.get(`settings`)
        if(response.status){
            setSettings(response.data.data)
            Cookies.set('system-settings', JSON.stringify(response.data.data));
        }
    }
    
    const isUserLoggedInHandler = (data)=>{
        navigate('/')
        setAuthUser(data.user)
        Cookies.set('authToken', data.token);
        setIsLoggedIn(true)
        fetchRolesAndPermissions()
        fetchSystemSettings()
    }


    const logOutUserHandler = async () => {
        Cookies.remove('authToken'); 
        setAuthUser(null)
        setIsLoggedIn(false)
        navigate('/')
        
    }

    const value = {
        authUser,
        isUserLoggedInHandler,
        isLoggedIn,
        logOutUserHandler,
        roles,
        permissions,
        fetchRolesAndPermissions,
        settings,
        fetchSystemSettings
    }
    
    return (
        <AuthContext.Provider value={value}>{ children }</AuthContext.Provider>
    )
}