// BarChartComponent.jsx
import React, { forwardRef } from 'react';
import { Bar } from 'react-chartjs-2';

import './BarChartComponent.css';


const BarChartComponent = forwardRef(({ data, label }, ref) => {

  const generateRandomColors = (numColors) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    colors.push(`rgba(${r}, ${g}, ${b}, 0.2)`);
  }
  return colors;
};

const backgroundColors = generateRandomColors(data.length);
const borderColors = backgroundColors.map(color => color.replace('0.2', '1'));

  

  const chartData = {
    labels:label, //can be deseases
    datasets: [
      {
        label: '08th March to 14th March 2024',
        data: data,
        backgroundColor: 'red',
        borderColor: 'red' ,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      datalabels: {
        color: '#000', // Font color (black)
        font: {
          weight: 'bold', // Font weight (bold)
          size:20
        },
        anchor: 'end', // Align labels to the end of the bar
        align: 'top', 
      },
      // legend: {
      //   display: false, // Hide legend
      // },
      // tooltip: {
      //   enabled: true, // Enable tooltips
      // },
    },
    scales: {
      x: {
        ticks: {
          color: '#000', // X-axis label font color (black)
          font: {
            weight: 'bold', // X-axis label font weight (bold)
            size: 15, // X-axis label font size in pixels
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: '#000', // Y-axis label font color (black)
          font: {
            weight: 'bold', // Y-axis label font weight (bold)
            size: 15, // Y-axis label font size in pixels
          },
        },
      },
      
    },
  };
  

  return (
    <div>
      <Bar ref={ref}  data={chartData} options={chartOptions} />
    </div>
  );
});

export default BarChartComponent;
