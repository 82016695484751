import { useEffect, useState } from "react";
import './Settings.css'
import ManageRoles from "./ManageRoles";
import ManageUsers from "./ManageUsers";
import SystemSettings from "./SystemSettings";
import ManagePermissions from "./ManagePermissions";
import { useAuth } from "../../store/AuthContext";
import { ROLES } from "../../constants/rolesPermissions";


const Settings = () =>{

  const {role} = useAuth()


  const [activeItem, setActiveItem] = useState('');

  const setActiveHandler = (itemName) => {
      setActiveItem(itemName === activeItem ? null : itemName);
  };

  let component = <h1>Welcome to the System Setup Page</h1>

  if(activeItem === 'manage-roles'){
         component = <ManageRoles/>
  }
  if (activeItem === 'manage-permissions') {
    component = <ManagePermissions/>
  }
  if(activeItem === 'manage-users'){
        component = <ManageUsers/>
  }
  if(activeItem === 'manage-settings'){
        component = <SystemSettings/>
  }

  const loadSettings = <>
      <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
      
      <div class="collapse navbar-collapse " id="navbarNavDropdown">
        <ul class="navbar-nav ">

          <li class={`nav-item ${activeItem === 'manage-roles' ? 'active bg-primary' : ''}`} onClick={()=>setActiveHandler('manage-roles')}>
            <span class="nav-link fw-bold"  >Roles </span>
          </li>
          <li class={`nav-item ${activeItem === 'manage-permissions' ? 'active bg-primary' : ''}`} onClick={()=>setActiveHandler('manage-permissions')}>
            <span class="nav-link fw-bold">Permissions</span>
          </li>
          <li class={`nav-item ${activeItem === 'manage-users' ? 'active bg-primary' : ''}`} onClick={()=>setActiveHandler('manage-users')}>
            <span class="nav-link fw-bold" >Users </span>
          </li>
            
          <li class={`nav-item ${activeItem === 'manage-settings' ? 'active bg-primary' : ''}`} onClick={()=>setActiveHandler('manage-settings')}>
            <span class="nav-link fw-bold" >System Settings</span>
          </li>
        
        </ul>
      </div>
    </nav>
  <div className="mt-3">
      {component}
  </div>
  </>

  
  return <>
    {loadSettings}
{/* {role.name == ROLES.SUPER_ADMIN ?
 (<> {loadSettings}</>) : <p>Not authorised</p> } */}
</>
}
export default Settings;