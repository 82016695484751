import MainPage from './layouts/main';
import './App.css'

function App() {
  return (
    <MainPage />
  );
}

export default App;
