import { useState, useEffect } from "react"
import apiClient from "../../utils/axios"
import RolePermissions from "../../components/settings/RolePermissions"
import { ROLES } from "../../constants/rolesPermissions"

const ManageRoles = () => {

    const [name, setName] = useState("")
    const [roleId, setRoleId] = useState("")

    const [roles, setRoles] = useState([])
    const [error, setError] = useState("")

    const [isEdit, setIsEdit] = useState(false)
    const [isViewPermission, setIsViewPermission] = useState(false)
    const [message,setMessage] = useState("")
    const allRoles = ROLES;


    const getRoles = async () => {
        try {
            const response = await apiClient.get('roles');
            if (response.status === 200 && response.data.status) {
                setRoles(response.data.roles)
            } else {
                console.log('something happened')
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    
   
    const addRoleHandler = async () => {
        //validate email

        if (!isEdit) {
            let data = {
                name: name
            }
            //add new role
            try {
                const response = await apiClient.post(`roles`, data);
                if (response.status === 200 && response.data.status) {
                    getRoles()
                } else {
                    setError(response.data.message)
                }
            } catch (error) {
                console.log("error", error)
            }

        } else {
            let data = {
                name: name,
                role_id: roleId
            }
            //update user here
            try {
                const response = await apiClient.post(`roles/update`, data);
                if (response.status === 200 && response.data.status) {
                    getRoles()
                    setIsEdit(false)
                } else {
                    setError(response.data.message)
                }
            } catch (error) {
                console.log("error", error)
            }
        }
       
    }

    const addRole = () => {
        setRoleId("")
        setName("")
        setIsEdit(false)
    }



    const handleEditRole = async (roleId) => {
        setIsEdit(true)
        setRoleId(roleId)
        let role = roles.find(role => role.id === roleId)
        setName(role.name)
    }
    const handleDeleteRole = async (roleId) => {

        let isConfirmed = window.confirm("A you sure you want to delete")
        if (isConfirmed) {
            try {
                const response = await apiClient.post(`roles/delete`, { role_id: roleId });
                if (response.status === 200) {
                    getRoles()
                } else {
                    console.log('Something happened.Failed to delete the role.');
                }
            } catch (error) {
                console.error('There was an error deleting the role!', error);
            }
        }
    }

    const updateRoleHandler = async () => {

        let data = {
            roles:allRoles
        } 

        try {
            const response = await apiClient.post(`roles-permissions/update-roles`, data)
            if (response.status === 200) {
                getRoles()
                setMessage("Successfully updated roles")
                setTimeout(() => {
                   setMessage("") 
                }, 3000);
            }
        } catch (error) {
            
        }
    }

  

    const viewPermission = (roleId) => {
        let role = roles.find(role => role.id === roleId)
        setRoleId(roleId)
        setName(role.name)
        setIsViewPermission(true)
        
    }

    useEffect(() => {
        getRoles()
    }, [])



    return <div className="row">
        <div className="col-md-4">
            {/* <div className="mb-3">
                {!isEdit ? <h5>Create Role</h5> :
                    <div className="d-flex justify-content-between">
                        <div><h5>Edit Role:  {name} </h5></div>
                        <div><span className="btn btn-primary" onClick={addRole}>Add Role</span></div>
                    </div>
                }
            </div>

            <div class="form-group">
                <label for="exampleInputEmail1">Role Name</label>
                <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Enter Role"
                    value={name}
                    onChange={(e) => setName(e.target.value)} />
            </div>
            <Button class="btn btn-primary" onClick={addRoleHandler}>{!isEdit ? 'Submit' : 'Update'}</Button>
            <span className="text-danger">{error !== "" && error}</span> */}

            <h5>All Roles</h5>
            <ul>
             {Object.entries(allRoles).map(([key, value]) => (
                 <li className="p-1" key={key}><b>{key }</b>   :   {value} </li>
            ))}
            </ul>
            <button className="btn btn-primary mt-2" onClick={updateRoleHandler}>Update Roles</button>
            <span className="text-success m-4" ><b>{ message && message}</b></span>
            
        </div>
        <div className="col-md-6">
            <h5>Roles</h5>
            {roles && roles.length && <table className="table table-striped">
                <thead>
                    <tr>
                        <td>Name</td>
                        {/* <th>Permissions</th> */}
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                
                    {roles.map(role => (
                        <tr>
                            <td>{role.name}</td>
                         
                            <td className="d-flex gap-1 ">
                                {/* <button className="btn btn-primary p-2" onClick={() => handleEditRole(role.id)}>Edit</button> */}
                                {/* <button className="btn btn-danger p-2" onClick={() => handleDeleteRole(role.id)}>Delete</button> */}
                                <button className="btn btn-primary p-3" onClick={() => viewPermission(role.id)}>View Permissions</button>
                            
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
        </div>
        
       
        
        {isViewPermission && <div className="mb-3">
            <div className="mb-3"><h3>Permissions For {name}</h3></div>
             <RolePermissions roleId={roleId} />
        </div>
}
        
</div>
}
export default ManageRoles;