import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";
import Button from "../../../reusables/Button";

const MeasurementsSetUp = () => {
    const [packSizes, setPackSizes] = useState([])
    const [units, setUnits] = useState([])
    const [packsizeName, setPackSizeName] = useState("")
    const [unitName, setUnitName] = useState("")
    
    const [isLoadingPackSize, setIsLoadingPackSize] = useState(false)
    const [isLoadingUnit, setIsLoadingUnit] = useState(false)
    
    const [message, setMessage] = useState("")

    const [isShowPackSize, setIsShowPackSize] = useState(false)
    const[isShowUnit,setIsShowUnit] = useState(false)
    

    const getPackSizes = async () => {
        setIsLoadingPackSize(true)
        const response = await apiClient.get('pack-sizes')
        if (response.status === 200) {
            setIsLoadingPackSize(false)
            setPackSizes(response.data)
        } else {
            console.log("error")
        }
    }

    const handlePackSizes = async (e) => {
        e.preventDefault();
        setIsLoadingPackSize(true)
        let data = {
            name:packsizeName
        }
        const response = await apiClient.post('pack-sizes', data)
        console.log("response",response)
        if (response.status === 201) {
            setIsShowPackSize(false)
            getPackSizes()
        } else {
            console.log("error")
        }
    }



    const getUnits = async () => {
        setIsLoadingUnit(true)
        const response = await apiClient.get('units-of-measure')
        if (response.status === 200) {
            setUnits(response.data)
            setIsLoadingUnit(false)
        } else {
            console.log("error")
        }
    }
    

    const handleUnit = async (e) => {
        e.preventDefault();
        
        setIsLoadingUnit(true)
        let data = {
            name:unitName
        }
        const response = await apiClient.post('units-of-measure',data)
        if (response.status ===  201) {
            setIsShowUnit(false)
            getUnits()
        } else {
            console.log("error")
        }
    }


    const tablePackSizes = !isLoadingPackSize ? <div className="table-responsive">
        
                    <table className="table table-stripped ">
                    <thead>
                        <td>Pack Size Name</td>
                    </thead>
                    <tbody>

                        {packSizes.map(packSize => (
                            <tr key={packSize.id}>
                                <td>{ packSize.name}</td>
                            </tr>
                        ))}
                        
                    </tbody>
                </table>
            </div> : <p>Loading...</p>
  
    const createPackSize = <div className="create-packsize">
            <div className="col-md-12">
                <input type="text" className="form-control" placeholder="Pack size name"
                    value={packsizeName}
                    onChange={(e)=>setPackSizeName(e.target.value)}
                />
            </div>
            <div className="col-md-3">
            {!isLoadingPackSize && <Button onClick={handlePackSizes}>Submit</Button>}
            {isLoadingPackSize && <Button >...</Button>}
            </div>
    </div>

    // units of measure
    const unitsTableData = !isLoadingUnit ? <div className="table-responsive">
                    <table className="table table-stripped ">
                        <thead>
                            <td>Unit Name</td>
                        </thead>
                    <tbody>
                            {units.map(unit => (
                                <tr>
                                    <td>{ unit.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> : <p>Loading...</p>

    const createUnit = <div className="create-packsize">
            <div className="col-md-12">
                <input type="text" className="form-control" placeholder="Enter Unit"
                    value={unitName}
                    onChange={(e)=>setUnitName(e.target.value)}
                />
            </div>
            <div className="col-md-3">
            {!isLoadingUnit && <Button onClick={handleUnit}>Submit</Button>}
            {isLoadingUnit && <Button >...</Button>}
            </div>
    </div>
    


  


    useEffect(() => {
        getPackSizes()
        getUnits()
    }, [])

    return (
        <div class="row mt-4">
            <div className="col-md-6 col-sm-12">
                <h5>Pack Sizes</h5>
                <div className="d-flex justify-content-between gap-2">
                    <div></div>
                    {!isShowPackSize && <div className="btn btn-primary p-2" onClick={() => setIsShowPackSize(true)}>Add Pack size</div>}
                    {isShowPackSize && <div className="btn btn-primary" onClick={() => setIsShowPackSize(false)}>Go Back</div>}
                </div>
                <div className="mt-3">
                    {isShowPackSize && createPackSize }
                    {!isShowPackSize && tablePackSizes}
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="d-flex justify-content-between gap-2">
                    <div></div>
                    {!isShowUnit && <div className="btn btn-primary p-2" onClick={() => setIsShowUnit(true)}>Add Unit </div>}
                    {isShowUnit && <div className="btn btn-primary" onClick={() => setIsShowUnit(false)}>Go Back</div>}
                </div>
                
                <div className="mt-3">
                    {isShowUnit && createUnit }
                    {!isShowUnit && unitsTableData}
                </div>
                 
            </div>

        </div>
    );
}
export default MeasurementsSetUp;