import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import apiClient from "../../utils/axios";

const ListDrugsComponent = ({drugs , refetchBatch}) => {

    const [totalCount,setTotalCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
	const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false)
    const [drug,setDrug] = useState({})
    

    
    const modalRef = useRef(null);

    const deleteModaHandler = (data) => {
        setOpenModal(true)
		setDrug(data)	
    }
    const closeModal = () => {
		setOpenModal(false)
		setDrug({})	
    }
    
    const removeDrugHandler = async (id) => {
        let url = `drugs/delete/${id}`;
        console.log("delete",url)
       try {
           const response = await apiClient.post(url)
           console.log("resrponse", response)
		  if (response.status === 204) {
			
          setOpenModal(false)
          setSuccessMessage("Batch deleted successfully")
          setTimeout(() => {
            setSuccessMessage("")
          }, 3000);
            refetchBatch()
        }
      } catch (error) {
        console.log(error)
      }
    }

   
    // this is used to open the delete modal for confirmation
	useEffect(() => {
		if (openModal && modalRef.current) {
			const modal = new window.bootstrap.Modal(modalRef.current);
			modal.show();
			return () => modal.hide();
		}
		
    }, [openModal, drug]);
    

    return <>
        <div class="card-inner p-0 table-responsive">
            <div class="text-center">
                <div class="mb-3"></div>
                <div class="mb-3"></div>
                <div></div>
            </div>
            <div class="nk-tb-list nk-tb-ulist">
                <div class="nk-tb-item nk-tb-head">
                {/* <div class="nk-tb-col"><span class="sub-text fw-bold">ID</span></div> */}
                    <div class="nk-tb-col"><span class="sub-text fw-bold">Drug Name</span></div>
                     
                
                    
                    <div class="nk-tb-col nk-tb-col-tools">
                        <ul class="nk-tb-actions gx-1 my-n1">
                            <div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Actions</span></div>
                    
                        </ul>
                    </div>
                </div>
        
            {drugs.length > 0 && drugs.map((drug) => {
                return <div class="nk-tb-item" key={drug.id}>
            
                    {/* <div class="nk-tb-col">
                        <NavLink to={`/drugs/${drug.id}`}>
                            <div class="user-card">
                                <div class="user-info"><span class="tb-lead">{drug.id}</span> </div>
                            </div>
                        </NavLink>
                    </div> */}
                    <div class="nk-tb-col tb-col-sm">
                        <span class="fw-medium">{drug.name}</span>
                    </div>
                   
                    <div class="nk-tb-col nk-tb-col-tools">
                        <ul class="nk-tb-actions gx-1">
                        
                            <li>
                                <div class="drodown">
                                    <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                    <div class="dropdown-menu dropdown-menu-end">
                                        <ul class="link-list-opt no-bdr" style={{ cursor: 'pointer' }}>
                                            <li>
                                                <NavLink to={`/inventory/drugs/${drug.id}`} >
                                                    <em class="icon ni ni-eye"></em><span>Views Details</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/inventory/drugs/Edit/${drug.id}`}><em class="icon ni ni-edit"></em><span>Edit</span></NavLink>
                                            </li>
                                            <li>
                                                <a onClick={() => deleteModaHandler(drug)}><em class="icon ni ni-trash" ></em><span>Delete </span>
                                                </a>
                                            </li>
                                        
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            })}
            </div>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modalRef}>
				<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
					<h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
					<button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
					</div>
					<div className="modal-body">
					<p><strong>Are you sure You want to delete this batch</strong></p>
					</div>
					<div className="modal-footer">
					<button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
					<button type="button" className="btn btn-danger"  onClick={()=>removeDrugHandler(drug.id)} >Confirm Delete</button>
					</div>
				</div>
				</div>
        </div>
        
    </>
}

export default ListDrugsComponent;