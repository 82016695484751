import { useState } from "react";
import SickLeave from "./SickLeave";
import ReferralLeave from "./ReferralLeave";
import MedicalEvacuationLeave from "./MedicalEvacuationLeave";

const LeaveIndex = ({patient}) => {


     const [activeTab, setActiveTab] = useState('sickLeave');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'sickLeave':
                return <SickLeave patient={patient}/>
            case 'referral':
                return <ReferralLeave patient={patient}/>
            case 'medevac':
                return <MedicalEvacuationLeave patient={patient} />
            default:
                return null;
        }
    };

    
        return <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'sickLeave' ? 'active' : ''}`}
                            href="#"
                            onClick={() => setActiveTab('sickLeave')}
                        >
                            Sick Leave
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'referral' ? 'active' : ''}`}
                            href="#"
                            onClick={() => setActiveTab('referral')}
                        >
                            Referral
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'medevac' ? 'active' : ''} `}
                            href="#"
                            onClick={() => setActiveTab('medevac')}
                        >
                            Medical evacuation (medevac)
                        </a>
                    </li>
                </ul>
                <div className="tab-content mt-3">
                    {renderTabContent()}
                </div>
        </div>

}

export default LeaveIndex;