import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";
import ListBrandsComponent from "../../../components/brands/ListBrandsComponent";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";

const ListBrands = () => {
    
    const [brands, setBrands] = useState([])
    const [originalBrands, setOriginalBrands] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [limit,setLimit] = useState(5)
    const [pageNo,setPageNo] = useState(1)
    
    
    const listBrands = async (pageNo = 1) => {
        let url = `brands/list`;

        let data = {
            pageNo: pageNo,
            limit:limit
        }
       
        const response = await apiClient.post(url,data)
        if (response.data.status) {
            setBrands(response.data.data.data)
            setOriginalBrands(response.data.data.data)
            setTotalCount(10)
        } else {
            console.log("something happend")
        }
    }

    const handlePageClick = (data) => {
        setPageNo(data.selected+1)
    }

    const loadFreshBrandData = () =>{
        
    }

    const searchHandler = async (e) =>{
        let search = e.target.value;
         if(search.trim() === ""){
            setBrands(originalBrands)
            return;
         }else{
             let data = {
                 search:search
             }
             try {
                 const response  = await apiClient.post('brands/search-brands',data);
                 if(response.status == 200 ){
                    setBrands(response.data.data)
                 }else{
                     console.log("not found",response)
                 }
             } catch (error) {
                 console.log(error)
             }
         }
     }

    useEffect(() => {
        listBrands(pageNo)
    }, [pageNo])
    
    return <>
    <div class="nk-block-head nk-block-head-sm">
			<div class="nk-block-between">
				<div class="nk-block-head-content">
					<h3 class="nk-block-title page-title"> Brands</h3>
					
					<div class="nk-block-des text-soft"><p>You have total {totalCount} Brands</p></div>
					
				</div>
				<div class="nk-block-head-content">
					<a href="/demo7/hospital/patient-add.html" class="btn btn-icon btn-primary d-md-none"><em class="icon ni ni-plus"></em></a>
					<NavLink to="/inventory/brands/add-brand" class="btn btn-primary d-none d-md-inline-flex"><em class="icon ni ni-plus"></em><span>Add Brands</span></NavLink>
				</div>
			</div>
    </div>
    {/*  */}
    <div class="nk-block">
        <div class="card card-bordered card-stretch">
            <div class="card-inner-group">
                <div class="card-inner position-relative card-tools-toggle">
                    <div class="card-title-group">
                        <div class="card-tools">
                            <div class="form-inline flex-nowrap gx-3">
                                <input type="search"
                                 onChange={searchHandler}
                                 placeholder="Search Brands"
                                class="form-control" style={{width: "280px;"}} /></div>
                        </div>
                        <div class="card-tools me-n1">
                            <ul class="btn-toolbar gx-1">
                                <li class="btn-toolbar-sep"></li>
                                <li>
                                    <div class="toggle-wrap">
                                        <a href="#" class="btn btn-icon btn-trigger toggle" data-target="cardTools"><em class="icon ni ni-menu-right"></em></a>
                                        <div class="toggle-content" data-content="cardTools">
                                            <ul class="btn-toolbar gx-1">
                                                <li>
                                                    <div class="dropdown">
                                                        <a href="#" class="btn btn-trigger btn-icon dropdown-toggle" data-bs-toggle="dropdown"><em class="icon ni ni-setting"></em></a>
                                                        <div class="dropdown-menu dropdown-menu-xs dropdown-menu-end">
                                                            <ul class="link-check">
                                                                <li><span>Show</span></li>
                                                                <li class="active"><a>10</a></li>
                                                                <li class=""><a>15</a></li>
                                                                <li class=""><a>20</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-search search-wrap" data-search="search">
                        <div class="card-body">
                            <div class="search-content">
                                <a href="#" class="search-back btn btn-icon toggle-search" data-target="search"><em class="icon ni ni-arrow-left"></em></a>
                                <input type="text" class="form-control border-transparent form-focus-none" placeholder="Search by name or patient id" /><button class="search-submit btn btn-icon"><em class="icon ni ni-search"></em></button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <ListBrandsComponent brands={brands} refetchBrands={ loadFreshBrandData} />
                
                    <div className="mt-3">
                        {totalCount > limit &&
                            <div className="mt-3 mb-2">
                                {/* <CustomPagination  totalCount={totalCount} limit={limit} handlePageClick={handlePageClick} /> */}
                                <ReactPaginate
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    pageCount={(totalCount / limit)}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                /> 
                            </div>
                        }
                    </div>
                    </div>
                </div>
            </div>
    {/*  */}
    </>
}

export default ListBrands;