import { React } from 'react';
import OnOf from '@mui/material/Switch' ;


const Switch = ({checked, onChange}) => {
        
    return (
             <OnOf checked={checked}  onChange={onChange}
            />
   
    );
    
}

export default Switch;