import { NavLink } from "react-router-dom";
import BarChartComponent from "./Barchart";
import PieChart from "./PieChart";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the autoTable plugin
import { useEffect, useRef, useState } from "react";
import Button from '../../../reusables/Button'
import apiClient from "../../../utils/axios";
import { format } from 'date-fns';
import Select from 'react-select';


const MedicalSummaryReport = () => {
    const [workPlaces, setWorkPlaces] = useState([])
    const [tableData,setTableData] = useState([])
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [workPlaceId,setWorkPlaceId] = useState("")
    const [clientName,setClientName] = useState("Lake Turkana Wind Power")
    const [additionalText, setAdditionalText] = useState("")
    const [isPdfGenerated, setIsPdfGenerated] = useState(false)
    const [error,setError] = useState("")
    const barChartRef = useRef(null);
    const pieChartRef = useRef(null);
    const [dataCount,setDataCount] = useState([])
    const [dataLabel,setDataLabel] = useState([])


    const options = workPlaces && workPlaces.map(workPlace => ({
        value: workPlace.id,
        label: workPlace.name
    }));
    
    const handleWorkPlaceChange = (seletedWorkOption) => {
        if (seletedWorkOption && seletedWorkOption.value) {
            const selectedWorkPlace = workPlaces.find(workPlace => workPlace.id === parseInt(seletedWorkOption.value));
            setWorkPlaceId(selectedWorkPlace.id) 
        } else {
            setWorkPlaceId(""); // Handle empty or undefined case
        }
    }


 const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        // setError('');
    };
  
    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        // setError('');
    };



    const formatDate = (start, end) =>{
        const startDate = new Date(start);
        const endDate = new Date(end);

        // Format the dates
        const startFormatted = format(startDate, 'dd MMMM yyyy');
        const endFormatted = format(endDate, 'dd MMMM yyyy');

        return `${startFormatted} to ${endFormatted}`;
    }

    
    const generatePdf = async () => {
        //check for empty values
        if (startDate === "" || endDate === "" || clientName === "") {
            setError("Please fill all the fields")
            return;
        }
        setError("")

        //processsing
        let data = {
            start_date: startDate,
            end_date: endDate,
            work_place_id:workPlaceId
        }

        let url = `reports/report-data`;
        
        setTableData([
            { label: 'Average POB on Site', value1: 257, label2: 'Average POB on Site', value2: 203 },
            { label: 'Total Clinic Visits', value1: 15, label2: 'Total Clinic Visits', value2: 22 },
            { label: 'Medical Treatment Cases', value1: 0, label2: 'Medical Treatment Cases', value2: 0 },
            { label: 'First Aid Cases', value1: 1, label2: 'First Aid Cases', value2: 0 },
            { label: 'Average POB on Site', value1: 257, label2: 'Average POB on Site', value2: 203 },
            { label: 'Medical Treatment Cases', value1: 0, label2: 'Medical Treatment Cases', value2: 0 },
            { label: 'First Aid Cases', value1: 1, label2: 'First Aid Cases', value2: 0 },
            { label: 'Average POB on Site', value1: 257, label2: 'Average POB on Site', value2: 203 },
        ])
        setIsPdfGenerated(true)

        try {
            const response = await apiClient.post(url, data)
            if (response.status === 200) {
                console.log("response",response.data)
                setIsPdfGenerated(true)
                const dataPoints = response.data.data_point_3;
                const counts = dataPoints.map(point => point.count);
                const diagnoses = dataPoints.map(point => point.diagnosis);
                setDataCount(counts)
                setDataLabel(diagnoses)
            }
        } catch (error) {
           console.log(error) 
        }
       
      
    }
    const downLoadHandler = async () => {
        
      
        const pdf = new jsPDF();
        let currentY = 10; // Start at the top of the page
    
        const addPageIfNeeded = (additionalHeight) => {
            if (currentY + additionalHeight > pdf.internal.pageSize.height - 10) {
                pdf.addPage();
                currentY = 10;
            }
        };
    
        // Title and period
        pdf.setFontSize(16);
        pdf.text('WEEKLY MEDICAL SUMMARY REPORT', 10, currentY);
        currentY += 10;
        pdf.setFontSize(12);
        pdf.text(`PERIOD: ${startDate} to ${endDate}`, 10, currentY);
        currentY += 10;
        pdf.text(`CLIENT: ${clientName}`, 10, currentY);
        currentY += 7;


        const titleText = 'CLINIC STATISTICS';
        const textWidth = pdf.getStringUnitWidth(titleText) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const pageWidth = pdf.internal.pageSize.width;
        const xPosition = (pageWidth - textWidth) / 2;
        pdf.text(titleText, xPosition, currentY);
        currentY += 10;
    
        // Client POB
        const sectionStartY = currentY;
        const clientPOB = "LTWP SATELLITE CAMPS SERVICE STAFF – 1,\nLTWP SECURITY – 125,\nLTWP VILLAGE – 76,\nMSH LTWP GUESTS – 10,\nMSH LTWP INTERNS – 0,\nNATIONAL POLICE SERVICE – 45";
        const clientPOBHeight = 10 * clientPOB.split('\n').length;
        const contractorsPOB = "CASUALS – 8,\nMSH CONTRACTORS (KETRACO) – 1,\nRESPONSE-MED – 2,\nCONTRACTORS – 0,\nRXPE,\nROBBS,\nSUMMIT – 4,\nSECO – 152,\nVESTAS – 21,\nCONTRACTOR (FIREFOX) – 15";
        const contractorsPOBHeight = 10 * contractorsPOB.split('\n').length;
        // const sectionHeight = Math.max(clientPOBHeight, contractorsPOBHeight) ; // Additional space for padding
        // console.log("section height", sectionHeight)
        const sectionHeight = 0
        
        // Set background color
        pdf.setFillColor(230, 230, 250); // Light lavender color
        pdf.rect(0, sectionStartY - 5, pageWidth, sectionHeight, 'F'); // Adjust dimensions as needed

    
        // Client POB
        pdf.setFontSize(7);
        pdf.text(clientPOB, 10, currentY);
        pdf.text(contractorsPOB, 110, currentY);
        currentY += Math.max(20, 30) 
    
    
        // Add table
        pdf.autoTable({
            head: [['Description', 'Value', 'label', 'Value']],
            body: tableData.map(item => [item.label, item.value1, item.label2, item.value2]),
            startY: currentY,
            theme: 'striped',
        });
    
        currentY = pdf.lastAutoTable.finalY + 10; // Adjust for the next section
    
        // Additional Text and Charts (adjust accordingly)
        const paragraphText = `${additionalText}`;
        const paragraphLines = pdf.splitTextToSize(paragraphText, 180);
        addPageIfNeeded(paragraphLines.length * 5);
        pdf.text(paragraphLines, 10, currentY);
        currentY += paragraphLines.length * 5;
    
        // Capture bar chart
        const barChartCanvas = barChartRef.current.canvas;
        const barChartImg = await html2canvas(barChartCanvas);
        const barChartImgData = barChartImg.toDataURL('image/png');
        addPageIfNeeded(80 + 10);
        pdf.addImage(barChartImgData, 'PNG', 10, currentY, 180, 80);
        currentY += 80 + 10;
    
        // Capture pie chart
        const pieChartCanvas = pieChartRef.current.canvas;
        const pieChartImg = await html2canvas(pieChartCanvas);
        const pieChartImgData = pieChartImg.toDataURL('image/png');
        addPageIfNeeded(80 + 10);
        pdf.addImage(pieChartImgData, 'PNG', 10, currentY, 80, 80);
    
        pdf.save(`RMSK LTWP Weekly Medical Report ${formatDate(startDate,endDate)}.pdf`);
        setIsPdfGenerated(false)
    };

    const handleSubmit = (e) =>{
        e.preventDefault()
    }

    const getWorkPlaces = async () => {

        const response = await apiClient.get(`work-places`)
        if (response.status === 200 && response.data.data.status) {
            console.log("response",)
            setWorkPlaces(response.data.data.data)
        }
        
    }

    useEffect(() => {
        getWorkPlaces()
    },[])

    

    return <>
        <NavLink className="btn btn-primary" to={`/reports/patients-report`}>Back to Reports</NavLink>
        <div className="pt-2"><strong>Weekly Summary Report</strong></div>
        <div className=" mt-1">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-6">
                        <div className="form-group mb-3">
                            <label htmlFor="startDate">Company Name</label><span className="text-danger">*</span>
                            {/* <input
                                type="text"
                                className="form-control"
                                id="client-name"
                                value={clientName}
                                placeholder="Enter Client Name"
                                onChange={(e)=>setClientName(e.target.value)}
                            /> */}
                            <Select 
                                options={options} 
                                onChange={handleWorkPlaceChange} 
                                placeholder="Search Work Places" 
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="startDate">Start Date</label><span className="text-danger">*</span>
                            <input
                                type="date"
                                className="form-control"
                                id="startDate"
                                value={startDate}
                                onChange={handleStartDateChange}
                            />
                        </div>
                        <div className="form-group mb-3">
                        <label htmlFor="endDate">End Date</label><span className="text-danger">*</span>
                        <input
                            type="date"
                            className="form-control"
                            id="endDate"
                            value={endDate}
                            onChange={handleEndDateChange}
                        />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-6">
                        <div className="form-group mb-3">
                        <label htmlFor="endDate">Additional Text</label>
                        <textarea
                            type="text"
                            className="form-control"
                            id="additionalInfo"
                            value={additionalText}
                                onChange={(e) => setAdditionalText(e.target.value)}
                                placeholder="Type text"
                        />
                        </div>
                    </div>
                </div>
            </form>
        </div>
       
        <div className="col-2">
            <Button onClick={generatePdf}>Generate PDF</Button>
            <span className="text-danger fw-bold">{ error && error}</span>
           
        </div>
        
        {isPdfGenerated && <div className="container " id="my-chart">
            <div className="mb-5">
                <BarChartComponent ref={barChartRef} data={dataCount} label={dataLabel} />
            </div>
            <div className="mt-2 ">
                <PieChart ref={pieChartRef} data={dataCount} label={dataLabel} />
            </div>
        
            <div className="col-2">
                <Button onClick={downLoadHandler}>Download PDF</Button>
                 
            </div>
        </div>}
         
    </>
}
export default MedicalSummaryReport;