import { useEffect, useState } from "react";
import Button from "../../../reusables/Button";
import apiClient from "../../../utils/axios";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import CreateBatch from './CreateBatch';
import { useAuth } from "../../../store/AuthContext";


const EditBatch = () => {
    const { batchId } = useParams()
    
    console.log("batch", batchId)
    const [drugs, setDrugs] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [batch, setBatch] = useState({})
    const [selectedSupplier, setSeletedSupplier] = useState(null)
    const [packSizes, setPackSizes] = useState([])
    const [seletedUnit, setSeletedUnit] = useState(null)
    const [seletedPackSize, setSeletedPackSize] = useState(null)
    const [unitsOfMeasure, setUnitsOfMeasure] = useState([])
    const [brands, setBrands] = useState([])
    const [supplierName, setSupplierName] = useState("")
    const [brandName, setBrandName] = useState("")
    const [unit, setUnit] = useState("")
    const [packSizeName, setPackSizeName] = useState("")

    const [isLoaing,setIsLoading] = useState(false)
    
    const [error,setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const { settings} = useAuth()
    const isShowMenu = (menu) =>{
        const setting = settings.find(setting => setting.slug === menu)
        return setting && setting.value !== 0 ? setting.value : ""
    } 



    const [formData, setFormData] = useState({
        brandId: '', 
        brand: '',
        quantityReceived: '',
        lpo: '',
        buyingPrice: '',
        sellingPrice: '',
        packSize: '',
        unitOfMeasure: '',
    });


    const options = suppliers.map(supplier => ({
        value: supplier.id,
        label: supplier.name
    }));


    const unitsOfMeasureOptions = unitsOfMeasure.map(unit => ({
        value: unit.id,
        label: unit.name
    }));

    const brandOptions = brands.map(brand => ({
        value: brand.id,
        label: brand.name
    }));

    const packSizeOptions = packSizes.map(size => ({
        value: size.id,
        label: size.name
    }));

    const handleUnitOfMeasureChange = (seletedUnitOfMeasure) => {
         
         if (seletedUnitOfMeasure && seletedUnitOfMeasure.value) {
            setSeletedUnit(seletedUnitOfMeasure.value);
        } else {
            setSeletedUnit(null); // Handle empty or undefined case
        }
    }

     const handlePackSizeChange = (selectedPackSize) => {
         
         if (selectedPackSize && selectedPackSize.value) {
            setSeletedPackSize(selectedPackSize.value);
        } else {
            setSeletedPackSize(null); // Handle empty or undefined case
        }
    }



    

    const handleBrandChange = (seletedBrandOption) => {
        if (seletedBrandOption && seletedBrandOption.value) {
            const selectedBrand = brands.find(brand => brand.id === parseInt(seletedBrandOption.value));

             setFormData({
                ...formData,
                brandId: selectedBrand.id
             });
            
        } else {
            setFormData({
                ...formData,
                brandId: ""
             });
        }
    }


    
    const handleSupplierChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            setSeletedSupplier(selectedOption.value);
        } else {
            setSeletedSupplier(null); // Handle empty or undefined case
        }
    };

    
    const handleDrugChange = (seletedDrugOption) => {
        if (seletedDrugOption && seletedDrugOption.value) {
            const selectedDrug = drugs.find(drug => drug.id === parseInt(seletedDrugOption.value));
             setFormData({
                ...formData,
                drugId: selectedDrug.id,
                brand: selectedDrug ? selectedDrug.brand.name : ''
             });
            
        } else {
            setSeletedSupplier(null); // Handle empty or undefined case
        }
    }

  const handleChange = (e) => {
        setFormData({
        ...formData,
        [e.target.name]: e.target.value
        });
    };


    const validateForm = () => {
        const newErrors = {};
        if (!formData.quantityReceived) newErrors.quantityReceived = 'Quantity Received is required';
        if (!formData.lpo) newErrors.lpo = 'LPO is required';
        if (!formData.buyingPrice) newErrors.buyingPrice = 'Buying Price is required';
        if (!formData.sellingPrice) newErrors.sellingPrice = 'Selling Price is required';
        // conditionally show this message based on the settings
        if (isShowMenu('enable-suppliers-menu') && !selectedSupplier) {
            newErrors.selectedSupplier = 'Enter Supplier';
        }

        // if (!selectedSupplier) newErrors.selectedSupplier = 'Enter Supplier';
       
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleUpdate = async (e) => {
        e.preventDefault();
        let url = `batches/update/${batchId}`;
        
        if (!validateForm()) {
            // Submit form data
            console.log('Errors submitted:', errors);
            return;
        } 
        let data = {
            brand_id: formData.brandId,
            quantity_received: formData.quantityReceived,
            quantity_available: formData.quantityAvailable,
            supplier_id:selectedSupplier,
            lpo: formData.lpo,
            buying_price: formData.buyingPrice,
            selling_price: formData.sellingPrice,
            pack_size_id:seletedPackSize,
            unit_of_measure_id: seletedUnit
        }


        try {
            const response = await apiClient.post(url, data);
            if (response.status === 200) {
                navigate('/inventory/batches')
            } else {
                console.log("something happened", response)
            }
        } catch (error) {
            console.log("error",error)
        }
   
  };


   


    const getDrugs = async () => {
        let url = "drugs/list"
        try {
            let data = {
                value:true
            }
             const response = await apiClient.post(url,data)
            if (response.status === 200) {
                setDrugs(response.data)
            }
        } catch (error) {
            console.log(error)
        }
       
    }

    const fetchSuppliers = async () => {
        let url = `suppliers/list`;
        let data  = {
            value:true
        }
        try {
            const response = await apiClient.post(url, data)
            if (response.data.status) {
                setSuppliers(response.data.data)
            } else {
                console.log("something happened", response)
            }
            
        } catch (error) {
            
        }
    }

    
    const getBatchDataWithParamId = async () =>{
        let url = `batches/view/${batchId}`
        try {
            const response = await apiClient.post(url)
            if (response.status === 200) {
                let batchData = response.data;
                setSupplierName(batchData.supplier ? batchData.supplier.name : "")
                setBrandName(batchData.brand ? batchData.brand.name : "")
                setUnit(batchData.unit_of_measure ? batchData.unit_of_measure.name : "")
                setPackSizeName(batchData.pack_size ? batchData.pack_size.name : "")
                
                 setFormData({
                    brandId: batchData.brand.id || '',
                    quantityReceived: batchData.quantity_received || '',
                    lpo: batchData.lpo || '',
                    buyingPrice: batchData.buying_price || '',
                    sellingPrice: batchData.selling_price || '',
                 });
                setSeletedSupplier(batchData.supplier ? batchData.supplier.id : "")
            }else {
                console.error('Failed to fetch supplier data');
            }

        } catch (error) {
            console.log(error)
            setIsLoading(true)
        }finally {
            setIsLoading(false); // Always set isLoading to false in finally block
        }
    }

     const getUnitOfMeasure = async () => {
        let url = `units-of-measure`;
        try {
            const response = await apiClient.get(url)
           setUnitsOfMeasure(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getPackSizes = async () => {
        let url = `pack-sizes`;
        try {
            const response = await apiClient.get(url)
            console.log("pack sizes", response.data)
           setPackSizes(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getBrands = async () => {
        let url = "brands/list"

        let data = {
            value:true
        }
        try {
             const response = await apiClient.post(url,data)
            if (response.data.status) {
                setBrands(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
       
    }

    useEffect(() => {
        getBatchDataWithParamId();
        getBrands()
        getUnitOfMeasure()
        getPackSizes()
        fetchSuppliers()
    },[batchId])
    return <>
        <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                    <div class="nk-block-head-content">
                        <h3 class="nk-block-title page-title">Update Batch </h3>
                        <div class="nk-block-des text-soft"><p>Update Batch information carefully.</p></div>
                    </div>
                    {/* <div class="nk-block-des text-soft float-end"><p class="btn btn-primary">Batches</p></div> */}
                </div>
            </div>
            <div class="nk-block">
                <div class="card card-bordered">
                    <div class="card-inner-group">
                        <div class="card-inner">
                            <div class="nk-block-head">
                                <div class="nk-block-head-content">
                                    <h5 class="title nk-block-title">Update Batch From </h5>
                                    <p>Update Batch infomation like Drug, Supplier etc</p>
                                    <ul>
                                    {isShowMenu('enable-suppliers-menu') && <li><b>Supplier</b> : { supplierName}</li>}
                                        <li><b>Brand</b>: { brandName}</li>
                                        <li><b>PackSize</b>: { packSizeName}</li>
                                        <li><b>Unit</b> :  { unit}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="nk-block">
                            
                                <div class="row gy-4">
                                
                                  
                                {isShowMenu('enable-suppliers-menu') && <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label"  for="supplier">Select Supplier</label>
                                            <div class="form-control-wrap">
                                                <Select 
                                                    options={options} 
                                                    onChange={handleSupplierChange} 
                                                    placeholder="Search for a Supplier..." 
                                                    // isClearable
                                                />
                                            </div>
                                        </div>
                                    </div>}


                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Brand</label>
                                            <div class="form-control-wrap">
                                                <Select 
                                                    options={brandOptions} 
                                                    onChange={handleBrandChange} 
                                                    placeholder="change Brand  " 
                                                />
                                            
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Quantity Received</label>
                                            <div class="form-control-wrap">
                                               <input type="number" class="form-control" name="quantityReceived" value={formData.quantityReceived} onChange={handleChange} />
                                               {errors.quantityReceived && <p className="error text-danger">{errors.quantityReceived}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="LPO">LPO</label>
                                            <div class="form-control-wrap">
                                                <input type="text" class="form-control" name="lpo" value={formData.lpo} onChange={handleChange} />
                                                {errors.lpo && <p className="error text-danger">{errors.lpo}</p>}
                                            </div>
                                        </div>
                                                </div>
                                                
                                                <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Buying Price</label>
                                            <div class="form-control-wrap">
                                                <input type="text" class="form-control" name="buyingPrice" value={formData.buyingPrice} onChange={handleChange} />
                                                 {errors.buyingPrice && <p className="error text-danger">{errors.buyingPrice}</p>}
                                            </div>
                                        </div>
                                                </div>
                                                
                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Selling Price</label>
                                            <div class="form-control-wrap">
                                                <input type="text" class="form-control" name="sellingPrice" value={formData.sellingPrice} onChange={handleChange} />
                                                {errors.sellingPrice && <p className="error text-danger">{errors.sellingPrice}</p>}
                                            </div>
                                        </div>
                                    </div>
                                                


                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Pack Size</label>
                                            <div class="form-control-wrap">
                                                 <Select 
                                                    options={packSizeOptions} 
                                                    onChange={handlePackSizeChange} 
                                                    placeholder="change pack size " 
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                                
                                    <div class="col-xxl-3 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="email">Unit of Measure</label>
                                            <div class="form-control-wrap">
                                                  <Select 
                                                    options={unitsOfMeasureOptions} 
                                                    onChange={handleUnitOfMeasureChange} 
                                                    placeholder="change Unit of Measure " 
                                                />
                                               
                                            </div>
                                        </div>
                                    </div>
                                              
                                </div>
                            </div>
                        </div>
                        <div class="card-inner">
                            <div class="nk-block">
                                <div class="row gy-4">
                                    <div class="col-12">
                                        <div class="form-group col-md-2 col-lg-2 col-sm-12 mx-auto">
                                            <Button onClick={handleUpdate}>Update Batch</Button>
                                            <p className="text-center text-danger fw-bold">{error && error }</p>
                                            <p className="text-center text-success fw-bold">{successMessage && successMessage}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>

}

export default EditBatch;