export const icdcodes = [
    {
        "name": "Type 2 diabetes mellitus without complications",
        "code": "E11.9"
    },
    {
        "name": "Essential (primary) hypertension",
        "code": "I10"
    },
    {
        "name": "Acute upper respiratory infection, unspecified",
        "code": "J06.9"
    },
    {
        "name": "Low back pain",
        "code": "M54.5"
    },
    {
        "name": "Chest pain, unspecified",
        "code": "R07.9"
    },
    {
        "name": "Chronic obstructive pulmonary disease, unspecified",
        "code": "J44.9"
    },
    {
        "name": "Osteoarthritis of knee, unspecified",
        "code": "M17.9"
    },
    {
        "name": "Generalized anxiety disorder",
        "code": "F41.1"
    },
    {
        "name": "Gastro-esophageal reflux disease without esophagitis",
        "code": "K21.9"
    },
    {
        "name": "Atherosclerotic heart disease of native coronary artery without angina pectoris",
        "code": "I25.10"
    },
    {
        "name": "Urinary tract infection, site not specified",
        "code": "N39.0"
    },
    {
        "name": "Unspecified atrial fibrillation",
        "code": "I48.91"
    },
    {
        "name": "Headache",
        "code": "R51"
    },
    {
        "name": "Hyperlipidemia, unspecified",
        "code": "E78.5"
    },
    {
        "name": "Unspecified asthma, uncomplicated",
        "code": "J45.909"
    },
    {
        "name": "Dizziness and giddiness",
        "code": "R42"
    },
    {
        "name": "Myalgia",
        "code": "M79.1"
    },
    {
        "name": "Other fatigue",
        "code": "R53.83"
    },
    {
        "name": "Noninfective gastroenteritis and colitis, unspecified",
        "code": "K52.9"
    },
    {
        "name": "Unspecified abdominal pain",
        "code": "R10.9"
    },
    {
        "name": "Pain in right knee",
        "code": "M25.561"
    },
    {
        "name": "Epigastric pain",
        "code": "R10.13"
    },
    {
        "name": "Migraine, unspecified, not intractable, without status migrainosus",
        "code": "G43.909"
    },
    {
        "name": "Obesity, unspecified",
        "code": "E66.9"
    },
    {
        "name": "Major depressive disorder, single episode, unspecified",
        "code": "F32.9"
    },
    {
        "name": "Cough",
        "code": "R05"
    },
    {
        "name": "Pain in unspecified knee",
        "code": "M25.569"
    },
    {
        "name": "Impacted cerumen, bilateral",
        "code": "H61.23"
    },
    {
        "name": "Constipation, unspecified",
        "code": "K59.00"
    },
    {
        "name": "Acute pharyngitis, unspecified",
        "code": "J02.9"
    },
    {
        "name": "Pneumonia, unspecified organism",
        "code": "J18.9"
    },
    {
        "name": "Bronchitis, not specified as acute or chronic",
        "code": "J40"
    },
    {
        "name": "Diarrhea, unspecified",
        "code": "R19.7"
    },
    {
        "name": "Viral infection, unspecified",
        "code": "B34.9"
    },
    {
        "name": "Obstructive sleep apnea (adult) (pediatric)",
        "code": "G47.33"
    },
    {
        "name": "Abnormal weight loss",
        "code": "R63.4"
    },
    {
        "name": "Cardiac arrhythmia, unspecified",
        "code": "I49.9"
    },
    {
        "name": "Nausea with vomiting, unspecified",
        "code": "R11.2"
    },
    {
        "name": "Adjustment disorder with mixed anxiety and depressed mood",
        "code": "F43.23"
    },
    {
        "name": "Allergic rhinitis, unspecified",
        "code": "J30.9"
    },
    {
        "name": "Acute myocardial infarction, unspecified",
        "code": "I21.9"
    },
    {
        "name": "Edema, unspecified",
        "code": "R60.9"
    },
    {
        "name": "Calculus of kidney",
        "code": "N20.0"
    },
    {
        "name": "Angina pectoris, unspecified",
        "code": "I20.9"
    },
    {
        "name": "Unspecified glaucoma",
        "code": "H40.9"
    },
    {
        "name": "Psoriasis, unspecified",
        "code": "L40.9"
    },
    {
        "name": "Otitis media, unspecified, right ear",
        "code": "H66.91"
    },
    {
        "name": "Cellulitis of left lower limb",
        "code": "L03.116"
    },
    {
        "name": "Hypothyroidism, unspecified",
        "code": "E03.9"
    },
    {
        "name": "Dysuria",
        "code": "R30.0"
    },
    {
        "name": "Acute appendicitis, unspecified",
        "code": "K35.80"
    },
    {
        "name": "Acute bronchospasm",
        "code": "J98.01"
    },
    {
        "name": "Pressure ulcer of unspecified site, stage 3",
        "code": "L89.309"
    },
    {
        "name": "Carpal tunnel syndrome, unspecified upper limb",
        "code": "G56.00"
    },
    {
        "name": "Other fecal abnormalities",
        "code": "R19.5"
    },
    {
        "name": "Chronic pharyngitis",
        "code": "J31.2"
    },
    {
        "name": "Unspecified urinary incontinence",
        "code": "R32"
    },
    {
        "name": "Chronic kidney disease, unspecified",
        "code": "N18.9"
    },
    {
        "name": "Enlarged lymph nodes, unspecified",
        "code": "R59.9"
    },
    {
        "name": "Hyperglycemia, unspecified",
        "code": "R73.9"
    },
    {
        "name": "Cellulitis of right upper limb",
        "code": "L03.113"
    },
    {
        "name": "Streptococcal pharyngitis",
        "code": "J02.0"
    },
    {
        "name": "Heart failure, unspecified",
        "code": "I50.9"
    },
    {
        "name": "Irritable bowel syndrome without diarrhea",
        "code": "K58.9"
    },
    {
        "name": "Cystocele, unspecified",
        "code": "N81.10"
    },
    {
        "name": "Pruritus, unspecified",
        "code": "L29.9"
    },
]