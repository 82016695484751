import { useEffect, useState } from "react";
import apiClient from "../../../utils/axios";
import ViewSupplierComponent from "../../../components/suppliers/ViewSupplierComponent";
import { useParams } from "react-router-dom";

const ViewSupplier = () => {

    
    const { supplierId } = useParams();

    const [supplier, setSupplier] = useState("")
    const [batches,setBatches] = useState([])
    const [isLoading,setIsLoading] = useState(true)

    const fetchSupplier = async () =>{
        let url = `suppliers/view/${supplierId}`
        try {
            const response = await apiClient.post(url)
            console.log("supplier info", response.data)
            if(response.status === 200){
                setSupplier(response.data)
                setBatches(response.data.batches)
            }else {
                console.error('Failed to fetch supplier data');
            }

        } catch (error) {
            console.log(error)
            setIsLoading(true)
        }finally {
            setIsLoading(false); // Always set isLoading to false in finally block
        }
    }

    useEffect(()=>{
        fetchSupplier()
    },[supplierId])

   
    return <>
           {isLoading && <p>loading ...</p>}
           {!isLoading && <ViewSupplierComponent supplier={supplier} batches={batches} />}
        </>

}

export default ViewSupplier;