import { useState,useEffect } from "react";
import SettingsRow from "./SettingsRow";
import Button from "../../../reusables/Button";
import apiClient from "../../../utils/axios";
import { ReactPaginate } from 'react-paginate';
import { ROLES } from "../../../constants/rolesPermissions";

const GeneralSettings = () => {

  const [name, setName] = useState("")
  const [location, setLocation] = useState("")
  const [contact, setContact] = useState("")
  const [email, setEmail] = useState("")
  const [adminName, setAdminName] = useState("")
  const [adminEmail, setAdminEmail] = useState("")
  
  
  
    const [message, setMessage] = useState("")   
    const [hospitals, setHospitals] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const [limit, setLimit] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
  
  

     const validateEmail = (email) => {
        // Basic email regex pattern
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };

    const handleSubmit = async () => {
        if (name.trim() === "" || email.trim() === "" || contact.trim() === "" || adminName.trim() === "" || adminEmail.trim() === "") {
            return;
        }
        if (!validateEmail(email)) {
            // setEmailError('Please enter a valid email');
            return ;
      }
      
       
      let data = {
          name: name,
          email: email,
          contact: contact,
          location: location,
          role: ROLES.ADMIN,
          adminName: adminName,
          adminEmail:adminEmail
        }
        try {
            const response = await apiClient.post('hospitals', data);
            if (response.data.status && response.status === 200) {
              setMessage(response.data.message)
              setTimeout(() => {
                  setMessage("")
              }, 3000);
              fetchHospitals()
              setName("")
              setLocation("")
              setAdminEmail("")
              setAdminName("")
              setContact("")
              setEmail("")
            }
           
        } catch (error) {
            
        }
  }
  const fetchHospitals = async (pageNo = 1) => {
    let data = {
      pageNo: pageNo,
      limit:limit
     }
    const response = await apiClient.get('hospitals', data)
    console.log("hosp data",response.data.data.data )
    if (response.status === 200) {
      setTotalCount(response.data.totalCount)
      setHospitals(response.data.data.data)
    }
  }
  const handlePageClick = (data) => {
    setPageNo(data.selected + 1)
  }
  const checkSubscription = (status) => {
    return status === true ? 'Active' : 'Expired'
  }
  
  useEffect(() => {
    fetchHospitals(pageNo)
  },[pageNo])

    
    return <div class="row">
      <div className="col-sm-12 col-md-4 col-lg-4 ">
         <SettingsRow description="Hospital Name">
              <input type="text" placeholder="Enter Hospital name" className="form-control"  value={name} onChange={(e)=>setName(e.target.value)}/>
            </SettingsRow>
            <hr />
            <SettingsRow description="Email Address">
              <input type="email" placeholder="Enter Email address" className="form-control"  value={email} onChange={(e)=>setEmail(e.target.value)}/>
            </SettingsRow>
            <hr />
            <SettingsRow description="Contact">
              <input type="number" placeholder="Enter contact " className="form-control"  value={contact} onChange={(e)=>setContact(e.target.value)}/>
            </SettingsRow>
        
            <SettingsRow description="Location">
                    <input type="text" placeholder="Enter Location " className="form-control" value={location} onChange={(e)=>setLocation(e.target.value)}/>
        </SettingsRow>
       <hr style={{ border: '1px solid black' }} />
        <SettingsRow description="Admin Name">
                 <input type="text" placeholder="Enter Admin " className="form-control" value={adminName} onChange={(e)=>setAdminName(e.target.value)}/>
        </SettingsRow>
        
        <SettingsRow description="Admin Email">
                 <input type="text" placeholder="Enter Location " className="form-control" value={adminEmail} onChange={(e)=>setAdminEmail(e.target.value)}/>
        </SettingsRow>

        <SettingsRow description="">
            <Button onClick={handleSubmit}>Submit</Button>
        </SettingsRow>
        
           <SettingsRow description="">
                <span className="text-success fw-bold">{ message && message}</span>
      </SettingsRow>
      </div>
      
      
      <div className="col-sm-12 col-md-4  col-lg-8 ">
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <td>Hopspital Name</td>
              <td> Admins </td>
              <td>Location</td>
              <td>Contact</td>
               <td>Date Paid</td>
              <td>Subscription Status</td>
             
            </tr>
          </thead>
          <tbody>
            {hospitals && hospitals.length > 0 && hospitals.map(hospital=><tr>
              <td>{hospital.name}</td>
              <td>{ hospital.admin_name}</td>
              <td>{hospital.location}</td>
              <td>{hospital.contact}</td>
               <td>{hospital.date_paid}</td>
              <td><button className={hospital.subscription_status === true ? 'btn btn-primary btn-sm' : 'btn btn-danger btn-sm'}>{checkSubscription(hospital.subscription_status)}</button></td>
             
            </tr>)}
          </tbody>
        </table>
        
        <div className="mt-3">
						{totalCount > limit &&
							<div className="mt-3 mb-2">
								<ReactPaginate
									previousLabel={"previous"}
									nextLabel={"next"}
									breakLabel={"..."}
									pageCount={(totalCount / limit)}
									marginPagesDisplayed={3}
									pageRangeDisplayed={3}
									onPageChange={handlePageClick}
									containerClassName={"pagination justify-content-center"}
									pageClassName={"page-item"}
									pageLinkClassName={"page-link"}
									previousClassName={"page-item"}
									previousLinkClassName={"page-link"}
									nextClassName={"page-item"}
									nextLinkClassName={"page-link"}
									breakClassName={"page-item"}
									breakLinkClassName={"page-link"}
									activeClassName={"active"}
								/> 
							</div>
						}
					</div>
      </div>

  </div>
  
}



export default GeneralSettings;