const ActivityLogs = () => {
    return <div className="activity-logs">
                <div className="nk-block-head nk-block-head-lg">
                    <div className="nk-block-head-content">
                        <h5 className="nk-block-title">Recent Activity</h5>
                        <div className="nk-block-des">
                            <p>Here is your last 20 login activities log. <span className="text-soft"><em className="icon ni ni-info"></em></span></p>
                        </div>
                    </div>
                </div>
                <div className="nk-block card card-bordered">
                    <table className="table table-ulogs">
                        <thead className="table-light">
                            <tr>
                                <th className="tb-col-os"><span className="overline-title">Browser <span className="d-sm-none">/ IP</span></span></th>
                                <th className="tb-col-ip"><span className="overline-title">IP</span></th>
                                <th className="tb-col-time"><span className="overline-title">Time</span></th>
                                <th className="tb-col-action"><span className="overline-title">&nbsp;</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tb-col-os">Chrome on Window</td>
                                <td className="tb-col-ip"><span className="sub-text">192.149.122.128</span></td>
                                <td className="tb-col-time"><span className="sub-text">11:34 PM</span></td>
                                <td className="tb-col-action"></td>
                            </tr>
                            <tr>
                                <td className="tb-col-os">Mozilla on Window</td>
                                <td className="tb-col-ip"><span className="sub-text">86.188.154.225</span></td>
                                <td className="tb-col-time"><span className="sub-text">Nov 20, 2019 <span className="d-none d-sm-inline-block">10:34 PM</span></span></td>
                                <td className="tb-col-action"><a href="#" className="link-cross me-sm-n1"><em className="icon ni ni-cross"></em></a></td>
                            </tr>
                            <tr>
                                <td className="tb-col-os">Chrome on iMac</td>
                                <td className="tb-col-ip"><span className="sub-text">192.149.122.128</span></td>
                                <td className="tb-col-time"><span className="sub-text">Nov 12, 2019 <span className="d-none d-sm-inline-block">08:56 PM</span></span></td>
                                <td className="tb-col-action"><a href="#" className="link-cross me-sm-n1"><em className="icon ni ni-cross"></em></a></td>
                            </tr>
                            <tr>
                                <td className="tb-col-os">Chrome on Window</td>
                                <td className="tb-col-ip"><span className="sub-text">192.149.122.128</span></td>
                                <td className="tb-col-time"><span className="sub-text">Nov 03, 2019 <span className="d-none d-sm-inline-block">04:29 PM</span></span></td>
                                <td className="tb-col-action"><a href="#" className="link-cross me-sm-n1"><em className="icon ni ni-cross"></em></a></td>
                            </tr>
                            <tr>
                                <td className="tb-col-os">Mozilla on Window</td>
                                <td className="tb-col-ip"><span className="sub-text">86.188.154.225</span></td>
                                <td className="tb-col-time"><span className="sub-text">Oct 29, 2019 <span className="d-none d-sm-inline-block">09:38 AM</span></span></td>
                                <td className="tb-col-action"><a href="#" className="link-cross me-sm-n1"><em className="icon ni ni-cross"></em></a></td>
                            </tr>
                            <tr>
                                <td className="tb-col-os">Chrome on iMac</td>
                                <td className="tb-col-ip"><span className="sub-text">192.149.122.128</span></td>
                                <td className="tb-col-time"><span className="sub-text">Oct 23, 2019 <span className="d-none d-sm-inline-block">04:16 PM</span></span></td>
                                <td className="tb-col-action"><a href="#" className="link-cross me-sm-n1"><em className="icon ni ni-cross"></em></a></td>
                            </tr>
                            <tr>
                                <td className="tb-col-os">Chrome on Window</td>
                                <td className="tb-col-ip"><span className="sub-text">192.149.122.128</span></td>
                                <td className="tb-col-time"><span className="sub-text">Oct 15, 2019 <span className="d-none d-sm-inline-block">11:41 PM</span></span></td>
                                <td className="tb-col-action"><a href="#" className="link-cross me-sm-n1"><em className="icon ni ni-cross"></em></a></td>
                            </tr>
                            <tr>
                                <td className="tb-col-os">Mozilla on Window</td>
                                <td className="tb-col-ip"><span className="sub-text">86.188.154.225</span></td>
                                <td className="tb-col-time"><span className="sub-text">Oct 13, 2019 <span className="d-none d-sm-inline-block">05:43 AM</span></span></td>
                                <td className="tb-col-action"><a href="#" className="link-cross me-sm-n1"><em className="icon ni ni-cross"></em></a></td>
                            </tr>
                            <tr>
                                <td className="tb-col-os">Chrome on iMac</td>
                                <td className="tb-col-ip"><span className="sub-text">192.149.122.128</span></td>
                                <td className="tb-col-time"><span className="sub-text">Oct 03, 2019 <span className="d-none d-sm-inline-block">04:12 AM</span></span></td>
                                <td className="tb-col-action"><a href="#" className="link-cross me-sm-n1"><em className="icon ni ni-cross"></em></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
}
export default ActivityLogs;