import { useEffect, useState } from "react";
// import apiClient from "../utils/axios";
// import ReactPaginate from "react-paginate";

const Allprescriptions = ({prescriptions,count,isLoading}) =>{

return <>
            <h4>All Prescription : {count}  </h4>
            {prescriptions && prescriptions.length  ? <div className="card"><table className='table table-hover table table-striped table-hover'>
                <thead style={{fontSize: 'large',fontWeight: 'bold'}}>
                    <tr>
                        <td>Brand Name</td>
                        <td>Drug Name</td>
                        <td>Number Dispensed</td>
                        <td>Dosage</td>
                        <td>Date Dispensed</td>
                    </tr>
                </thead>
                <tbody>
                    { prescriptions.map((prescription)=>(
                        <tr>
                            <td>{prescription?.brand?.name}</td>
                            <td>{prescription.drug.name}</td>
                            <td>{prescription.number_dispensed}</td>
                            <td>{prescription.dosage}</td>
                            <td>{prescription.date_added}</td>
                        </tr>
                    ))}
                </tbody>
                
            </table> </div> : ""}
            {isLoading && <p className="fw-bold">Please wait ...</p>}

                     {/* <div className="mt-3">
                        {count > limit &&
                            <div className="mt-3 mb-2">

                                <ReactPaginate
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    pageCount={(count / limit)}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                /> 
                            </div>
                        }
                    </div> */}

</>;

}



export default Allprescriptions;