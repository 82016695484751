import { NavLink } from "react-router-dom";
import { useAuth } from "../../store/AuthContext";

const ViewBatchComponent = ({ batch}) => {

    const {settings} = useAuth()

    const isShowMenu = (menu) =>{
        const setting = settings.find(setting => setting.slug === menu)
        return setting && setting.value !== 0 ? setting.value : ""
    } 


    

  
return <>
        <div class="nk-block-head">
                <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">Batch Information: <strong class="text-primary small"> {batch.brand.name}  </strong></h3>
                </div>
            </div>
            <div class="nk-block nk-block-lg">
                <div class="card">
                    <div class="card-aside-wrap">
                        <div class="card-content">
                            <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#tabItem1" aria-selected="true" role="tab"><em class="icon ni ni-user-circle-fill"></em><span>Batch information</span></a>
                                </li>
                            </ul>
                            <div class="card-inner">
                                <div class="tab-content">
                                    <div class="tab-pane active" id="tabItem1" role="tabpanel">
                                    <div>
                                    
                                        
                                        <div className="m-3">

                                        <ul>
                                            <li><p>Batch Name :  {batch.brand.name}</p></li>
                                            <li> <p>Buying Price : {batch.buying_price}</p></li>
                                            <li><p>Selling Price : {batch.selling_price}</p></li>
                                            <li><p>Quantity Received : {batch.quantity_received}</p></li>
                                            <li><p>Pack Size : {batch.pack_size.name}</p></li>
                                            <li><p>LPO : {batch.lpo}</p></li>
                                            <p><p>Unit of Measure : {batch.unit_of_measure.name}</p></p>
                                        </ul>
                                        </div>

                                        {isShowMenu('enable-suppliers-menu') && <div className="m-3">
                                            <h4>Supplier Information </h4>
                                            <ul>
                                                <li>   <p> Name : {batch?.supplier?.name}</p> </li>
                                                <li>  <p> Phone : {batch?.supplier?.phone}</p></li>
                                            </ul>
                                        </div>}
                                        
                                      
                                       
                                       
                                        
                                        
                                        
                                        
                                        
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
    </>
}

export default ViewBatchComponent;